import React from 'react';
import GeneralFunctions from '../../functions/general_function1';


const TranslateModal = ({

}) => {
    return (
        <div className="modal fade" id="translateModal">
                    <div className="modal-dialog">                       
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h4 className="modal-title" id="99165">Translate</h4>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <p><b>Label ID:</b> <span id="lblLabelID">12131</span></p>
                                    <p><b>Current Language:</b> <span id="lblLabelLanguage">English</span></p>
                                    <p><b>Default Language:</b> <span>English</span></p>
                                    <p><b>Default Label:</b> <span id="lblLabelDefault">English</span></p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="editLabel">Label</label>
                                    <input type="text" className="form-control" id="editLabel" />
                                </div>
                                <div>
                                    <input type="hidden" id="selectedDBObjectAttributeID" />
                                    <input type="hidden" id="selectedRID" />
                                    <input type="hidden" id="selectedOldValue" />
                                    <input type="hidden" id="templateLabel" />
                                    <input type="hidden" id="labelTypeID" />
                                    <input type="hidden" id="translabelID" />
                                    <input type="hidden" id="templateLabelID" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal"><span id="999167">Cancel</span></button>
                                <button id="assignLabelButtonConfirm" type="button" className="btn btn-danger" onClick={() => {GeneralFunctions.translateLabelFunction()}} data-dismiss="modal"><span>Confirm</span></button>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default TranslateModal;