import axios from 'axios';
import Auth from './auth';
import React, { useEffect } from 'react';
import { object } from 'prop-types';

import Swal2 from 'sweetalert2';
import moment from 'moment';

let $ = window.$;

let cancelToken = axios.CancelToken;
let source = cancelToken.source();
let previousInput = [];
let suggestions = [];
let objFkName = [],
    objFkNameArray = [],
    userApplicationSettings = [],
    revisionSpecificDates = [];
let oldValue = [];
let employeeId = null;
let objectData = [];
let objectDataArray = [];
var Settings = JSON.parse(Auth.getSettings());
let treeKeys = {
    OUID: "OrganisationalUnitHierarchy",
    DisciplineID: "Disciplines",
    ParentLocationID: "LocationHierarchy"
}, pendingObjectsForQuery = [], webrtc,
    employeePositionTemplate;

let overrideDataSource = axios.CancelToken.source();

class GeneralFunctions {

    static disconnectWebrtc() {
        webrtc.disconnect();
    }

    static leaveRoomWebrtc() {
        webrtc.leaveRoom();
    }

    static setObjectData(objData) {
        objectData = objData;
    }

    static getObjectData() {
        return objectData;
    }

    static setObjectDataArray(objectDataArrayLocal) {
        objectDataArray = objectDataArrayLocal;
    }

    static modifyRIDOfObjectDataArray(targetProp, newRID, oldRID) {
        const OLD_RID = oldRID;
        objectData.map(obj => {
            if (obj[targetProp] != undefined) {
                if (obj[targetProp].length) {
                    obj[targetProp].map(obj1 => {
                        let attribs = obj1.Attributes;
                        attribs.map(obj2 => {
                            if (obj2.RID == OLD_RID) {
                                obj2.RID = newRID;
                            }
                        })
                    })
                } else {
                    obj[targetProp].Attributes.map(obj1 => {
                        if (obj1.RID == oldRID) {
                            obj1.RID = newRID;
                        }
                    })
                }
            }
        })
    }

    static modifyRIDOfObjectDataArrayV2(params) {
        const oldRID = params.oldRID,
            newRID = params.newRID,
            targetProp = params.targetProp,
            object = params.object,
            successDetail_2_object = params.successDetail_2_object;
        let dbObjectID = null,
            additionalUpdate = null;

        objectData.forEach(obj => {
            let objTargetProp = obj[targetProp];

            if (objTargetProp) {
                if (objTargetProp.length) {
                    objTargetProp.forEach(attrib1 => {
                        let attribs = attrib1.Attributes;
                        attribs.map(attrib2 => {
                            if (successDetail_2_object.ShouldChangeSameDBObjectID) {
                                if (dbObjectID == null) {
                                    dbObjectID = successDetail_2_object.DBObjectID;
                                }
                                if (additionalUpdate == null) {
                                    additionalUpdate = successDetail_2_object.AdditionalUpdate;
                                }

                                if (object.GroupData) {
                                    let objectGroupData = object.GroupData,
                                        attrib2GroupData = attrib2.GroupData;

                                    if (objectGroupData.GroupID == attrib2GroupData.GroupID && objectGroupData.ElementID == attrib2GroupData.ElementID) {
                                        attrib2.RID = newRID;

                                        if (additionalUpdate) {
                                            if (attrib2.AttributeName.includes(`[${additionalUpdate.DBObjectID}]`)) {
                                                attrib2.RID = additionalUpdate.RID;
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    });
                } else {
                    objTargetProp.Attributes.map(attribute => {
                        if (attribute.RID == oldRID) {
                            attribute.RID = newRID;
                        }
                    });
                }
            }
        });
    }

    static modifyObjectDataArrayElement(params) {
        let paramsKey = params.key,
            paramsData = params.data;

        objectData.map(obj => {
            let instance = obj[paramsKey];

            if (instance) {
                obj[paramsKey] = paramsData;
            }

            return obj;
        });
    }

    static setObjFkName(objFkNameParam) {
        objFkName = objFkNameParam;
    }

    static getObjkValue(AttributeName) {
        return objFkName[AttributeName.split("].")[1] + "_Name"];
    }

    static setObjkValue(AttributeName, newValue) {
        objFkName[AttributeName.split("].")[1] + "_Name"] = newValue;
    }

    static setObjFkNameArray(objFkNameArrayParam) {
        objFkNameArray = objFkNameArrayParam;
    }

    static getObjFkNameArrayValue(AttributeName, objVal) {
        AttributeName = AttributeName.split("].")[1];
        for (let x in objFkNameArray) {
            let objFkNameArrayElem = objFkNameArray[x];
            if (objFkNameArrayElem[AttributeName] == objVal) {
                return objFkNameArrayElem[(AttributeName + "_Name")];
            }
        }
    }

    static setRevisionSpecificDates(revisionSpecificDatesParam) {
        revisionSpecificDates = revisionSpecificDatesParam;
    }

    static getRevisionSpecificDates(dBObjectAttributeID) {
        for (let x in revisionSpecificDates) {
            let revisionSpecificDate = revisionSpecificDates[x];
            if (revisionSpecificDate && revisionSpecificDate.DBObjectAttributeID == dBObjectAttributeID) {
                return revisionSpecificDate.DateEffective;
            }
        }

        return "";
    }

    static getElementIfMultipleValues(element, object) {
        let tempElement = document.getElementById(object.AttributeName + "-" + object.RID);

        return (tempElement ? tempElement : element);
    }

    static setEmployeeId(empId) {
        employeeId = empId;
    }

    static getEmployeeId() {
        return employeeId;
    }

   
    static getLangDefualt(value) {
        return (
            Object.keys(value).map((obj) => {
                if (Auth.getLanguage() === obj) {
                    var result = value[Auth.getLanguage()];
                    return (result)
                }
            })
        )
    }

    static getDateSettings() {
        let self = this;
        axios.get((API_URL) + '/system_default_settings_proc/', {
            params: {
                PageSize: 999,
                PageNum: 1,
                SortField: "ID",
            },
            headers: {
                'token': Auth.getToken()
            },
        }).then((res) => {
            Auth.setSettings(JSON.stringify(res.data[0].SystemDefaultsettings));

        }).catch(function (thrown) {
            console.log("error");
        });

        return JSON.parse(Auth.getSettings());
    }

    static languageAPI() {
        var result = [], LanguageResult = []
        axios.get((API_URL_CMS) + '/default_labels?Language=' + Auth.getLanguage(), { //Get Form Data

        }).then((res) => {
            switch (res.status) {
                case 200:
                    result = this.getLangDefualt(res.data.languages[0]);
                    return (
                        Object.keys(result).map((obj) => {
                            if (result[obj] !== undefined) {
                                LanguageResult = result[obj];
                                this.setState({
                                    Language: LanguageResult
                                })
                                this.injectLanguage(LanguageResult)
                            }
                        })
                    )

                    break;
                default:
                    break;
            }
        }).catch(function (thrown) {
            // this.stopLoading();
            // waitingDialog.hide();

        });
    }

    static injectLanguage(object) {
        var ID, Labels, EN;
        if (object != null && object != undefined) {
            try {
                object.map((obj) => {
                    if (obj.DefaultLabel != null || obj.DefaultLabel != undefined) {

                        ID = (obj.DBObjectAttributeID).toString();
                        Labels = (obj.DefaultLabel).toString();
                        if (obj.EN != undefined && obj.EN != null) {
                            EN = (obj.EN).toString();
                        }
                        if (Labels !== '') {

                            $("div#" + ID + " h3").text(Labels);
                            $("p#" + ID).text(Labels);
                            $("tspan#" + ID).text(Labels);
                            $("span#" + ID).text(Labels);
                            $("span[name=" + ID + "]").text(Labels);
                            $("input[ref=" + ID + "]").attr("placeholder", Labels);
                        
                        } else {
                            $("div#" + ID + " h3").text(EN);
                            $("p#" + ID).text(EN);
                            $("span#" + ID).text(EN);
                        }
                    }
                })
            } catch (Exception) {
                console.log(Exception);
            }
        }
    }

    static initMaxLength(element, object) {
        if (object.Length != null) {
            element.maxLength = object.Length;
        }
    }

    static injectionType(element, object, pageLocation, locationModal) {
        let isSet = false;

        if (object.HasMultipleValues) { // CHECKS IF THE OBJECT IS ALREADY SET AND IT HAS MULTIPLE VALUES
            element = this.initMultipleValues(element, object);
        }

        if (object.IsRevisionSpecific) {
            this.initRevision(element, object);
        }

        var type = "",
            name = object.Name;
        if (object.IsPicker) {
            this.createButtonExtension(element, object);
            element.className = "form-control search-input";
        } else if (object.IsSelectList) {
            if (object.ListValue != null) {
                if (element.options) {
                    if (element.options.length == 0) {
                        if (object.ListValue != null && object.ListValue.includes("SPG:")) {
                            if(object.AttributeName == "[1162].PositionTypeID")
                            {
                                this.createDefaultPleaseSelect(element, "workflowStateRole");
                            }
                            else
                            {
                                this.createDefaultPleaseSelect(element);
                            }
                        }
                        this.renderOptions(element, object);
                    }
                }
            }
            else {
                this.createSelectList(element, object.AttributeFKJSON)
            }
            element.className = "form-control";
        } else if (object.isFileUpload || object.IsFileUpload) {
            type = "file";
            var img = document.getElementById("img." + object.AttributeName);
            img.src = "../src/assets/images/default-profile-image.png";
            element.accept = "image/*";
            this.injectFileUpload(element, object);
        } else if (object.IsHierarchyList) {
            this.createButtonExtension(element, object);
            element.className = "form-control search-input";
            if (object.IsMandatory) {
                element.className = "form-control search-input mandatory";
            }

        } else if (object.AttributeName.toLowerCase().includes("password")) {
            type = "password";
            element.className = "form-control";
        } else if (object.IsColourPicker) {
            type = "color";
            element.className = "form-control";
            if (object.Value == undefined) {
                element.value = object.DefaultValue;
            }
        } else {
            if (name === "String") {
                type = "text";
                element.className = "form-control";
            } else if (name === "Date" || name === "Time" || name === "DateTime" || name === "date") {
                type = "text";
                if (locationModal == "assign-employee-modal") {
                    if (object.AttributeName == "[22].DT_EndPosition") {
                        initDatepickerEmployee(element, Auth.getSettingsByID(2).value);
                    }
                    else {
                        initDatepickerEmployee(element, Auth.getSettingsByID(2).value, pageLocation);
                    }
                }
                else {
                    if(pageLocation == "Add Employee" && element.id == "[22].DT_StartPosition")
                    {
                        initDatepickerAddEmployee(element, Auth.getSettingsByID(2).value, pageLocation, locationModal)
                    }
                    else if(pageLocation == "Add Employee" && element.id == "[2].DateAppointed")
                    {
                        initDatepickerAddEmployee(element, Auth.getSettingsByID(2).value, pageLocation, locationModal)
                    }
                    else
                    {
                        initDatepickerEmployee(element, Auth.getSettingsByID(2).value, pageLocation, locationModal);
                    }
                }
                element.className = "form-control";
            } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {
                if (name === "Money" || name === "Double") {
                    type = "text";
                } else {
                    if (object.AttributeName == "[95].MaxBonus" || object.AttributeName == "[95].BonusOpportunity" || object.AttributeName == "[95].CalculatedBonusPercent") {
                        type = "text";
                    }
                    else {
                        type = "number";
                    }
                }
                element.className = "form-control";
            } else if (name === "Bit") {
                type = "checkbox";
            } else if (name === "Email") {
                type = "email";
                element.className = "form-control";
            }
            this.initMaxLength(element, object);
        }

        if (type != "") {
            element.setAttribute('type', type);
        }

        element.removeAttribute('disabled');
        this.injectHoverText(document.getElementById(object.DBObjectAttributeID), object);
    }

    static injectionTypeExtension(element, object) {
        let self = this

        element.onblur = function (event) {
            element.value = self.formatCurrency(event.target.value)
        }
    }

    static isObjectSetAlready(element) {
        return !element.classList.contains("disable");
    }

    static initMultipleValues(element, object) {

        let elementRID = element.getAttribute("rid"),
            elementID = element.id,
            objectRID = object.RID,
            tempElement = document.getElementById(getObjectStringConcat(object));

        if (elementRID == null) {
            /*
                IF THE ELEMENT'S RID IS NULL, THEN IT IS THE FIRST OCCURENCE FROM THE OBJECT.
                ATTACH THE ATTRIBUTE 'RID' TO THE ELEMENT
            */
            element.setAttributeNode(createNewAttribute("rid", object.RID));
        } else {
            /*
                IF THE ELEMENT'S RID IS NOT NULL, THEN IT IS NOT THE FIRST OCCURENCE FROM THE OBJECT. FOLLOW THE PROCEDURE:
                1. CHECK THE ELEMENT'S RID AND COMPARE IT WITH THE OBJECT.
            */
            if (elementRID != objectRID && tempElement == null) {
                let htmlStr = this.createElementForMultipleValues(object),
                    elementParent = this.getParentNode(element, "form-group");
                if (elementParent != undefined) {
                    if (document.getElementById(object.AttributeName + "-" + object.RID) == undefined) {
                        elementParent.insertAdjacentHTML("afterend", htmlStr);
                        element = document.getElementById(object.AttributeName + "-" + object.RID);
                        element.setAttributeNode(createNewAttribute("rid", object.RID));
                        return element;
                    }
                }
            }
        }

        return element;
    }

    static initBtnRemove(element, object) {
        if (object.HasMultipleValues && object.RW == 2) {
            let elementID = element.id,
                btnRemoveElement = document.getElementById("btnRemove" + elementID),
                elementParent = this.getParentNode(element, "form-group");

            if (btnRemoveElement == null && elementParent != null) {
                let htmlStr = this.createElementRemoveForMultipleValues(element, object),
                    elementIDSplit = splitAttributeName(elementID);
                elementParent.insertAdjacentHTML("beforeend", htmlStr);
                btnRemoveElement = document.getElementById("btnRemove" + elementID);
                btnRemoveElement.setAttributeNode(createNewAttribute("rid", object.RID));
                $(btnRemoveElement).on('click', function () {

                });
            }
        }
    }

    static createElementRemoveForMultipleValues(element, object) {
        let elementID = element.id,
            elementIDSplit = splitAttributeName(elementID),
            htmlStr = `
            <div class='col-sm-1'>
                <button class='btn btn-danger' id='btnRemove`+ elementID + `' type='button'>Remove</button>
            </div>`;

        return htmlStr;
    }

    static createElementForMultipleValues(object) {
        let name = object.Name,
            htmlStr = '',
            checkBoxHtml = `
        <div class='form-group'>
            <div class='col-sm-2'></div>
            <div class='col-sm-8'><input id='` + (object.AttributeName + "-" + object.RID) + `'/></div>
        </div>`,
            inpHtml = `
        <div class='form-group'>
            <div class='col-sm-2'></div>
            <div class='col-sm-8'><input id='` + (object.AttributeName + "-" + object.RID) + `' class='form-control disable' disabled placeholder='` + object.DefaultLabel + `' /></div>
        </div>
        `,
            selectHtml = `
        <div class='form-group'>
            <div class='col-sm-2'></div>
            <div class='col-sm-8'><select id='` + (object.AttributeName + "-" + object.RID) + `' class='form-control disable' disabled placeholder='` + object.DefaultLabel + `' /></div>
        </div>`;

        if (object.IsPicker) {
            htmlStr = inpHtml;
        } else if (object.IsSelectList) {
            htmlStr = selectHtml;
        } else if (object.isFileUpload || object.IsFileUpload) {

        } else if (object.IsHierarchyList) {
            htmlStr = inpHtml;
        } else if (object.AttributeName.toLowerCase().includes("password")) {
            htmlStr = inpHtml;
        } else if (object.IsColourPicker) {
            htmlStr = inpHtml;
        } else {
            if (name === "String") {
                htmlStr = inpHtml;
            } else if (name === "Date" || name === "Time" || name === "DateTime" || name === "date") {
                htmlStr = inpHtml;
            } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {
                htmlStr = inpHtml;
            } else if (name === "Bit") {
                htmlStr = checkBoxHtml;
            } else if (name === "Email") {
                htmlStr = inpHtml;
            }
        }

        return htmlStr;
    }

    static getParentNode(element, classToSearch) {
        let elementObj = element.parentNode;

        while (elementObj.className != classToSearch) {
            elementObj = elementObj.parentNode;
        }

        return elementObj;
    }

    static getRevisionStaticID(params) {
        let toBeReturned = params.toBeReturned,
            dBObjectAttributeID = params.dBObjectAttributeID,
            extraConcat = params.extraConcat,
            extraConcatStr = "";

        const vals = {
            groupHtmlID: "input-group-revision-",
            groupHtmlInputElementID: "input-group-revision-input-",
            groupHtmlDateElementID: "input-group-revision-date-",
            groupHtmlHistoryBtnID: "input-group-revision-date-history-",
            groupHtmlNewRevisionBtnID: "input-group-revision-date-new-revision-",
            revisionModalHistoryID: "modalRevisionHistory-",
            revisionModalNewID: "modalRevisionNew-",
            revisionModalBodyHistoryID: "modalRevisionHistoryBody-",
            revisionModalBodyNewID: "modalRevisionNewBody-",
            revisionModalMessageHistoryContainerID: "modalRevisionHistoryMessageContainer-",
            revisionModalMessageNewContainerID: "modalRevisionNewMessageContainer-",
            formID: "formRevisionNew-",
            formDateElementID: "formRevisionNewDate-",
            formInputElementID: "formRevisionNewInput-",
            formRemoveBtnElementID: "formRevisionNewRemoveBtn-",
            revisionModalNewPosBtnID: "modalRevisionNewBtnPos-",
            revisionTimelineID: "modalRevisionHistoryTimeline-",
            revisionTimeLineItemID: "modalRevisionHistoryTimelineItem-",
            //ADDED BY MIGS
            newRevisionTimelineAddItemFormID: 'addFormNewRevisionTimelineForm-',
            newRevisionTimelineAddItemInputID: 'addFormNewRevisionTimelineInput-',
            newRevisionTimelineAddItemDateID: 'addFormNewRevisionTimelineDate-',
            newRevisionTimelineAddItemBtnID: 'addFormNewRevisionTimelineBtn-'
        }

        if (extraConcat) {
            extraConcat.forEach(item => {
                extraConcatStr += ("-" + item);
            })
        }

        return (vals[toBeReturned] + dBObjectAttributeID + extraConcatStr);
    
    }

    static getRevisionStaticInputVerification(dBObjectAttributeID, object) {
        let self = this,
          
            vals = [{
                inputID: self.getRevisionStaticID({ toBeReturned: "formInputElementID", dBObjectAttributeID: dBObjectAttributeID }),
                isMandatory: true,
                type: self.getType(object),
                label: object.DefaultLabel
            },
            {
                inputID: self.getRevisionStaticID({ toBeReturned: "formDateElementID", dBObjectAttributeID: dBObjectAttributeID }),
                isMandatory: true,
                type: "date",
                label: "Effective Date"
            }];

        

        return vals;
    }

    static getNewRevisionTimelineStaticInputVerification(dBObjectAttributeID, object) {
        let self = this,
            vals = [{
                inputID: self.getRevisionStaticID({ toBeReturned: "newRevisionTimelineAddItemInputID", dBObjectAttributeID: dBObjectAttributeID }),
                isMandatory: true,
                type: self.getType(object),
                label: object.DefaultLabel
            },
            {
                inputID: self.getRevisionStaticID({ toBeReturned: "newRevisionTimelineAddItemDateID", dBObjectAttributeID: dBObjectAttributeID }),
                isMandatory: true,
                type: "date",
                label: "Effective Date"
            }];

        return vals
    }

    static getType(object) {
        let name = object.Name;

        if (object.IsPicker) {
            return "picker";
        } else if (object.IsSelectList) {
            return "selectListt";
        } else if (object.isFileUpload || object.IsFileUpload) {
            return "fileUpload";
        } else if (object.IsHierarchyList) {
            return "hierarchyList";
        } else if (object.IsColourPicker) {
            return "colourPicker";
        } else {
            if (name === "String") {
                return "string";
            } else if (name === "Date" || name === "Time" || name === "DateTime" || name === "date") {
                return "date";
            } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {
                if (name === "Money") {
                    return "money";
                } else {
                    return "number";
                }
            } else if (name === "Bit") {
                return "bit";
            } else if (name === "Email") {
                return "email";
            }
        }
    }

    static initRevision(element, object) {
        let self = this,
            dBObjectAttributeID = object.DBObjectAttributeID,
            groupHtmlID = self.getRevisionStaticID({ toBeReturned: "groupHtmlID", dBObjectAttributeID: dBObjectAttributeID });

        if (!document.getElementById(groupHtmlID)) {
            this.injectHoverText(document.getElementById(object.DBObjectAttributeID), object);
            let elementRID = element.getAttribute("rid"),
                elementID = element.id,
                objectRID = object.RID,
                elementParent = this.getParentNode(element, "col-sm-7"),
                groupHtmlInputElementID = self.getRevisionStaticID({ toBeReturned: "groupHtmlInputElementID", dBObjectAttributeID: dBObjectAttributeID }),
                groupHtmlDateElementID = self.getRevisionStaticID({ toBeReturned: "groupHtmlDateElementID", dBObjectAttributeID: dBObjectAttributeID }),
                groupHtmlHistoryBtnID = self.getRevisionStaticID({ toBeReturned: "groupHtmlHistoryBtnID", dBObjectAttributeID: dBObjectAttributeID }),
                groupHtmlNewRevisionBtnID = self.getRevisionStaticID({ toBeReturned: "groupHtmlNewRevisionBtnID", dBObjectAttributeID: dBObjectAttributeID }),
               
                groupHtmlStr = `
                    <div id='`+ groupHtmlID + `' class='input-group'>
                        <input id='`+ groupHtmlInputElementID + `' type="text" class="form-control" />
                        <span class="input-group-btn" style="width:10px;"></span>
                        <input id='`+ groupHtmlDateElementID + `' type="text" class="form-control" />
                        <span class="input-group-btn">
                            <button id='`+ groupHtmlHistoryBtnID + `' class="btn btn-default" type="button"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                        </span>
                    </div>
                `,
                groupHtml = null,
                SP_MODULE = "system_revision_list",
                objectDate = {
                    Name: "Date",
                    AttributeName: "",
                    DefaultLabel: "Effective Date",
                    DBObjectAttributeID: dBObjectAttributeID
                };

            elementParent.insertAdjacentHTML('beforeend', groupHtmlStr);
            groupHtml = document.getElementById(groupHtmlID);

            let groupHtmlInputElement = document.getElementById(groupHtmlInputElementID),
                groupHtmlDateElement = document.getElementById(groupHtmlDateElementID),
                groupHtmlDateElementValue = self.getRevisionSpecificDates(dBObjectAttributeID),
                revisionHistoryModal = this.initRevisionHistoryModal(element, object),
                revisionNewModal = this.initRevisionNewModal(element, object);

            if (object.RW > 0) {
                groupHtmlInputElement.disabled = true;
                groupHtmlDateElement.disabled = true;
                self.injectTypeForRevision(groupHtmlInputElement, object, { value: object.Value });
                if (groupHtmlDateElementValue) {
                    self.injectTypeForRevision(groupHtmlDateElement, objectDate, { value: groupHtmlDateElementValue });
                }
                self.injectionDisabled(groupHtmlInputElement, object);
                self.injectionDisabled(groupHtmlDateElement, object);

                element.style.display = "none";

                // INSERT FUNCTION TO THE BUTTONS
                document.getElementById(groupHtmlHistoryBtnID).removeEventListener("click", onClickHistory);
                document.getElementById(groupHtmlHistoryBtnID).addEventListener("click", onClickHistory);
                if (object.RW == 2) {
                    // INSERT FUNCTION TO THE BUTTONS
                    document.getElementById(groupHtmlNewRevisionBtnID).removeEventListener("click", onClickNewRevision);
                    document.getElementById(groupHtmlNewRevisionBtnID).addEventListener("click", onClickNewRevision);
                }
            }

            function onClickHistory() {
                let requestjson = {
                    Module: SP_MODULE,
                    Parameters: {
                        ObjectAttributeID: dBObjectAttributeID,
                        EmployeeID: self.getEmployeeId()
                    }
                }, config = {
                    Data: { requestjson: requestjson },
                    Method: "GET",
                    ResponseSuccessCallback: responseSuccessCallback,
                    ResponseFailCallback: responseFailCallback,
                    Url: "/single_api/"
                };

                waitingDialog.show("Loading history...", { dialogSize: 'sm', progressType: 'danger' });
                self.genericQuery(config);
            }

            function onClickNewRevision() {
                self.initGenerateRevisionNewModalContent(element, object);
                showModal(revisionNewModal);
            }

            function responseSuccessCallback(responseJson) {
                waitingDialog.hide();
                let data = responseJson.data;

                if (data.Status.IsSuccess) {
                    self.initGenerateRevisionHistoryModalContent(element, object, data.Data.Revisions);
                    showModal(revisionHistoryModal);
                }
            }

            function responseFailCallback(responseJson) {
                waitingDialog.hide();
                console.log("responseFailCallback", responseJson);
            }
        }
    }

    static initRevisionHistoryModal(element, object) {
        let self = this,
            dBObjectAttributeID = object.DBObjectAttributeID,
            revisionModalID = self.getRevisionStaticID({ toBeReturned: "revisionModalHistoryID", dBObjectAttributeID: dBObjectAttributeID }),
            revisionModalBodyID = self.getRevisionStaticID({ toBeReturned: "revisionModalBodyHistoryID", dBObjectAttributeID: dBObjectAttributeID }),
            headerText = object.DefaultLabel + " History";

        if (document.getElementById(revisionModalID) == null) {
            let cont = document.getElementById("modalContainer"),
                modalElem = document.getElementById(revisionModalID),
                modalHtml =
                    `<div class="modal fade modal-scrollable" id='` + revisionModalID + `'>
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h4 class="modal-title">`+ headerText + `</h4>
                                </div>
                                <div class="modal-body" id='`+ revisionModalBodyID + `'>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>`;

            if (modalElem != null) {
                modalElem.parentNode.removeChild(modalElem);
            }
            cont.insertAdjacentHTML('beforeend', modalHtml);
        }

        return document.getElementById(revisionModalID);
    }

    static initGenerateRevisionHistoryModalContent(element, object, data) {
        let self = this,
            dBObjectAttributeID = object.DBObjectAttributeID,
            revisionModalBodyID = self.getRevisionStaticID({ toBeReturned: "revisionModalBodyHistoryID", dBObjectAttributeID: dBObjectAttributeID }),
            revisionModalBody = document.getElementById(revisionModalBodyID),
            revisionModalMessageContainerID = self.getRevisionStaticID({ toBeReturned: "revisionModalMessageHistoryContainerID", dBObjectAttributeID: dBObjectAttributeID }),
            revisionTimelineID = self.getRevisionStaticID({ toBeReturned: "revisionTimelineID", dBObjectAttributeID: dBObjectAttributeID });

        revisionModalBody.innerHTML = "";

        let modalBodyContentStr = `
            <div class="row">
                <div class="col-sm-12">
                    <ul class="timeline" id="`+ revisionTimelineID + `"></ul>
                </div>
            </div>
        `, formMessageContainerHtmlStr = `
                <div id="`+ revisionModalMessageContainerID + `" />`;

        revisionModalBody.insertAdjacentHTML('afterbegin', formMessageContainerHtmlStr);
        revisionModalBody.insertAdjacentHTML('beforeend', modalBodyContentStr);

        self.insertFieldsRevisionModalHistoryContent(element, object,
            {
                data: data,
                revisionTimeLine: document.getElementById(revisionTimelineID)
            });
    }

    static initGenerateRevisionNewModalContent(element, object) {
        let self = this,
            dBObjectAttributeID = object.DBObjectAttributeID,
            revisionModalBodyID = self.getRevisionStaticID({ toBeReturned: "revisionModalBodyNewID", dBObjectAttributeID: dBObjectAttributeID }),
            revisionModalBody = document.getElementById(revisionModalBodyID),
            revisionModalMessageContainerID = self.getRevisionStaticID({ toBeReturned: "revisionModalMessageNewContainerID", dBObjectAttributeID: dBObjectAttributeID }),
            currentRevisionDate = self.getRevisionSpecificDates(dBObjectAttributeID);

        revisionModalBody.innerHTML = "";

        let formID = self.getRevisionStaticID({ toBeReturned: "formID", dBObjectAttributeID: dBObjectAttributeID }),
            formDateElementID = self.getRevisionStaticID({ toBeReturned: "formDateElementID", dBObjectAttributeID: dBObjectAttributeID }),
            formInputElementID = self.getRevisionStaticID({ toBeReturned: "formInputElementID", dBObjectAttributeID: dBObjectAttributeID });

        let formMessageContainerHtmlStr = `
                <div id='`+ revisionModalMessageContainerID + `' />
            `, formHtmlStr = `
                <form id="`+ formID + `" class='form-horizontal'>
                </form>
            `,
            formDateElementHtmlStr = `
                <input id="`+ formDateElementID + `" class="form-control" />
            `,
            formInputElementHtmlStr = `
                <input id="" class="form-control" />
            `, formHtml = null, formDateElementHtml = null;

        revisionModalBody.insertAdjacentHTML('afterbegin', formMessageContainerHtmlStr);
        revisionModalBody.insertAdjacentHTML('beforeend', formHtmlStr);
        formHtml = document.getElementById(formID);

        this.insertFieldsRevisionModalNewContent(formDateElementHtmlStr,
            { // object
                Name: "Date",
                AttributeName: ""
            },
            { // params
                formHtml: formHtml,
                label: "Effective Date",
                inputID: formDateElementID,
                currentRevisionDate
            }
        );

        this.insertFieldsRevisionModalNewContent(this.createElementForRevisionNew(object, { inputID: formInputElementID }), object,
            {
                formHtml: formHtml,
                label: object.DefaultLabel,
                inputID: formInputElementID
            }
        );
    }

    static insertFieldsRevisionModalHistoryContent(element, object, params) {
        let self = this,
            dBObjectAttributeID = object.DBObjectAttributeID,
            data = params.data,
            revisionTimeLine = params.revisionTimeLine,
            groupHtmlInputElementID = self.getRevisionStaticID({ toBeReturned: "groupHtmlInputElementID", dBObjectAttributeID: dBObjectAttributeID }),
            groupHtmlDateElementID = self.getRevisionStaticID({ toBeReturned: "groupHtmlDateElementID", dBObjectAttributeID: dBObjectAttributeID }),
            groupHtmlInputElement = document.getElementById(groupHtmlInputElementID),
            currentRevisionDate = self.getRevisionSpecificDates(dBObjectAttributeID),
            groupHtmlDateElement = document.getElementById(groupHtmlDateElementID);

        if (revisionTimeLine && data) {
            let inpListItems = self.getRevisionStaticInputVerification(dBObjectAttributeID, object),
                objectDate = {
                    Name: "Date",
                    AttributeName: "",
                    DefaultLabel: "Effective Date",
                    DBObjectAttributeID: dBObjectAttributeID
                };

            data.forEach(item => {
                let iconColor = "green";

                if (item.RevisionID < 0) {
                    iconColor = "orange";
                } else if (item.RevisionID > 0) {
                    iconColor = "black";
                } else {
                    self.injectTypeForRevision(groupHtmlInputElement, object, { value: item.Value });
                    groupHtmlDateElement.value = item.DateEffective
                    iconColor = "green";
                }

                let listIconHtmlStr = `
                    <li>
                        <i class="fa fa-circle bg-`+ iconColor + `"></i>
                        <div class="timeline-item no-box-shadow">
                            <div class="row">
                                <div class="col-sm-5"></div>
                                <div class="col-sm-5"></div>
                            </div>
                        </div>
                    </li>
                `;

                revisionTimeLine.insertAdjacentHTML("beforeend", listIconHtmlStr);

                // INSERT TO THE HISTORY ITEM
                let tempParentContainer = revisionTimeLine;
                for (let x = 0; x < 3; x++) {
                    if (tempParentContainer) {
                        tempParentContainer = tempParentContainer.lastElementChild;
                    }
                }

                // FOR DELETE BTN
                if (item.RevisionID < 0 && object.RW == 2) {
                    let formRemoveBtnElementID = (self.getRevisionStaticID({ toBeReturned: "formInputElementID", dBObjectAttributeID: dBObjectAttributeID }) + "-" + item.RID),
                        formRemoveBtnElement = null,
                        btnHtmlStr = `
                            <div class="col-sm-2">
                                <button id="`+ formRemoveBtnElementID + `" class="btn btn-danger">
                                    <i class="fa fa-close"></i>
                                </button>
                            </div>
                        `;
                    tempParentContainer.insertAdjacentHTML('beforeend', btnHtmlStr);

                    formRemoveBtnElement = document.getElementById(formRemoveBtnElementID);

                    if (formRemoveBtnElement) {
                        formRemoveBtnElement.setAttribute('data-rid', item.RID);
                        formRemoveBtnElement.setAttribute('data-object', JSON.stringify(object));

                        formRemoveBtnElement.removeEventListener("click", onClickRemoveRevisionBtn);
                        formRemoveBtnElement.addEventListener("click", onClickRemoveRevisionBtn);
                    }
                }

                inpListItems.forEach((inpListItem, inpListItemIndex) => {
                    let inpHtmlStr = "",
                        tempInpListItemInputID = inpListItem.inputID;

                    inpListItem.inputID = (inpListItem.inputID + "-" + item.RID);

                    if (inpListItemIndex == 0) {
                        inpHtmlStr = self.createElementForRevisionNew(object, {
                            inputID: inpListItem.inputID
                        });
                    } else {
                        inpHtmlStr = self.createElementForRevisionNew(objectDate,
                            { // params
                                inputID: inpListItem.inputID
                            });
                    }

                    if (tempParentContainer) {
                        let cont = tempParentContainer.children[inpListItemIndex];
                        if (cont) {
                            cont.insertAdjacentHTML("beforeend", inpHtmlStr);
                            let newElement = document.getElementById(inpListItem.inputID);

                            newElement.setAttribute('data-item', JSON.stringify(item));
                            newElement.setAttribute('data-rid', item.RID);
                            newElement.setAttribute('data-object', JSON.stringify(object));
                            if (inpListItemIndex == 0) {
                                self.injectTypeForRevision(newElement, object, {
                                    value: item.Value
                                });
                                newElement.setAttribute("data-oldvalue", item.Value);
                                newElement.setAttribute("data-type", self.getType(object));
                                newElement.setAttribute("data-key", "Value");

                                self.injectMasking(newElement, object);
                                if (item.RW === 2) {
                                    self.injectFunctionForRevision(newElement, object);
                                }
                                else {
                                    newElement.disabled = true;
                                }
                            } else {
                                self.injectTypeForRevision(newElement, objectDate, {
                                    value: item.DateEffective, currentRevisionDate
                                });
                                newElement.setAttribute("data-oldvalue", item.DateEffective);
                                newElement.setAttribute("data-type", "date");
                                newElement.setAttribute("data-key", "DateEffective");

                                self.injectMasking(newElement, objectDate);
                                if (item.RW === 2) {
                                    newElement.disabled = false;
                                    self.injectFunctionForRevision(newElement, objectDate);
                                }
                                else {
                                    newElement.disabled = true;
                                }
                            }
                        }
                    }

                    inpListItem.inputID = tempInpListItemInputID;
                });
            });
        }

        self.revisionSpecificAddField(element, object, params);

        function onClickRemoveRevisionBtn(event) {
            self.injectRemoveRevision(event);
        }
    }

    static revisionSpecificAddField(element, object, params) {

        let self = this,
            dBObjectAttributeID = object.DBObjectAttributeID,
            data = params.data,
            revisionTimeLine = params.revisionTimeLine,
            currentRevisionDate = self.getRevisionSpecificDates(dBObjectAttributeID);

        let addNewTimelineFormID = self.getRevisionStaticID({ toBeReturned: "newRevisionTimelineAddItemFormID", dBObjectAttributeID: dBObjectAttributeID }),
            addNewTimelineInputID = self.getRevisionStaticID({ toBeReturned: "newRevisionTimelineAddItemInputID", dBObjectAttributeID: dBObjectAttributeID }),
            addNewTimelineDateID = self.getRevisionStaticID({ toBeReturned: "newRevisionTimelineAddItemDateID", dBObjectAttributeID: dBObjectAttributeID }),
            addNewTimelineBtnID = self.getRevisionStaticID({ toBeReturned: "newRevisionTimelineAddItemBtnID", dBObjectAttributeID: dBObjectAttributeID });

        let applicationFunctionID = Auth.getEmployeeMenuAccess();
 
        let addRevisionItem = `
            <li>
                <i class="fa fa-circle bg-`+ 'blue' + `"></i>
                <div class="timeline-item no-box-shadow">
                <div class="row">
                    <form id="`+ addNewTimelineFormID + `">
                        
                        <div class="col-sm-2">
                            <button id="`+ addNewTimelineBtnID + `" type="button" class="btn btn-primary">
                                <i class="fa fa-plus"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </li>
        `;


        let formNewRevisionTimelineDateHtmlStr = `
            <input id="`+ addNewTimelineDateID + `" class="form-control" />
        `;

        let dummyContainter = `
                                <div class="col-sm-5">
                                    <input type="text" placeholder="`+ object.DefaultLabel + `" class="form-control" id="` + addNewTimelineInputID + `" />
                                </div>
                                    <div class="col-sm-5">
                                        <input type="text" class="form-control" id="`+ addNewTimelineDateID + `" />
                                    </div>
                                    <div class="col-sm-2">
                                        <button id="`+ addNewTimelineBtnID + `" type="button" class="btn btn-primary">
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>`




        revisionTimeLine.insertAdjacentHTML('afterbegin', addRevisionItem);
        let formHtml = document.getElementById(addNewTimelineFormID);
        let addNewRevisionBtn = document.getElementById(addNewTimelineBtnID);

        

        this.addNewRevisionTimelineFormFields(formNewRevisionTimelineDateHtmlStr,
            { // object
                Name: "Date",
                AttributeName: ""
            },
            { // params
                formHtml: formHtml,
                label: "Effective Date",
                inputID: addNewTimelineDateID,
                currentRevisionDate
            }
        );

        this.addNewRevisionTimelineFormFields(this.createElementForRevisionNew(object, { inputID: addNewTimelineInputID }), object,
            {
                formHtml: formHtml,
                label: object.DefaultLabel,
                inputID: addNewTimelineInputID
            }
        );

        if (addNewRevisionBtn) {
            addNewRevisionBtn.removeEventListener('click', onClickRevisionModalAddRevisionBtn)
            addNewRevisionBtn.addEventListener('click', onClickRevisionModalAddRevisionBtn)
        }

        if(addNewTimelineBtnID == "addFormNewRevisionTimelineBtn-126")
        {
            if(applicationFunctionID.filter(obj => {return obj.ApplicationFunctionID === parseInt(162)}).length == 0)
            {
                $("#" + addNewTimelineBtnID).attr("disabled", true);
            }
        }
        else if(addNewTimelineBtnID == "addFormNewRevisionTimelineBtn-127") 
        {
            if(applicationFunctionID.filter(obj => {return obj.ApplicationFunctionID === parseInt(158)}).length == 0)
            {
                $("#" + addNewTimelineBtnID).attr("disabled", true);
            }
        }
        else if(addNewTimelineBtnID == "addFormNewRevisionTimelineBtn-128")
        {
            if(applicationFunctionID.filter(obj => {return obj.ApplicationFunctionID === parseInt(159)}).length == 0)
            {
                $("#" + addNewTimelineBtnID).attr("disabled", true);
            }
        }
        else if(addNewTimelineBtnID == "addFormNewRevisionTimelineBtn-483")
        {
            if(applicationFunctionID.filter(obj => {return obj.ApplicationFunctionID === parseInt(160)}).length == 0)
            {
                $("#" + addNewTimelineBtnID).attr("disabled", true);
            }
        }
        else if(addNewTimelineBtnID == "addFormNewRevisionTimelineBtn-850")
        {
            if(applicationFunctionID.filter(obj => {return obj.ApplicationFunctionID === parseInt(161)}).length == 0)
            {
                $("#" + addNewTimelineBtnID).attr("disabled", true);
            }
        }

        function onClickRevisionModalAddRevisionBtn(event) {
            self.injectBtnPosFunction(event, object, element);
        }
    }

    static addNewRevisionTimelineFormFields(element, object, params) {
        let formHtml = params.formHtml,
            formGroup = `
                <div class="col-sm-5">
                </div>
        `;

        formHtml.insertAdjacentHTML('afterbegin', formGroup);
        let formGroupInputElem = formHtml.children[0]

        // INSERT THE LABEL
        
        if (typeof element == "object") {
            formGroupInputElem.appendChild(element);
        } else if (typeof element == "string") {
            formGroupInputElem.insertAdjacentHTML('afterbegin', element);
        }

        let newElement = document.getElementById(params.inputID);
        if (newElement) {
            this.injectTypeForRevision(newElement, object, { currentRevisionDate: params.currentRevisionDate });
            this.injectMasking(newElement, object);
        }
    }

    static insertFieldsRevisionModalNewContent(element, object, params) {
        let formHtml = params.formHtml,
            formGroup = `
            <div class="form-group">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-9">
                </div>
            </div>
        `;

        formHtml.insertAdjacentHTML('afterbegin', formGroup);
        let formGroupChildren = formHtml.children[0].children,
            formGroupLabelElem = formGroupChildren[0],
            formGroupInputElem = formGroupChildren[1];

        // INSERT THE LABEL
        formGroupLabelElem.insertAdjacentHTML('afterbegin', '<p>' + params.label + '</p>');

        if (typeof element == "object") {
            formGroupInputElem.appendChild(element);
        } else if (typeof element == "string") {
            formGroupInputElem.insertAdjacentHTML('afterbegin', element);
        }

        let newElement = document.getElementById(params.inputID);
        if (newElement) {
            this.injectTypeForRevision(newElement, object, { currentRevisionDate: params.currentRevisionDate });
            this.injectMasking(newElement, object);
        }
    }

    static initRevisionNewModal(element, object) {
        let self = this,
            dBObjectAttributeID = object.DBObjectAttributeID,
            revisionModalID = self.getRevisionStaticID({ toBeReturned: "revisionModalNewID", dBObjectAttributeID: dBObjectAttributeID }),
            revisionModalBodyID = self.getRevisionStaticID({ toBeReturned: "revisionModalBodyNewID", dBObjectAttributeID: dBObjectAttributeID }),
            revisionModalPosBtnID = self.getRevisionStaticID({ toBeReturned: "revisionModalNewPosBtnID", dBObjectAttributeID: dBObjectAttributeID }),
            headerText = "New " + object.DefaultLabel;

        if (document.getElementById(revisionModalID) == null) {
            let cont = document.getElementById("modalContainer"),
                modalElem = document.getElementById(revisionModalID),
                modalHtml =
                    `<div class="modal fade" id='` + revisionModalID + `'>
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h4 class="modal-title">`+ headerText + `</h4>
                                </div>
                                <div class="modal-body" id='`+ revisionModalBodyID + `'></div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                    <button id='`+ revisionModalPosBtnID + `' type="button" class="btn btn-primary">Proceed</button>
                                </div>
                            </div>
                        </div>
                    </div>`;

            if (modalElem != null) {
                modalElem.parentNode.removeChild(modalElem);
            }
            cont.insertAdjacentHTML('beforeend', modalHtml);

            let revisionModalPosBtn = document.getElementById(revisionModalPosBtnID);
            if (revisionModalPosBtn) {
                revisionModalPosBtn.removeEventListener('click', onClickRevisionModalPosBtn);
                revisionModalPosBtn.addEventListener('click', onClickRevisionModalPosBtn);
            }
        }

        function onClickRevisionModalPosBtn(event) {
            self.injectBtnPosFunction(event, object);
        }

        return document.getElementById(revisionModalID);
    }

    static createElementForRevisionNew(object, params) {
        let name = object.Name,
            htmlStr = '',
            inputID = params.inputID,
            checkBoxHtml = `<input id='` + inputID + `'/>`,
            inpHtml = `<input id='` + inputID + `' class='form-control' placeholder='` + object.DefaultLabel + `' />`,
            selectHtml = `<select id='` + inputID + `' class='form-control' placeholder='` + object.DefaultLabel + `' />`;

        if (object.IsPicker) {
            htmlStr = inpHtml;
        } else if (object.IsSelectList) {
            htmlStr = selectHtml;
        } else if (object.isFileUpload || object.IsFileUpload) {

        } else if (object.IsHierarchyList) {
            htmlStr = inpHtml;
        } else if (object.AttributeName.toLowerCase().includes("password")) {
            htmlStr = inpHtml;
        } else if (object.IsColourPicker) {
            htmlStr = inpHtml;
        } else {
            if (name === "String") {
                htmlStr = inpHtml;
            } else if (name === "Date" || name === "Time" || name === "DateTime" || name === "date") {
                htmlStr = inpHtml;
            } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {
                htmlStr = inpHtml;
            } else if (name === "Bit") {
                htmlStr = checkBoxHtml;
            } else if (name === "Email") {
                htmlStr = inpHtml;
            }
        }

        return htmlStr;
    }

    static injectTypeForRevision(element, object, params = {}) {
        let self = this,
            name = object.Name,
            type = "",
            val = params.value,
            currentRevisionDate = params.currentRevisionDate;

        if (object.IsPicker) {
            this.createButtonExtension(element, object);
        } else if (object.IsSelectList) {
            if (object.ListValue != null) {
                if (element.options.length == 0) {
                    if (object.ListValue != null && object.ListValue.includes("SPG:")) {
                        this.createDefaultPleaseSelect(element);
                    }
                    this.renderOptions(element, object);
                }
            }
        } else if (object.isFileUpload || object.IsFileUpload) {
            type = "file";
            element.value = val ? val : "";
        } else if (object.IsHierarchyList) {
            this.createButtonExtension(element, object);
            element.value = val ? val : "";
        } else if (object.AttributeName.toLowerCase().includes("password")) {
            type = "password";
            element.value = val ? val : "";
        } else if (object.IsColourPicker) {
            type = "color";
            element.value = val ? val : "";
        } else {
            if (name === "String") {
                type = "text";
                element.value = val ? val : "";
            } else if (name === "Date" || name === "Time" || name === "DateTime" || name === "date") {
                let dateFormatFromSettings = Auth.getSettingsByID(2).value,
                    currentDate = dateFormat(new Date().toJSON().slice(0, 10), dateFormatFromSettings),
                    startDate = currentRevisionDate,
                    valDate = val ? val : currentDate,
                    datepickerMethods = {},
                    methods = [];

                type = "text";
                methods.push({
                    method: "setDate",
                    value: valDate
                });
                datepickerMethods['methods'] = methods;

                initDatepicker(element, dateFormatFromSettings, null, null);
                datepickerMethod(element, datepickerMethods);

                element.value = valDate;
            } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {
                if (name === "Money") {
                    let separators = self.getSeparators(object);

                    type = "text";

                    if (val) {
                        element.value = self.returnToFormatMoneyMasking(val, 2, separators);
                    }
                } else {
                    type = "number";
                    element.value = val ? val : "";
                }
            } else if (name === "Bit") {
                element.value = val ? val : "";
            } else if (name === "Email") {
                element.value = val ? val : "";
            }
            this.initMaxLength(element, object);
        }

        element.setAttribute('type', type);
    }

    static injectFunctionForRevision(element, object) {
        let self = this,
            dBObjectAttributeID = object.DBObjectAttributeID,
            theObject = object,
            name = object.Name,
            SP_MODULE = "system_revision_add",
            messageParams = {
                messageContainer: document.getElementById(self.getRevisionStaticID({ toBeReturned: "revisionModalMessageHistoryContainerID", dBObjectAttributeID: object.DBObjectAttributeID }))
            };

        messageParams.command = 0;
        self.initMessage(messageParams);
        messageParams.command = null;

        try {
            if (object.IsPicker) {

            } else if (object.IsSelectList) {
                element.removeEventListener("change", inputGenericCallback);
                element.addEventListener("change", inputGenericCallback);
            } else if (object.isFileUpload || object.IsFileUpload) {

            } else if (object.IsHierarchyList) {

            } else if (object.AttributeName.toLowerCase().includes("password")) {
                element.removeEventListener("change", inputGenericCallback);
                element.addEventListener("change", inputGenericCallback);
            } else if (object.IsColourPicker) {
                element.removeEventListener("change", inputGenericCallback);
                element.addEventListener("change", inputGenericCallback);
            } else {
                if (name === "String") {
                    element.removeEventListener("change", inputGenericCallback);
                    element.addEventListener("change", inputGenericCallback);
                } else if (name === "Date" || name === "Time" || name === "DateTime" || name === "date") {
                    datePickerEvent([
                        {
                            element: element,
                            event: "changeDate",
                            callback: dateCallback
                        }
                    ]);
                } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {
                    if (name === "Money" || name === "Double") {
                        element.removeEventListener("change", inputMoneyCallback);
                        element.addEventListener("change", inputMoneyCallback);
                    } else {
                        element.removeEventListener("change", inputCallback);
                        element.addEventListener("change", inputCallback);
                    }
                } else if (name === "Bit") {
                    element.removeEventListener("change", inputGenericCallback);
                    element.addEventListener("change", inputGenericCallback);
                } else if (name === "Email") {
                    element.removeEventListener("change", inputGenericCallback);
                    element.addEventListener("change", inputGenericCallback);
                }
            }
        } catch (Exception) {
            console.log(Exception);
            messageParams.isError = true;
            messageParams.message = Exception.message;

            self.initMessage(messageParams);
        }

        function dateCallback(e) {
            let targetElement = e.target,
                targetValue = targetElement.value,
                params = {},
                RID = "",
                oldValue = "";

            RID = targetElement.getAttribute("data-rid");
            oldValue = targetElement.getAttribute("data-oldvalue");

            if (targetValue.trim() != "") {
                let relatedElements = getRelatedElementData({ element: element }),
                    parameters = {
                        ObjectAttributeID: dBObjectAttributeID,
                        EmployeeID: self.getEmployeeId(),
                        DateEffective: targetValue,
                        RID: RID
                    };

                parameters = insertRelatedElementData({
                    parameters: parameters,
                    relatedElements: relatedElements
                });

                params["module"] = SP_MODULE;
                params["parameters"] = parameters;
                params["object"] = relatedElements[0].object;

                initCallSP(params);
            }
        }

        function inputMoneyCallback(e) {
            let separators = self.getSeparators(object),
                targetElement = e.target,
                targetValue = targetElement.value,
                params = {},
                item = {},
                RID = "",
                oldValue = "";

            RID = targetElement.getAttribute("data-rid");
            oldValue = targetElement.getAttribute("data-oldvalue");
            item = targetElement.getAttribute("data-item");

            if (item) {
                item = JSON.parse(item);
            } else {
                item = {};
            }

            if (targetValue.trim() != "") {
                targetValue = targetValue.length > 0 ? self.returnFromFormatMoneyMasking(targetValue, 2, separators) : null;
                targetElement.value = self.returnToFormatMoneyMasking(targetValue, 2, separators);
                let parameters = {
                    ObjectAttributeID: dBObjectAttributeID,
                    EmployeeID: self.getEmployeeId(),
                    Value: targetValue,
                    RID: RID
                };

                let relatedElements = getRelatedElementData({ element: element });

                parameters = insertRelatedElementData({
                    parameters: parameters,
                    relatedElements: relatedElements
                });
                params["object"] = relatedElements[0].object;

                params["module"] = SP_MODULE;
                params["parameters"] = parameters;

                initCallSP(params);
            } else {
                targetElement.value = self.returnToFormatMoneyMasking(oldValue, 2, separators);
            }
        }

        function inputGenericCallback(e) {
            let targetElement = e.target,
                targetValue = targetElement.value,
                params = {},
                RID = "",
                oldValue = "";

            RID = targetElement.getAttribute("data-rid");
            oldValue = targetElement.getAttribute("data-oldvalue");

            if (targetValue.trim() != "") {
                let relatedElements = getRelatedElementData({ element: element }),
                    parameters = {
                        ObjectAttributeID: dBObjectAttributeID,
                        EmployeeID: self.getEmployeeId(),
                        Value: targetValue,
                        RID: RID
                    };

                parameters = insertRelatedElementData({
                    parameters: parameters,
                    relatedElements: relatedElements
                });

                params["module"] = SP_MODULE;
                params["parameters"] = parameters;
                params["object"] = relatedElements[0].object;

                initCallSP(params);
            } else {
                targetElement.value = oldValue;
            }
        }

        function getRelatedElementData(params) {
            let element = params.element,
                currentElementID = element.id,
                RID = element.getAttribute("data-rid"),
                relatedElement = document.querySelectorAll('[id*="' + RID + '"]'),
                relatedElementData = [];

            if (relatedElement) {
                relatedElement.forEach(relatedElementItem => {
                    if (relatedElementItem.id != currentElementID && relatedElementItem.tagName != "BUTTON") {
                        let itemKey = relatedElementItem.getAttribute("data-key"),
                            itemRID = relatedElementItem.getAttribute("data-rid"),
                            itemType = relatedElementItem.getAttribute("data-type"),
                            itemObject = JSON.parse(relatedElementItem.getAttribute("data-object")),
                            itemValue = relatedElementItem.value,
                            itemData = {},
                            itemDataValue = "",
                            itemDataObject = itemObject;

                        switch (itemType) {
                            case "date":
                                itemDataValue = itemValue;
                                break;
                            case "money":
                            case "number":
                                let separators = self.getSeparators(object);

                                itemDataValue = self.returnFromFormatMoneyMasking(itemValue, 2, separators);
                                break;
                            case "string":
                                itemDataValue = itemValue;
                                break;
                            default:
                                itemDataValue = itemValue;
                                break;
                        }

                        itemData['object'] = itemObject;
                        itemData['value'] = itemDataValue;
                        itemData['type'] = itemType;
                        itemData['key'] = itemKey;
                        relatedElementData.push(itemData);
                    }
                })
            }

            return relatedElementData;
        }

        function insertRelatedElementData(params) {
            let parameters = params.parameters,
                relatedElements = params.relatedElements;

            relatedElements.forEach(relatedElement => {
                parameters[relatedElement.key] = relatedElement.value;
            });

            return parameters;
        }

        function initCallSP(params) {
            let requestjson = {
                Module: params.module,
                Parameters: params.parameters
            }, config = {
                Data: { requestjson: requestjson },
                ExtraData: params.object,
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            };

            waitingDialog.show("Updating...", { dialogSize: 'sm', progressType: 'danger' });
            self.genericQuery(config);
        }

        function responseSuccessCallback(responseJson, config) {
            waitingDialog.hide();
            let data = responseJson.data,
                extraData = config.ExtraData;

            if (data.Status.IsSuccess) {
                self.initGenerateRevisionHistoryModalContent(null, extraData, data.Data.Revisions);
            } else {
                self.initGenerateRevisionHistoryModalContent(null, extraData, data.Data.Revisions);
                Swal2.fire({
                    title: "Error",
                    text: data.Status.Message,
                    icon: 'error',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Ok"
                })

            }
        }

        function responseFailCallback(responseJson) {
            waitingDialog.hide();
            messageParams.isError = true;
            messageParams.message = responseJson.message;

            self.initMessage(messageParams);
        }
    }

    static injectRemoveRevision(event) {
        let self = this,
            messageParams = {},
            dBObjectAttributeID = 0;

        try {
            let
                targetElement = event.target,
                object = null,
                RID = null,
                formData = new FormData();

            if (targetElement.tagName == "I") {
                targetElement = targetElement.parentNode;
            }

            object = JSON.parse(targetElement.getAttribute("data-object"));
            RID = targetElement.getAttribute("data-rid");

            messageParams = {
                messageContainer: document.getElementById(self.getRevisionStaticID({ toBeReturned: "revisionModalMessageHistoryContainerID", dBObjectAttributeID: object.DBObjectAttributeID }))
            }
            messageParams.command = 0;
            self.initMessage(messageParams);
            messageParams.command = null;

            let requestjson = {
                Module: "system_revision_delete",
                Parameters: {
                    RID: RID
                }
            }, config = {
                Data: { requestjson: requestjson },
                ExtraData: object,
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            };

            waitingDialog.show("Removing...", { dialogSize: 'sm', progressType: 'danger' });
            self.genericQuery(config);
        } catch (Exception) {
            messageParams.isError = true;
            messageParams.message = Exception.message;

            self.initMessage(messageParams);
        }

        function responseSuccessCallback(responseJson, config) {
            waitingDialog.hide();
            let data = responseJson.data,
                extraData = config.ExtraData;

            if (data.Status.IsSuccess) {
                self.initGenerateRevisionHistoryModalContent(null, extraData, data.Data.Revisions);
            } else {
                messageParams.isError = true;
                messageParams.message = data.Status.Message;

                self.initMessage(messageParams);
            }
        }

        function responseFailCallback(responseJson) {
            waitingDialog.hide();
            messageParams.isError = true;
            messageParams.message = responseJson.message;

            self.initMessage(messageParams);
        }

        function reloadHistory(params) {
            let object = params.object,
                dBObjectAttributeID = object.DBObjectAttributeID,
                requestjson = {
                    Module: SP_LIST[dBObjectAttributeID],
                    Parameters: {
                        ObjectAttributeID: dBObjectAttributeID,
                        EmployeeID: self.getEmployeeId()
                    }
                }, config = {
                    Data: { requestjson: requestjson },
                    ExtraData: params.object,
                    Method: "GET",
                    ResponseSuccessCallback: responseSuccessCallback,
                    ResponseFailCallback: responseFailCallback,
                    Url: "/single_api/"
                };

            waitingDialog.show("Loading history...", { dialogSize: 'sm', progressType: 'danger' });
            self.genericQuery(config);
        }

        function responseSuccessCallback(responseJson, config) {
            waitingDialog.hide();
            let data = responseJson.data,
                extraData = config.ExtraData;

            if (data.Status.IsSuccess) {
                self.initGenerateRevisionHistoryModalContent(null, extraData, data.Data.Revisions);
            }
        }

        function responseFailCallback(responseJson) {
            waitingDialog.hide();

        }
    }

    static insertValueForRevisionElement(params) {
        let self = this,
            element = params.element,
            object = params.object,
            dBObjectAttributeID = object.DBObjectAttributeID,
            name = object.Name,
            value = params.value;

        if (object.IsPicker) {

        } else if (object.IsSelectList) {

        } else if (object.isFileUpload || object.IsFileUpload) {

        } else if (object.IsHierarchyList) {

        } else if (object.AttributeName.toLowerCase().includes("password")) {

        } else if (object.IsColourPicker) {

        } else {
            if (name === "String") {

            } else if (name === "Date" || name === "Time" || name === "DateTime" || name === "date") {
                let dateFormatFromSettings = Auth.getSettingsByID(2).value,
                    startDate = dateFormat(new Date().toJSON().slice(0, 10), dateFormatFromSettings),
                    valDate = value ? value : startDate,
                    datepickerMethods = {},
                    methods = [];

                methods.push({
                    method: "setDate",
                    value: valDate
                });
                datepickerMethods['methods'] = methods;

                datepickerMethod(element, datepickerMethods);

                element.value = valDate;
            } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {
                if (name === "Money" || name === "Double") {
                    let separators = self.getSeparators(object);

                    if (value) {
                        element.value = self.returnToFormatMoneyMasking(value, 2, separators);
                    }
                } else {
                    element.value = value ? value : "";
                }
            } else if (name === "Bit") {
                type = "checkbox";
            } else if (name === "Email") {
                type = "email";
            }
        }
    }

    static injectBtnPosFunction(event, object, element) {
        let self = this,
            dBObjectAttributeID = object.DBObjectAttributeID,
            SP_MODULE = "system_revision_add",
            targetElement = event.target,
            messageParams = {
                messageContainer: document.getElementById(self.getRevisionStaticID({ toBeReturned: "revisionModalMessageHistoryContainerID", dBObjectAttributeID: dBObjectAttributeID }))
            };

        try {
            messageParams.command = 0;
            self.initMessage(messageParams);
            messageParams.command = null;

            if (targetElement) {
                targetElement.classList.add("disabled");
                targetElement.disabled = true;
            }

            let inpVerificationList = self.getNewRevisionTimelineStaticInputVerification(dBObjectAttributeID, object),
                inputValues = [],
                errorValMsg = [],
                hasError = false;
            if (inpVerificationList) {
                inpVerificationList.forEach(item => {
                    item["value"] = this.getRevisionBtnPosValue(item, object);
                    if (item.isMandatory) {
                        if (item.value == null || item.value == "") {
                            hasError = true;
                            errorValMsg.push(item.label + " is mandatory.");
                        }
                    }
                    inputValues.push(item);
                });
                if (hasError) {
                    messageParams.isError = true;
                    messageParams.message = errorValMsg;

                    self.initMessage(messageParams);
                } else {
                    let requestjson = {
                        Module: SP_MODULE,
                        Parameters: {
                            ObjectAttributeID: dBObjectAttributeID,
                            EmployeeID: self.getEmployeeId(),
                            Value: inputValues[0].value,
                            DateEffective: inputValues[1].value
                        }
                    }, config = {
                        Data: { requestjson: requestjson },
                        Method: "GET",
                        ResponseSuccessCallback: responseSuccessCallback,
                        ResponseFailCallback: responseFailCallback,
                        Url: "/single_api/"
                    };

                    waitingDialog.show("Adding revision...", { dialogSize: 'sm', progressType: 'danger' });
                    self.genericQuery(config);



                }
            }
        } catch (Exception) {
            messageParams.isError = true;
            messageParams.message = Exception.message;

            self.initMessage(messageParams);
        } finally {
            if (targetElement) {
                targetElement.classList.remove("disabled");
                targetElement.disabled = false;
            }
        }

        function responseSuccessCallback(responseJson) {
            waitingDialog.hide();
            let data = responseJson.data,
                revisions = data.Data.Revisions;

            if (data.Status.IsSuccess) {
                self.modifyRevisionSpecificDates({
                    revisions: revisions
                });
                messageParams.isError = false;
                messageParams.message = "Successfully added a new revision";

                self.initMessage(messageParams);
                self.initGenerateRevisionHistoryModalContent(element, object, data.Data.Revisions);
            
            } else {
                messageParams.isError = true;
                messageParams.message = data.Status.Message;

                self.initMessage(messageParams);
            }
        }

        function responseFailCallback(responseJson) {
            waitingDialog.hide();
            messageParams.isError = true;
            messageParams.message = responseJson.message;

            self.initMessage(messageParams);
        }
    }

    static getRevisionBtnPosValue(item, object) {
        let self = this,
            itemElem = document.getElementById(item.inputID),
            itemElemValue = null,
            val = "";

        switch (item.type) {
            case "string":
                itemElemValue = itemElem.value;
                val = itemElemValue;
                break;
            case "money":
            case "number":
                let separators = self.getSeparators(object);
                itemElemValue = itemElem.value;
                val = itemElemValue.length > 0 ? self.returnFromFormatMoneyMasking(itemElem.value, 2, separators) : null;
                break;
            case "date":
                itemElemValue = itemElem.value;
                val = itemElemValue.length > 0 ? itemElemValue : null;
                break;
            default:
                itemElemValue = itemElem.value;
                val = itemElemValue;
                break;
        }
        return typeof val == "string" ? val.trim() : val;
    }

    static modifyRevisionSpecificDates(params) {
        let _this = this,
            newRevisionSpecificDates = params.revisions,
            newRevisionSpecificDate;

        if (newRevisionSpecificDates) {
            newRevisionSpecificDate = newRevisionSpecificDates.find(item => {
                return item && item.RevisionID == 0;
            });
            if (newRevisionSpecificDate) {
                const revisionSpecificDateIndex = revisionSpecificDates.findIndex(item => {
                    return item && item.DBObjectAttributeID == newRevisionSpecificDate.DBObjectAttributeID;
                });
                if (revisionSpecificDateIndex < 0) {
                    revisionSpecificDates.push(newRevisionSpecificDate);
                    _this.modifyRevisionSpecifiFields({ revisiorevisionSpecificDate: newRevisionSpecificDate });
                }
            }
        }
    }

    static modifyRevisionSpecifiFields(params) {
        const _this = this,
            revisionSpecificDate = params.revisiorevisionSpecificDate,
            dBObjectAttributeID = revisionSpecificDate.DBObjectAttributeID,
            groupHtmlInputElementID = _this.getRevisionStaticID({ toBeReturned: "groupHtmlInputElementID", dBObjectAttributeID: dBObjectAttributeID }),
            groupHtmlDateElementID = _this.getRevisionStaticID({ toBeReturned: "groupHtmlDateElementID", dBObjectAttributeID: dBObjectAttributeID }),
            groupHtmlInputElement = document.getElementById(groupHtmlInputElementID),
            groupHtmlDateElement = document.getElementById(groupHtmlDateElementID);

        if (groupHtmlInputElement) {
            groupHtmlInputElement.value = revisionSpecificDate.Value;
        }

        if (groupHtmlDateElement) {
            groupHtmlDateElement.value = revisionSpecificDate.DateEffective;
        }
    }

    static initMessage(params) {
        let command = params.command,
            isError = params.isError,
            message = params.message,
            messageContainer = params.messageContainer,
            messageHtml = "";

        if (command == 0) {
            while (messageContainer.firstChild) {
                messageContainer.removeChild(messageContainer.firstChild);
            }
        } else if (command != 0 && messageContainer) {
            while (messageContainer.firstChild) {
                messageContainer.removeChild(messageContainer.firstChild);
            }

            if (typeof message == "string") {
                messageHtml = "<span>" + message + "</span>";
            } else if (typeof message == "object") {
                let messageTemp = "",
                    x;
                for (x in message) {
                    messageTemp += "<li>" + message[x] + "</li>";
                }
                messageHtml = "<ul>" + messageTemp + "</ul>";
            }

            let newInnerHtml = `
                <div class="alert alert-`+ (isError ? `danger` : `success`) + `">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    `+ (messageHtml) + `
                </div>`;

            messageContainer.insertAdjacentHTML('beforeend', newInnerHtml);
        }
    }

    static injectMasking(element, object) {
        let name = object.Name;

        if (object.IsPicker) {

        } else if (object.IsSelectList) {

        } else if (object.isFileUpload || object.IsFileUpload) {

        } else if (object.IsHierarchyList) {

        } else if (object.AttributeName.toLowerCase().includes("password")) {

        } else if (object.IsColourPicker) {

        } else {
            if (name === "String") {
                if (object.HasRule) {
                    if (object.RuleValue == "IPADDRESS") {
                        this.initIPFormatMasking(element, object);
                    }
                }
            } else if (name === "Date" || name === "Time" || name === "DateTime" || name === "date") {

            } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {
                if (name == "Money" || name == "Double") {
                    this.initMoneyFormatMasking(element, object);
                } else {

                }

            } else if (name === "Bit") {

            } else if (name === "Email") {

            }
        }
    }

    static injectLabels(element, object) {
        let defaultLabel = object.DefaultLabel,
            $label = $("#" + object.DBObjectAttributeID);
        $label.text(defaultLabel);
        $(element).attr('placeholder', defaultLabel);
    }

    static injectHoverText(element, object) {
        let hoverText = object.HoverText,
            onHoverElement = (event) => {
                let type = event.type;

                switch (type) {
                    case "mouseenter":
                        let title = $(element).attr('title');

                        if (title == null && element) {
                            element.title = hoverText == undefined ? "" : hoverText;
                            element.style.cursor = "help";
                        }
                        break;
                    case "mouseout":
                        break;
                }
            };

        $(element).hover(null);
        $(element).hover(onHoverElement);
    }

    static hideParentFunction(element) {
        var $parentDiv = $(element).parent().parent();
        $parentDiv.css("display", "none");
    }

    static injectLazyLoad(element, object) {
        if (!element.getAttribute("isListLoaded")) {
            var self = this;
            $('#' + element.id.split("].")[1]).unbind();
            $("#" + element.id.split("].")[1]).on("mouseover", function () {
                onClick(object, element, self, false);
            });
            $("#" + element.id.split("].")[1]).on("mouseout", function () {
                onClick(object, element, self, false);
            });
            $("#" + element.id.split("].")[1]).on("click", function () {
                onClick(object, element, self, false);
            });
            element.objectParam = object;
            element.classParam = this;
            element.isClicked = false;

            function onClick(object, element, self, isClicked) {
                if (element != null && !isClicked) {
                    if (!element.getAttribute("isListLoaded")) {
                        event.target.isClicked = true;
                        paramClass.getLisValueData(element, object, object.ListValue.split(":"), "SP");
                    }
                }
            }
        }
    }

    static injectionRequired(element, object) {
        element.setAttribute('required', object.IsMandatory);
    }

    static injectionMandatory(element, object) {
        if (object.IsMandatory && element.type != null) {
            if (element.type == "checkbox") {
                element.className = "mandatory";
            } else {
                element.className = "form-control mandatory";
            }
        }
    }

    static MaxMinFunction(element, object) {
        if (object.AttributeExtension != null) {
            var VarObject = object.AttributeExtension.split(";")
            var min = VarObject[0].split(":");
            var max = VarObject[1].split(":");
            var step = VarObject[2].split(":");
            element.setAttribute('max', max[1]);
            element.setAttribute('min', min[1]);
            element.setAttribute('step', step[1]);
        }
    }

    static checkInputFieldError(element) {
        if (element.required) {
            if (element.type != "checkbox") {
                if (element.value == "") {
                    element.className = "form-control mandatory";
                }
                else {
                    element.className = "form-control"
                }
            }
        }
    }

    static injectionMandatoryField(element, object) {
        if (object.IsMandatory) {
            if (element.type == "checkbox") {
                element.className = "mandatory";
            } else {
                element.className = "form-control mandatory";
            }
        }
    }

    static injectionDisabled(element, object) {

        var RW = object.RW,
            disabled = true;

        if (object.IsFileUpload) {
            if (RW != 2) {
                element.style.display = "none";
            }
        } else if (object.IsPicker || object.IsHierarchyList || object.IsRevisionSpecific) {
            if (object.RW == 2) {
                element.classList.add("form-editable");
            }
        } else {
            if (RW == 2) {
                if (object.AttributeName == "[6181].Name") {
                    element.setAttribute('disabled', true);
                }
                else {
                    element.removeAttribute('disabled');
                }
            } else if (RW == 1) {
                element.setAttribute('disabled', true);
                if (object.AttributeName == "[6181].Name") {
                    var x = document.getElementById("lineBusinessContainer");
                    x.style.display = "none";
                }
            } else {
                element.setAttribute('disabled', true);
                element.className = (element.type == "checkbox") ? "disable" : "form-control disable";
            }
        }

        var name = object.Name;
        if (name === "Bit") {
            if (object.RuleValue == "PrimaryPosition") {
                if (object.Value == "1") {
                    $(element).attr('disabled', true);
                } else {
                    $(element).removeAttr('disabled');
                }
            }
        }
    }

    static hideParentContainer(object) {
        var element = document.getElementById("div." + object.AttributeName);
        if (element != null) {
            element.style.display = "none";
        }
    }

    static createButtonExtension(element, object) {

        var parent = element.parentNode;
        if (parent.getAttribute("isChanged") == null) {
            $(parent).replaceWith(this.buttonAddOn(element, object));
            element = document.getElementById(element.id);
            element.readOnly = true;
            element.setAttribute('type', "text");
            if (object.HasMultipleValues) {
                element.setAttributeNode(createNewAttribute("rid", object.RID));
            }
            this.injectManagerOnClick(element, object, document.getElementById("inpBtn" + element.id));

        }
    }

    static buttonAddOn(element, object) {
        let elementID = element.id,
            elementRID = element.getAttribute("rid"),
            parentNode = element.parentNode,
            className = "col-sm-8";

        if (parentNode) {
            className = parentNode.className;
        }

        if (object.Value) {
            return `
                    <div class="${className}">
                        <div class="input-group" isChanged=true>
                            <input id="`+ elementID + `" placeholder="` + object.DefaultLabel + `" class="form-control search-input" disabled />
                            <span class="input-group-btn">
                                <button id="inpBtn`+ elementID + `" class="btn btn-default" type="button">Search</button>
                            </span>
                        </div>
                </div>
                    `;

        }
        else {
            return `
                <div class="${className}">
                    <div class="input-group" isChanged=true>
                        <input id="`+ elementID + `" placeholder="` + object.DefaultLabel + `" class="form-control search-input mandatory" disabled />
                        <span class="input-group-btn">
                            <button id="inpBtn`+ elementID + `" class="btn btn-default" type="button">Search</button>
                        </span>
                    </div>
            </div>
                `;
        }
    }

    static renderOptions(element, object) {
        var listValue = object.ListValue;
        if (listValue != null) {
            if (typeof listValue === "string") {
                if (object.ListValue != null && listValue.includes("SPG:") && listValue.split(":")[0] !== "SA") {
                    this.getLisValueData(element, object, object.ListValue.split(":"), "SP");
                    this.injectLazyLoad(element, object);
                } else if (object.ListValue != null && listValue.includes("SPG:") && listValue.split(":")[0] === "SA") {
                    let listValueModule = listValue.split(":").slice(2).join(":");
                    this.getListValueDataSA(element, object, listValueModule)
                } else if (listValue.includes(";")) {
                    var type = "";
                    if (listValue.includes("|")) {
                        type = "stringWithPipe";
                    } else {
                        type = "string";
                    }
                    listValue = listValue.split(";");
                    this.mapListValueData(element, listValue, type, object.Value);
                }
            }
        }
    }

    static injectValueForSelect(element, object) {
        let theValue,
            theElement = element;
        if (object.GroupData != undefined) {
            theValue = this.getObjFkNameArrayValue(object.AttributeName, object.Value);
        } else if (object.HasMultipleValues) {
            theValue = this.getObjFkNameArrayValue(object.AttributeName, object.Value);
        }
        else if (object.Value === null) {
            theValue = "Please select..."
        }
        else {
            theValue = this.getObjkValue(object.AttributeName);
        }

        if (object.IsSelectList) {
            if (object.ListValue != null) {
                if (object.Value != null && object.ListValue.includes("SPG:")) {
                    this.createOptions(theElement, object.Value, theValue);
                }
                else if (object.Value === null) {
                    this.createOptions(theElement, object.Value, theValue);
                }
            } else {
                this.createOptions(theElement, object.Value, theValue);
            }
        }
    }

    static injectValuesConditioner(element, object) {
        if (object.GroupData != undefined) {

            element.value = this.getObjFkNameArrayValue(object.AttributeName, object.Value);
        } else if (object.HasMultipleValues) {
            element.value = this.getObjFkNameArrayValue(object.AttributeName, object.Value);
        } else {
            element.value = this.getObjkValue(object.AttributeName);
        }
    }

    static createDefaultPleaseSelect(element, positionType) {
        if(positionType == "workflowStateRole")
        {
            this.createOptions(element, '', "Please select...", positionType);
        }
        else
        {
            this.createOptions(element, '', "Please select...");
        }
    }

    //TODO
    static createOptions(element, value, text, positionType) {

        var mySelect = document.getElementById(element.id),
            newOption = document.createElement('option'),
            newOption2 = document.createElement('option');
        let newValue = value === null ? "" : value;

        if (element.id == "[59].ObjectiveTypeID") {
            if ($("*[id='" + element.id + "']").length == 0) {
                mySelect.appendChild(newOption);
            }
        }
        else {
            if ($("*[id='" + element.id + "'] option[value='" + newValue + "']").length == 0) {
                newOption.value = newValue;
                newOption.innerText = text;
                mySelect.appendChild(newOption);

                if(positionType == "workflowStateRole") {
                    newOption2.value = null;
                    newOption2.innerText = "No Position Type";
                    mySelect.appendChild(newOption2);
                }
               
            }
        }
    }


    static getLisValueData(element, object, ListValueData, type) {
        var api = "/generic_list/?ObjectType=", self = this,
            value = object.Value,
            call = api + ListValueData[1],
            thePendingObjectForQuery = self.getQueuedObject(call);
        if (thePendingObjectForQuery == undefined) {
            self.createQueuedObject(call, object, element, type);
            axios.get((API_URL) + call, {
                headers: {
                    Token: Auth.getToken()
                },
            }).then(function (response) {
                switch (response.status) {
                    case 200:
                        self.updateQueuedObject(call, "Data", response.data);
                        self.processQueuedObjects(element, call);
                        break;
                    default:
                        break;
                }
            }).catch(function (thrown) {
                console.log(thrown);
            });
        } else {
            self.updateQueuedObject(call, "Objects", {
                "Element": element,
                "IsDone": false,
                "Object": object,
                "Type": type
            });
            self.processQueuedObjects(element, call);
        }
    }

    static getListValueDataSA(element, object, listvalue) {
        let request = JSON.parse(`${listvalue}`);
        let requestjson = {
            ...request
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };


        GeneralFunctions.genericQuery(config);

        function responseSuccessCallback(responseJson) {
            let data = responseJson.data;

            if (data.Status.IsSuccess) {
                if (data.Data.GenericList === undefined) {
                    data.Data.forEach(option => {
                        if (element.options.length > 1) {
                            if (element.options[1].value != option.ID) {
                                var opt = document.createElement('option');
                                opt.value = option.ID;
                                opt.innerText = option.Value;
                                element.appendChild(opt);
                            }
                        }
                        else {
                            var opt = document.createElement('option');
                            opt.value = option.ID;
                            opt.innerText = option.Value;
                            element.appendChild(opt);
                        }
                    })
                }
            }
        }

        function responseFailCallback(responseJson) {
            alert(responseJson.message, "SA listvalue");
        }
    }

    static mapListValueData(element, ListValueData, type, objectValue) {
        var id = "", value = "";

        ListValueData.map((data) => {
            if (type == "string") {
                id = data;
                value = data;
            } else if (type == "SP") {
                id = data.ID;
                value = data.Value;
            } else if (type == "stringWithPipe") {
                var valAndId = data.split("|");
                id = valAndId[0];
                value = id + " - " + valAndId[1];
            }
            if ((type == "SP" && objectValue != id) || type == "string" || type == "stringWithPipe") {
                this.createOptions(element, id, value);
            }
        });

        if (element) {
            element.setAttribute("isListLoaded", true);
        }
    }

    static getQueuedObject(call) {
        let x,
            thePendingObjectForQuery = undefined;
        for (x in pendingObjectsForQuery) {
            let pendingObjectForQuery = pendingObjectsForQuery[x];
            if (pendingObjectForQuery.Call == call) {
                thePendingObjectForQuery = pendingObjectForQuery;
            }
        }

        return thePendingObjectForQuery;
    }

    static createQueuedObject(call, obj, element, type) {
        let elem = {}, objElem = {};
        elem["Call"] = call;
        elem["Data"] = null;
        elem["Objects"] = [];
        objElem["Element"] = element;
        objElem["IsDone"] = false;
        objElem["Object"] = obj;
        objElem["Type"] = type;

        elem["Objects"].push(objElem);
        pendingObjectsForQuery.push(elem);
    }

    static updateQueuedObject(call, key, newValue) {
        pendingObjectsForQuery.map((pendingObjectForQuery) => {
            if (pendingObjectForQuery.Call == call) {
                if (key == "Objects") {
                    pendingObjectForQuery[key].push(newValue);
                } else {
                    pendingObjectForQuery[key] = newValue;
                }
            }
        });
    }

    static processQueuedObjects(element, call) {
        let self = this,
            elementID = element.id,
            elementIDSplit = splitAttributeName(elementID);

        pendingObjectsForQuery.map((pendingObjectForQuery) => {
            if (pendingObjectForQuery.Call == call && pendingObjectForQuery.Data != null) {
                pendingObjectForQuery.Objects.map(obj => {
                    if (!obj.IsDone) {
                        let theObject = obj["Object"];
                        if (theObject.HasRule) {
                            if (theObject.RuleValue == "UpdateEmployeeHM") {
                                attachOptions(elementIDSplit, theObject, pendingObjectForQuery.Data);
                            } else if (theObject.RuleValue == "UpdateEmployeePosition") {
                                self.mapListValueData(obj.Element, pendingObjectForQuery.Data, obj.Type, obj["Object"].Value);
                            } else {
                                if (theObject.IsHierarchyList || theObject.IsPicker) {
                                    initTree(obj.Element ? obj.Element : element, theObject, pendingObjectForQuery.Data);
                                    bindTree(obj.Element ? obj.Element : element, theObject);
                                } else {
                                    self.mapListValueData(obj.Element, pendingObjectForQuery.Data, obj.Type, obj["Object"].Value);
                                }
                            }
                        } else if (theObject.IsHierarchyList || theObject.IsPicker) {
                            initTree(obj.Element ? obj.Element : element, theObject, pendingObjectForQuery.Data);
                            bindTree(obj.Element ? obj.Element : element, theObject);
                        } else {
                            self.mapListValueData(obj.Element, pendingObjectForQuery.Data, obj.Type, obj["Object"].Value);
                        }
                        obj.IsDone = true;
                    }
                })
            }
        });
    }

    static clearPendingObjectsForQuery() {
        pendingObjectsForQuery = [];
    }

    //TODO
    static injectValues(element, object) {
        var name = object.Name;
        var dataVal = [];
        var fkName = object.AttributeName.split("].")[1] + "_Name";
        if (object.IsSelectList) {
            if (object.Value === null) {
                if(object.AttributeName == "[1162].PositionTypeID")
                {
                    element.value = "null";
                }
                else
                {
                    element.value = "";
                }
            }
            else {
                element.value = object.Value;
            }
            element.setAttribute('isLoaded', true);
        } else if (object.IsFileUpload || object.isFileUpload) {
            if (object.Value != null && object.Value != "") {
                var img = document.getElementById("img." + object.AttributeName);
                img.onerror = function () {
                    this.src = '../src/assets/images/default-profile-image.png';
                };
                img.src = API_URL_ROOT_FS + object.Value;
            }
        } else if (object.IsPicker) {
            if (object.Value != null) {
                this.injectValuesConditioner(element, object);
            }
        } else if (object.IsHierarchyList) {
            if (object.Value != null) {
                if (object.GroupData) {
                    element.value = this.getObjFkNameArrayValue(object.AttributeName, object.Value);
                } else {
                    element.value = objFkName[fkName];
                }
            }
        } else if (object.IsColourPicker) {
            element.value = object.Value == null ? object.DefaultValue : object.Value;
            element.setAttribute('isLoaded', true);
        } else if (name === "String") {
            element.value = object.Value;
            element.setAttribute('isLoaded', true);
            if (object.AttributeName == "[95].Multiplier") {
                element.value = this.formatNumberToMoneySpecific(object.Value)
            }
        } else if (name === "Date" || name === "Time" || name === "DateTime") {
            let dateValue = object.Value;
            if (dateValue) {
                let methods = [],
                    datepickerMethods = {};

                methods.push({
                    method: "update",
                    value: dateValue
                });
                datepickerMethods['methods'] = methods;
                datepickerMethod(element, datepickerMethods);
                element.value = dateValue;
            }

            element.setAttribute('isLoaded', true);
        } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {
            if (name === "Money") {
                if (object.AttributeName == "[95].ConvertedCalculatedBonusPayout" && (object.Value == "-1.00" || object.Value == "-1")) {
                    element.value = "No exchange rate available";
                }
                else if (object.AttributeName == "[95].ConvertedSalaryAmount" && object.Value == "-1.00") {
                    element.value = "No exchange rate available";
                }
                else {
                    element.value = this.formatNumberToMoney(object.Value, "Currency")
                }
            } else {
                if (object.Value != null) {
                    if (object.AttributeName == "[95].MaxBonus" || object.AttributeName == "[95].BonusOpportunity" || object.AttributeName == "[95].CalculatedBonusPercent") {
                        element.value = this.formatNumberToMoneyPDF(object.Value);
                    }
                    else {
                        element.value = object.Value.split(",").join("");
                    }
                }
            }
            element.setAttribute('isLoaded', true);
        } else if (name === "Bit") {
            element.checked = (object.Value == "1") ? true : false;
            element.setAttribute('isLoaded', true);
        } else if (name === "Email") {
            element.value = object.Value;
            element.setAttribute('isLoaded', true);
        }

        if (object.HasPendingApproval) {
            this.initApproval({
                object,
                element
            });
        }
    }

    static injectFileUpload(element, object) {
        let self = this;

        element.setAttribute("name", "imageStyle");
        $("*[id='" + element.id + "']").unbind();
        $("*[id='" + element.id + "']").on("change", function () {
            previewFile(element, object, self);
        })
    }

    static onImageUpload(element, object) {
        let self = this,
            formData = new FormData(),
            newValue = toBeUploadedImages[object.AttributeName];

        formData.append("user_file", newValue);
        formData.append("employee_id", self.getEmployeeId());
        formData.append('document_type', 2);
        formData.append('user_id', Auth.getEmployeeId());
        formData.append('old_value', object.Value);
        formData.append('rid', (object.RID != null) ? object.RID + "." + object.AttributeName.split("].")[1] : "");
        self.updateByRidEmpPhoto(formData, object, newValue, element);
    }

    static getImage(element, object) {
        axios.get((API_URL) + object.Value, { //Get Form Data
            headers: {
                Token: Auth.getToken()
            },
        }).then(function (response) {
            console.log(response);
        }).catch(function (thrown) {
            console.log(thrown);
        });
    }

    static formatDate(date) {

        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        if (date.includes("-")) {
            return [year, month, day].join('-');
        } else {
            return [year, month, day].join('/');
        }
    }

    static injectFunction(element, object) {
        var name = object.Name,
            self = this;
        if (name === "Date" || name === "Time" || name === "DateTime" || name === "date") {
            setDatePickerOnChange(element, function (event) {
                onChange(element, object, self, { event });
            });
        } else if (object.IsHierarchyList || object.IsPicker) {
            this.saveChangesBtnModal(element, object);
        }
        else {
            if (name == "Money" || name == "Number" || name == "Real") {
                self.onChangeMoneyFormat(element, object);
            } else if (name == "String") {
                if (object.HasRule) {
                    if (object.RuleValue == "IPADDRESS") {
                        self.onChangeIPFormat(element, object);
                    }
                    if(object.RuleValue == "UpdatePMFunctionalKPI" && object.AttributeName == "[6186].Name")
                    {
                        element.onchange = function (event) {
                            onChange(element, object, self, { event });
                        }
                    }
                } else {
                    element.onchange = function (event) {
                        onChange(element, object, self, { event });
                    }
                }
            } else {
                if (object.AttributeExtension != null) {
                    if (object.AttributeExtension.includes('Min') && object.AttributeExtension.includes('Max')) {
                        element.onblur = function (event) {
                            onChange(element, object, self, { event });
                        }
                    } else {
                        element.onchange = function (event) {
                            onChange(element, object, self, { event });
                        }
                    }
                } else {
                    element.onchange = function (event) {
                        onChange(element, object, self, { event });
                    }
                }
            }
        }

        element.onfocus = function () {
            onFocus(element, object);
        }
        element.objectParam = object;
        element.paramClass = this;

        function onChange(element, object, self, params) {
            var newValue, oldValue = object.Value,
                UserLogin = Auth.getEmployeeId(),
                data = new FormData(),
                event = params.event;

            if (element.type == "checkbox") {
                newValue = (element.checked) ? "1" : "0";
            } else if (element.type == "file") {
                newValue = toBeUploadedImages[object.AttributeName];
            } else {
                newValue = element.value.trim();
            }

            if (element.type == "file") { // FOR FILE TYPE INPUTS
                data.append("user_file", newValue);
                data.append("employee_id", self.getEmployeeId());
                data.append('document_type', DOCU_TYPE.PHOTO);
                data.append('user_id', UserLogin);
                data.append('old_value', object.Value);
                data.append('rid', (object.RID != null) ? object.RID + "." + object.AttributeName.split("].")[1] : "");
                self.updateByRidEmpPhoto(data, object, newValue, element);
            } else {
                if (oldValue != newValue) { // FOR OTHER INPUTS
                    if (object.Name === "Date" || object.Name === "Time" || object.Name === "DateTime" || object.Name === "date") {
                        var value = object.Value;
                        data.append("Value", newValue);
                        data.append("OldValue", value);
                    } else {
                        data.append("Value", newValue);
                        data.append("OldValue", (object.Value == null) ? '' : object.Value);
                    }
                    data.append("RID", object.RID + "." + object.AttributeName.split("].")[1]);
                    data.append("userID", UserLogin);
                    if (object.ResponseText != null && object.ResponseText != "") {
                        self.showResponseModal(element, object, data, newValue);
                    } else {
                        if (object.AttributeExtension) {
                            if (object.AttributeExtension.includes('Min') || object.AttributeExtension.includes('Max')) {
                                let targetElement = event.target,
                                    validity = targetElement.validity;

                                if (validity.valid) {
                                    self.updateByRID(data, object, newValue, element);
                                } else {
                                    Swal2.fire({
                                        title: "Information",
                                        text: targetElement.validationMessage,
                                        icon: 'info',
                                        showCancelButton: true,
                                        showConfirmButton: false,
                                        cancelButtonColor: '#d33',
                                        cancelButtonText: "Ok"
                                    })

                                    targetElement.value = object.Value;
                                }
                            }
                        } else {
                            if ((object.IsSelectList && newValue != '') || !object.IsSelectList) {
                                self.updateByRID(data, object, newValue, element);
                            }
                        }
                    }
                }
            }
        }

        function validateEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        function updateValidation(element, object, value) {
            var errorValMsg = '';
            var errors = [];
            var min = element.getAttribute("min"), max = element.getAttribute("max"), length = object.Length,
                isRequired = object.IsMandatory, type = element.getAttribute("type");

            if (min != null && value < min) {
                errors.push(object.AttributeName.substring(4) + " value is lower than the minimum.");
            }
            if (max != null && value > max) {
                errors.push(object.AttributeName.substring(4) + " value is greater than the maximum.");
            }
            if (length != null && value.length > length) {
                errors.push(object.AttributeName.substring(4) + " max length only " + length);
            }

            if (isRequired && value == '') {
                errors.push(object.AttributeName.substring(4) + " is required");
            }

            if (type == "email" && value != "") {
                if (validateEmail(value)) {
                } else {
                    errors.push(object.DefaultLabel + " '" + value + "' is not valid ");
                }
            }

            if (errors == "") {
                $("#messageError").addClass('alert-hidden');
                $("#messageError").removeClass('alert-show');
            } else {
                $("#messageError").removeClass('alert-hidden');
                $("#messageError").addClass('alert-show');
            }
            return errors;
        }

        function onFocus(element, obj) {
            changeClass(element, 0, obj);
        }

        function updateByRID(data, object, newValue, element) {
            if (object.RW == 2) {
                var updateValidationChange = updateValidation(element, object, newValue)

                if (updateValidationChange == "" || updateValidationChange == undefined) {
                    changeClass(element, 1, object);
                    fetch(API_URL + "/generic_update/", { // Your POST endpoint
                        method: 'PUT',
                        headers: {
                            'token': Auth.getToken()
                        },
                        body: data
                    }).then(
                        response => response.json() // if the response is a JSON object
                    ).then(success => {
                        if (success.detail[0][0] == "ok") {
                            if (element.type == "checkbox") {
                                element.checked = (newValue == 1) ? true : false;
                            } else {
                                element.value = newValue;
                            }
                            object.Value = newValue;
                            changeClass(element, 2, object);
                        } else if (success.detail[0][1].includes("Value updated by another user")) {
                            var val = success.detail[0][2];
                            if (element.type == "checkbox") {
                                element.checked = val;
                            } else {
                                element.value = val;
                            }
                            object.Value = val;
                            changeClass(element, 3, object);
                            Swal2.fire({
                                title: "Information",
                                text: "Value updated by another user!",
                                icon: 'info',
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonColor: '#d33',
                                cancelButtonText: "Ok"
                            })

                        } else {
                            if (element.type == "checkbox") {
                                element.checked = object.Value;
                            } else {
                                element.value = object.Value;
                            }
                            changeClass(element, 3, object);
                            Swal2.fire({
                                title: "Information",
                                text: success.detail[0][1],
                                icon: 'info',
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonColor: '#d33',
                                cancelButtonText: "Ok"
                            })

                        }
                    }
                    ).catch(
                        error => {
                            console.log('error', error);
                            element.value = object.Value;
                            changeClass(element, 3, object);

                        }
                    );

                } else {
                    changeClass(element, 3, object);
                    if (element.type == "checkbox") {
                        element.checked = object.Value == 1 ? true : false;
                    } else {
                        element.value = object.Value;
                    }
                }
            } else {
                if (element.type == "checkbox") {
                    element.checked = object.Value == 1 ? true : false;
                } else {
                    element.value = object.Value;
                }
            }
        }

        function changeClass(element, func, obj) {
            if (obj != null) {
                if (obj.IsPicker || obj.IsHierarchyList) {
                    if (func == 1) {
                        element.className = "form-control search-input updating";
                        element.setAttribute('disabled', true);
                    } else if (func == 2) {
                        element.className = "form-control search-input updated";
                        element.removeAttribute('disabled');
                    } else if (func == 3) {
                        element.className = "form-control search-input error";
                        element.removeAttribute('disabled');
                    } else {
                        element.className = "form-control search-input";
                        element.removeAttribute('disabled');
                    }
                } else {
                    if (element.type != null) {
                        if (element.type == "checkbox") {
                            if (func == 1) {
                                element.className = "inp updating";
                            } else if (func == 2) {
                                element.className = "inp updated";
                            } else if (func == 3) {
                                element.className = "inp error";
                            }
                        } else {
                            if (func == 1) {
                                element.className = "form-control updating";
                            } else if (func == 2) {
                                element.className = "form-control updated";
                            } else if (func == 3) {
                                element.className = "form-control error";
                            } else {
                                element.className = "form-control";
                            }
                        }
                    } else {

                        if (func == 1) {
                            element.className = "img-rounded updating";
                        } else if (func == 2) {
                            element.className = "img-rounded updated";
                        } else if (func == 3) {
                            element.className = "img-rounded error";
                        } else {
                            element.className = "img-rounded";
                        }
                    }
                }

            } else {
                if (element.type != null) {
                    if (element.type == "checkbox") {
                        if (func == 1) {
                            element.className = "inp updating";
                        } else if (func == 2) {
                            element.className = "inp updated";
                        } else if (func == 3) {
                            element.className = "inp error";
                        }
                    } else {
                        if (func == 1) {
                            element.className = "form-control updating";
                        } else if (func == 2) {
                            element.className = "form-control updated";
                        } else if (func == 3) {
                            element.className = "form-control error";
                        } else {
                            element.className = "form-control";
                        }
                    }
                } else {

                    if (func == 1) {
                        element.className = "img-rounded updating";
                    } else if (func == 2) {
                        element.className = "img-rounded updated";
                    } else if (func == 3) {
                        element.className = "img-rounded error";
                    } else {
                        element.className = "img-rounded";
                    }
                }
            }
        }
    }

    static updateByRidEmpPhoto(formdata, object, newValue, element) {
        var self = this,
            method = (object.RID == null) ? "POST" : "PUT",
            img = document.getElementById("img." + object.AttributeName);


        this.changeClassForValidation(img, 1, object);

        //CHANGES HERE 11/12/2019
        fetch((API_URL_FS) + '/user_files/', { // Your POST endpoint
            method: method,
            headers: {
                Token: Auth.getToken()
            },
            body: formdata // This is the content of your file
        }).then(
            response => response.json()
        ).then(
            success => {
                let locationRef = success.new_value;
                formdata.append('location_reference', locationRef)
                formdata.delete('user_file')
                fetch((API_URL) + '/user_files/', {
                    method: method,
                    headers: {
                        Token: Auth.getToken()
                    },
                    body: formdata
                }).then(
                    response => response.json()
                ).then(
                    success => {
                        if (method == "PUT") {
                            var resp = success.detail[0][0];
                            this.changeClassForValidation(img, 2, object);
                            if (success.new_value != null) {
                                object.RID = resp;
                                object.Value = success.new_value;
                                if (this.getEmployeeId() == Auth.getEmployeeId()) {
                                    document.getElementById("userImageTop").src = API_URL_ROOT_FS + object.Value;
                                    document.getElementById("userImageModal").src = API_URL_ROOT_FS + object.Value;
                                }
                            }
                        }
                    }
                ).catch(
                    error => {
                        console.log('error', error);
                        self.changeClassForValidation(img, 3, object);
                        self.injectValues(element, object);
                    }
                );
                
            }// if the response is a JSON object
        ).catch(
            error => {
                console.log('error', error);
                self.changeClassForValidation(img, 3, object);
                self.injectValues(element, object);
            }
        );
    }

    static overrideUpdateByRID(dbObjectAttributeID, empID, employeePositionID, disciplineID, element, object) {
        //Change value FOR LABEL LATER

        overrideDataSource.cancel("Cancel request because another request will be made.");

        let self = this,
            newCancelToken = axios.CancelToken,
            newSource = newCancelToken.source(),
            params = {
                DBObjectAttributeID: dbObjectAttributeID,
                EmployeeID: empID,
                ReferenceID: employeePositionID,
                Value: disciplineID
            }, requestjson = {
                Module: "system_generic_add",
                Parameters: params
            }, config = {
                CancelToken: overrideDataSource,
                Data: { requestjson: requestjson },
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            };

        config = GeneralFunctions.genericQuery(config);
        overrideDataSource = config.CancelToken;

        function responseSuccessCallback(responseJson) {
            
            let data = responseJson.data;

            if (data.Status.IsSuccess) {
                let Data = data.Data;
                if (object.RW == 2) {
                    let elementID = element.id,
                        elementIDSplit = splitAttributeName(elementID);
                    if (object.IsHierarchyList) {
                        var selectedTreeObj = treeSelectedNode[elementIDSplit];
                        element.value = selectedTreeObj.value;
                        object.Value = selectedTreeObj.id;
                    }
                }
            }
            
        }

        function responseFailCallback(responseJson) {
            console.log(responseJson);
        }
    }


    static updateByRID(data, object, newValue, element) {
        if (object.RW == 2) {
            var updateValidationChange = this.updateValidation(element, object, newValue);
            let elementID = element.id,
                elementIDSplit = splitAttributeName(elementID);

            if (updateValidationChange == "" || updateValidationChange == undefined) {
                this.changeClassForValidation(element, 1, object);

                if (object.AttributeName == "[22].IsPrimaryPosition") {
                    if (confirm('Are you sure you want to update is Primary Position?')) {
                        fetch(API_URL + "/generic_update/", { // Your POST endpoint
                            method: 'PUT',
                            headers: {
                                'token': Auth.getToken()
                            },
                            body: data
                        }).then(
                            response => response.json() // if the response is a JSON object
                        ).then(success => {
                            try {
                                if (success.detail[0][0].includes("ok")) {
                                    var successDetail = success.detail[0][0],
                                        successDetailArr = successDetail.split(";");
                                    let successDetail_2 = success.detail[0][1],
                                        successDetail_2_object = null;

                                    if (successDetail != "ok") {
                                        if (!(successDetailArr[1].includes("Value") && successDetailArr[2].includes("RID"))) {
                                            this.showInformationModal(successDetail);
                                        }
                                    }

                                    if (successDetail_2) {
                                        successDetail_2_object = JSON.parse(successDetail_2);
                                    }

                                    if (element.type == "checkbox") {
                                        element.checked = (newValue == 1) ? true : false;
                                    } else if (object.IsPicker) {
                                        if (successDetail.includes("ok")) {
                                            var selectedManager = getSelectedManager(object);

                                            var newName = selectedManager != null || selectedManager != undefined ? selectedManager.Employee : '';
                                            object.Value = newValue;
                                            this.setObjkValue(object.AttributeName, newName);
                                            element.value = newName;
                                        }
                                    } else if (object.IsHierarchyList) {
                                        var selectedTreeObj = treeSelectedNode[elementIDSplit];
                                        element.value = selectedTreeObj.value;
                                        object.Value = selectedTreeObj.id;
                                    } else {
                                        if (object.Name === "Date" || object.Name === "Time" || object.Name === "DateTime" || object.Name === "date") {

                                        } else if (object.Name == "Money" || object.Name == "Number") {

                                        } else {
                                            element.value = newValue;
                                        }
                                    }

                                    if ((successDetailArr[1] != null && successDetailArr[1] != undefined) && (successDetailArr[2] != null && successDetailArr[2] != undefined)) {
                                        if (successDetailArr[1].includes("Value") && successDetailArr[2].includes("RID")) {
                                            object.Value = successDetailArr[1].split(",")[1];
                                            object.RID = successDetailArr[2].split(",")[1];

                                            if (object.AttributeName.includes("[22]")) {
                                                
                                                this.modifyRIDOfObjectDataArrayV2({
                                                    newRID: successDetailArr[2].split(",")[1],
                                                    targetProp: "EmployeePosition",
                                                    successDetail_2_object,
                                                    object,
                                                    oldRID: object.RID
                                                });
                                            }
                                        }
                                    } else {
                                        object.Value = newValue;
                                    }

                                    if (success.detail[0][1] != undefined) {
                                        let returnedJson = success.detail[0][1];
                                        if (typeof returnedJson == "string") {
                                            returnedJson = JSON.parse(returnedJson);

                                            if (returnedJson.RID != undefined) {
                                                object.RID = returnedJson.RID;
                                            }
                                        }
                                    }
                                    this.changeClassForValidation(element, 2, object);

                                    if (object.HasRule) {
                                        switch (object.RuleValue) {
                                            case 'PrimaryPosition':
                                                this.rulePrimaryPosition({
                                                    element,
                                                    object,
                                                    targetProp: "EmployeePosition"
                                                });
                                                break;
                                            case 'FTEPercent':
                                                break;
                                        }
                                    }
                                } else if (success.detail[0][1].includes("Value updated by another user") || (success.detail[0][1].includes("[104]"))) {
                                    var val = success.detail[0][2];
                                    if (val.includes("[ID]:") || val.includes("[Value]:")) {
                                        if (success.detail[0][2].includes("[ID]:")) {
                                            var id = success.detail[0][2].split("[ID]:")[1],
                                                value = success.detail[0][3].split("[Value]:")[1];

                                            object.Value = id;
                                            element.value = value;
                                        } else {
                                            var id = success.detail[0][3].split("[ID]:")[1],
                                                value = success.detail[0][2].split("[Value]:")[1];
                                            object.Value = id;
                                            element.value = value;
                                        }
                                    } else {
                                        object.Value = val;
                                        if (element.type == "checkbox") {
                                            element.checked = val;
                                        } else {
                                            if (object.Name === "Date" || object.Name === "Time" || object.Name === "DateTime" || object.Name === "date") {

                                            } else if (object.Name === "Money" || object.Name === "Number" || object.Name === "Double") {
                                                element.value = this.returnToFormatMoneyMasking(val, object.Precision, this.getSeparators(object));
                                            } else {
                                                element.value = val;
                                            }
                                        }
                                    }
                                    this.changeClassForValidation(element, 3, object);
                                    Swal2.fire({
                                        title: "Information",
                                        text: "Value updated by another user!",
                                        icon: 'info',
                                        showCancelButton: true,
                                        showConfirmButton: false,
                                        cancelButtonColor: '#d33',
                                        cancelButtonText: "Ok"
                                    })

                                } else if (success.detail[0][2] != null) {
                                    let additionalDataStr = success.detail[0][2],
                                        additionalData = JSON.parse(additionalDataStr),
                                        name = object.Name;

                                    if (additionalData) {
                                        let _status = additionalData.Status,
                                            _data = additionalData.Data;

                                        if (object.IsPicker) {

                                        } else if (object.IsSelectList) {
                                            if (_status.IsSuccess) {
                                                object.Value = newValue;

                                                this.changeClassForValidation(element, 2, object);
                                            } else {
                                                Swal2.fire({
                                                    title: "Information",
                                                    text: _status.Message,
                                                    icon: 'info',
                                                    showCancelButton: true,
                                                    showConfirmButton: false,
                                                    cancelButtonColor: '#d33',
                                                    cancelButtonText: "Ok"
                                                })

                                                element.value = object.Value;
                                                this.changeClassForValidation(element, 3, object);
                                            }
                                        } else if (object.isFileUpload || object.IsFileUpload) {

                                        } else if (object.IsHierarchyList) {

                                        } else if (object.AttributeName.toLowerCase().includes("password")) {

                                        } else if (object.IsColourPicker) {
                                            if (_status.IsSuccess) {
                                                object.Value = newValue;

                                                this.changeClassForValidation(element, 2, object);
                                            } else {
                                                Swal2.fire({
                                                    title: "Information",
                                                    text: _status.Message,
                                                    icon: 'info',
                                                    showCancelButton: true,
                                                    showConfirmButton: false,
                                                    cancelButtonColor: '#d33',
                                                    cancelButtonText: "Ok"
                                                })

                                                element.value = object.Value;
                                                this.changeClassForValidation(element, 3, object);
                                            }
                                        } else {
                                            if (name === "String") {
                                                if (_status.IsSuccess) {
                                                    object.Value = newValue;

                                                    this.changeClassForValidation(element, 2, object);
                                                } else {
                                                    Swal2.fire({
                                                        title: "Information",
                                                        text: _status.Message,
                                                        icon: 'info',
                                                        showCancelButton: true,
                                                        showConfirmButton: false,
                                                        cancelButtonColor: '#d33',
                                                        cancelButtonText: "Ok"
                                                    })
                                                    element.value = object.Value;
                                                    this.changeClassForValidation(element, 3, object);
                                                }
                                            } else if (name === "Date" || name === "Time" || name === "DateTime" || name === "date") {

                                            } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {
                                                if (_status.IsSuccess) {
                                                    object.Value = newValue;

                                                    this.changeClassForValidation(element, 2, object);
                                                } else {
                                                    element.value = object.Value;

                                                    this.changeClassForValidation(element, 3, object);
                                                }
                                            } else if (name === "Bit") {

                                            } else if (name === "Email") {
                                                if (_status.IsSuccess) {
                                                    object.Value = newValue;

                                                    this.changeClassForValidation(element, 2, object);
                                                } else {
                                                    Swal2.fire({
                                                        title: "Information",
                                                        text: _status.Message,
                                                        icon: 'info',
                                                        showCancelButton: true,
                                                        showConfirmButton: false,
                                                        cancelButtonColor: '#d33',
                                                        cancelButtonText: "Ok"
                                                    })
                                                    element.value = object.Value;
                                                    this.changeClassForValidation(element, 3, object);
                                                }
                                            }
                                        }

                                        if (object.HasRule) {
                                            switch (object.RuleValue) {
                                                case 'PrimaryPosition':

                                                    break;
                                                case 'FTEPercent':
                                                    let attributeExtension = object.AttributeExtension,
                                                        attributeExtensionArray = attributeExtension.split(';'),
                                                        min = attributeExtensionArray[0].split(':')[1],
                                                        max = attributeExtensionArray[1].split(':')[1];

                                                    const totalFTEPercent = _data.TotalFTEPercent;

                                                    if (totalFTEPercent > max) {
                                                        Swal2.fire({
                                                            title: "Information",
                                                            text: `Total FTE Percent should be lesser than or equal to ${max}.`,
                                                            icon: 'info',
                                                            showCancelButton: true,
                                                            showConfirmButton: false,
                                                            cancelButtonColor: '#d33',
                                                            cancelButtonText: "Ok"
                                                        })
                                                    } else if (totalFTEPercent < min) {
                                                        Swal2.fire({
                                                            title: "Information",
                                                            text: `Total FTE Percent should be greater than or equal to ${min}.`,
                                                            icon: 'info',
                                                            showCancelButton: true,
                                                            showConfirmButton: false,
                                                            cancelButtonColor: '#d33',
                                                            cancelButtonText: "Ok"
                                                        })
                                                    }
                                                    break;
                                            }
                                        }
                                    }
                                } else {
                                    if (element.type == "checkbox") {
                                        element.checked = object.Value;
                                    } else {
                                        element.value = object.Value;
                                    }
                                    this.changeClassForValidation(element, 3, object);
                                    Swal2.fire({
                                        title: "Information",
                                        text: success.detail[0][1],
                                        icon: 'info',
                                        showCancelButton: true,
                                        showConfirmButton: false,
                                        cancelButtonColor: '#d33',
                                        cancelButtonText: "Ok"
                                    })


                                }
                            } catch (Ex) {
                                this.changeClassForValidation(element, 3, object);
                                if (success.detail[0]) {
                                    Swal2.fire({
                                        title: "Information",
                                        text: success.detail[0],
                                        icon: 'info',
                                        showCancelButton: true,
                                        showConfirmButton: false,
                                        cancelButtonColor: '#d33',
                                        cancelButtonText: "Ok"
                                    })

                                }
                                else {
                                    alert(Ex);
                                }
                            }
                        }
                        ).catch(
                            error => {
                                console.log('error', error);
                                element.value = object.Value;
                                this.changeClassForValidation(element, 3, object);

                            }
                        );
                    } else {
                        // Do nothing!
                        console.log('Thing was not saved to the database.');
                    }
                }
                else {
                    fetch(API_URL + "/generic_update/", { // Your POST endpoint
                        method: 'PUT',
                        headers: {
                            'token': Auth.getToken()
                        },
                        body: data
                    }).then(
                        response => response.json() // if the response is a JSON object
                    ).then(success => {
                        try {
                            if (success.detail[0][0].includes("ok")) {
                                var successDetail = success.detail[0][0],
                                    successDetailArr = successDetail.split(";");
                                let successDetail_2 = success.detail[0][1],
                                    successDetail_2_object = null;

                                if (successDetail != "ok") {
                                    if (!(successDetailArr[1].includes("Value") && successDetailArr[2].includes("RID"))) {
                                        this.showInformationModal(successDetail);
                                    }
                                }

                                if (successDetail_2) {
                                    successDetail_2_object = JSON.parse(successDetail_2);
                                }

                                if (element.type == "checkbox") {
                                    element.checked = (newValue == 1) ? true : false;
                                } else if (object.IsPicker) {
                                    if (successDetail.includes("ok")) {
                                        var selectedManager = getSelectedManager(object);

                                        var newName = selectedManager != null || selectedManager != undefined ? selectedManager.Employee : '';
                                        object.Value = newValue;
                                        this.setObjkValue(object.AttributeName, newName);
                                        element.value = newName;
                                    }
                                } else if (object.IsHierarchyList) {
                                    var selectedTreeObj = treeSelectedNode[elementIDSplit];
                                    element.value = selectedTreeObj.value;
                                    object.Value = selectedTreeObj.id;
                                } else {
                                    if (object.Name === "Date" || object.Name === "Time" || object.Name === "DateTime" || object.Name === "date") {

                                    } else if (object.Name == "Money" || object.Name == "Number") {

                                    } else {
                                        element.value = newValue;
                                    }
                                }

                                if(object.AttributeName == "[2].FTEValue")
                                {
                                    if(newValue < 1)
                                    {
                                        document.getElementById("[1].EmployeeTypeID").value = 2;
                                    }
                                    else
                                    {
                                        document.getElementById("[1].EmployeeTypeID").value = 1;
                                    }
                                }

                                if ((successDetailArr[1] != null && successDetailArr[1] != undefined) && (successDetailArr[2] != null && successDetailArr[2] != undefined)) {
                                    if (successDetailArr[1].includes("Value") && successDetailArr[2].includes("RID")) {
                                        object.Value = successDetailArr[1].split(",")[1];
                                        object.RID = successDetailArr[2].split(",")[1];

                                        if (object.AttributeName.includes("[22]")) {
                                            
                                            this.modifyRIDOfObjectDataArrayV2({
                                                newRID: successDetailArr[2].split(",")[1],
                                                targetProp: "EmployeePosition",
                                                successDetail_2_object,
                                                object,
                                                oldRID: object.RID
                                            });
                                        }
                                    }
                                } else {
                                    object.Value = newValue;
                                }

                                if (success.detail[0][1] != undefined) {
                                    let returnedJson = success.detail[0][1];
                                    if (typeof returnedJson == "string") {
                                        returnedJson = JSON.parse(returnedJson);

                                        if (returnedJson.RID != undefined) {
                                            object.RID = returnedJson.RID;
                                        }
                                    }
                                }
                                this.changeClassForValidation(element, 2, object);

                                if (object.HasRule) {
                                    switch (object.RuleValue) {
                                        case 'PrimaryPosition':
                                            this.rulePrimaryPosition({
                                                element,
                                                object,
                                                targetProp: "EmployeePosition"
                                            });
                                            break;
                                        case 'FTEPercent':
                                            break;
                                    }
                                }
                            } else if (success.detail[0][1].includes("Value updated by another user") || (success.detail[0][1].includes("[104]"))) {
                                var val = success.detail[0][2];
                                if (val.includes("[ID]:") || val.includes("[Value]:")) {
                                    if (success.detail[0][2].includes("[ID]:")) {
                                        var id = success.detail[0][2].split("[ID]:")[1],
                                            value = success.detail[0][3].split("[Value]:")[1];

                                        object.Value = id;
                                        element.value = value;
                                    } else {
                                        var id = success.detail[0][3].split("[ID]:")[1],
                                            value = success.detail[0][2].split("[Value]:")[1];
                                        object.Value = id;
                                        element.value = value;
                                    }
                                } else {
                                    object.Value = val;
                                    if (element.type == "checkbox") {
                                        element.checked = val;
                                    } else {
                                        if (object.Name === "Date" || object.Name === "Time" || object.Name === "DateTime" || object.Name === "date") {

                                        } else if (object.Name === "Money" || object.Name === "Number" || object.Name === "Double") {
                                            element.value = this.returnToFormatMoneyMasking(val, object.Precision, this.getSeparators(object));
                                        } else {
                                            element.value = val;
                                        }
                                    }
                                }
                                this.changeClassForValidation(element, 3, object);
                                Swal2.fire({
                                    title: "Information",
                                    text: "Value updated by another user!",
                                    icon: 'info',
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#d33',
                                    cancelButtonText: "Ok"
                                })

                            } else if (success.detail[0][2] != null) {
                                let additionalDataStr = success.detail[0][2],
                                    additionalData = JSON.parse(additionalDataStr),
                                    name = object.Name;

                                if (additionalData) {
                                    let _status = additionalData.Status,
                                        _data = additionalData.Data;

                                    if (object.IsPicker) {

                                    } else if (object.IsSelectList) {
                                        if (_status.IsSuccess) {
                                            object.Value = newValue;

                                            this.changeClassForValidation(element, 2, object);
                                        } else {
                                            Swal2.fire({
                                                title: "Information",
                                                text: _status.Message,
                                                icon: 'info',
                                                showCancelButton: true,
                                                showConfirmButton: false,
                                                cancelButtonColor: '#d33',
                                                cancelButtonText: "Ok"
                                            })

                                            element.value = object.Value;
                                            this.changeClassForValidation(element, 3, object);
                                        }
                                    } else if (object.isFileUpload || object.IsFileUpload) {

                                    } else if (object.IsHierarchyList) {

                                    } else if (object.AttributeName.toLowerCase().includes("password")) {

                                    } else if (object.IsColourPicker) {
                                        if (_status.IsSuccess) {
                                            object.Value = newValue;

                                            this.changeClassForValidation(element, 2, object);
                                        } else {
                                            Swal2.fire({
                                                title: "Information",
                                                text: _status.Message,
                                                icon: 'info',
                                                showCancelButton: true,
                                                showConfirmButton: false,
                                                cancelButtonColor: '#d33',
                                                cancelButtonText: "Ok"
                                            })
                                            element.value = object.Value;
                                            this.changeClassForValidation(element, 3, object);
                                        }
                                    } else {
                                        if (name === "String") {
                                            if (_status.IsSuccess) {
                                                object.Value = newValue;

                                                this.changeClassForValidation(element, 2, object);
                                            } else {
                                                Swal2.fire({
                                                    title: "Information",
                                                    text: _status.Message,
                                                    icon: 'info',
                                                    showCancelButton: true,
                                                    showConfirmButton: false,
                                                    cancelButtonColor: '#d33',
                                                    cancelButtonText: "Ok"
                                                })
                                                element.value = object.Value;
                                                this.changeClassForValidation(element, 3, object);
                                            }
                                        } else if (name === "Date" || name === "Time" || name === "DateTime" || name === "date") {

                                        } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {
                                            if (_status.IsSuccess) {
                                                object.Value = newValue;

                                                this.changeClassForValidation(element, 2, object);
                                            } else {
                                                element.value = object.Value;

                                                this.changeClassForValidation(element, 3, object);
                                            }
                                        } else if (name === "Bit") {

                                        } else if (name === "Email") {
                                            if (_status.IsSuccess) {
                                                object.Value = newValue;

                                                this.changeClassForValidation(element, 2, object);
                                            } else {
                                                Swal2.fire({
                                                    title: "Information",
                                                    text: _status.Message,
                                                    icon: 'info',
                                                    showCancelButton: true,
                                                    showConfirmButton: false,
                                                    cancelButtonColor: '#d33',
                                                    cancelButtonText: "Ok"
                                                })
                                                element.value = object.Value;
                                                this.changeClassForValidation(element, 3, object);
                                            }
                                        }
                                    }

                                    if (object.HasRule) {
                                        switch (object.RuleValue) {
                                            case 'PrimaryPosition':

                                                break;
                                            case 'FTEPercent':
                                                let attributeExtension = object.AttributeExtension,
                                                    attributeExtensionArray = attributeExtension.split(';'),
                                                    min = attributeExtensionArray[0].split(':')[1],
                                                    max = attributeExtensionArray[1].split(':')[1];

                                                const totalFTEPercent = _data.TotalFTEPercent;

                                                if (totalFTEPercent > max) {
                                                    Swal2.fire({
                                                        title: "Information",
                                                        text: `Total FTE Percent should be lesser than or equal to ${max}.`,
                                                        icon: 'info',
                                                        showCancelButton: true,
                                                        showConfirmButton: false,
                                                        cancelButtonColor: '#d33',
                                                        cancelButtonText: "Ok"
                                                    })
                                                } else if (totalFTEPercent < min) {
                                                    Swal2.fire({
                                                        title: "Information",
                                                        text: `Total FTE Percent should be greater than or equal to ${min}.`,
                                                        icon: 'info',
                                                        showCancelButton: true,
                                                        showConfirmButton: false,
                                                        cancelButtonColor: '#d33',
                                                        cancelButtonText: "Ok"
                                                    })

                                                }
                                                break;
                                        }
                                    }
                                }
                            } else {
                                if (element.type == "checkbox") {
                                    element.checked = object.Value;
                                } else {
                                    element.value = object.Value;
                                }
                                this.changeClassForValidation(element, 3, object);
                                Swal2.fire({
                                    title: "Information",
                                    text: success.detail[0][1],
                                    icon: 'info',
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#d33',
                                    cancelButtonText: "Ok"
                                })


                            }
                        } catch (Ex) {
                            this.changeClassForValidation(element, 3, object);
                            if (success.detail[0]) {
                                Swal2.fire({
                                    title: "Information",
                                    text: success.detail[0],
                                    icon: 'info',
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#d33',
                                    cancelButtonText: "Ok"
                                })

                            }
                            else {
                                alert(Ex);
                            }
                        }
                    }// Handle the success response object
                    ).catch(
                        error => {
                            console.log('error', error);
                            element.value = object.Value;
                            this.changeClassForValidation(element, 3, object);

                        } // Handle the error response object
                    );
                }


            } else {
                this.changeClassForValidation(element, 3, object);
                if (element.type == "checkbox") {
                    element.checked = object.Value == 1 ? true : false;
                } else {
                    element.value = object.Value;
                }
            }
        } else {
            if (element.type == "checkbox") {
                element.checked = object.Value == 1 ? true : false;
            } else {
                element.value = object.Value;
            }
        }
    }

    static rulePrimaryPosition(params) {
        const _this = this;
        let element = params.element,
            object = params.object,
            targetProp = params.targetProp;

        objectData.forEach(obj => {
            let objTargetProp = obj[targetProp];

            if (objTargetProp) {
                if (objTargetProp.length) {
                    objTargetProp.forEach(attrib1 => {
                        let attribs = attrib1.Attributes;

                        attribs.map(attrib2 => {
                            if (attrib2) {
                                if (object.GroupData) {
                                    let objectGroupData = object.GroupData,
                                        attrib2GroupData = attrib2.GroupData;

                                    if (attrib2GroupData.GroupID == objectGroupData.GroupID && object.DBObjectAttributeID == attrib2.DBObjectAttributeID) {
                                        if (attrib2.RID != object.RID) {
                                            let attrib2Element = _this.getDynamicElement({ object: attrib2 });

                                            attrib2.Value = "0";
                                            attrib2Element.checked = false;

                                            _this.injectionDisabled(attrib2Element, attrib2);
                                            hideEmployeePositionGroupDataFn({ hide: false, object: attrib2 });
                                        } else {
                                            _this.injectionDisabled(element, object);
                                            hideEmployeePositionGroupDataFn({ hide: true, object });
                                        }
                                    }
                                }
                            }
                        });
                    });
                }
            }
        });
    }

    static validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    static updateValidation(element, object, value) {
        var errorValMsg = '';
        var errors = [];
        var min = element.getAttribute("min"), max = element.getAttribute("max"), length = object.Length,
            isRequired = object.IsMandatory, type = element.getAttribute("type");

        if (length != null && value.length > length) {
            errors.push(object.AttributeName.substring(4) + " max length only " + element.Length);
        }

        if (isRequired && value == '') {
            errors.push(object.AttributeName.substring(4) + " is required");
        }

        if (type == "email" && value != "") {
            if (this.validateEmail(value)) {
            } else {
                errors.push(object.DefaultLabel + " '" + value + "' is not valid ");
            }
        }

        if (errors == "") {
            $("#messageError").addClass('alert-hidden');
            $("#messageError").removeClass('alert-show');
        } else {
            $("#messageError").removeClass('alert-hidden');
            $("#messageError").addClass('alert-show');
        }
        return errors;
    }

    static changeClassForValidation(element, func, obj) {
        if (obj != null) {
            if (obj.IsPicker || obj.IsHierarchyList) {
                if (func == 1) {
                    element.className = "form-control search-input updating";
                    element.setAttribute('disabled', true);
                } else if (func == 2) {
                    element.className = "form-control search-input updated";
                    element.removeAttribute('disabled');
                } else if (func == 3) {
                    element.className = "form-control search-input error";
                    element.removeAttribute('disabled');
                } else {
                    element.className = "form-control search-input";
                    element.removeAttribute('disabled');
                }
            } else {
                if (element.type != null) {
                    if (element.type == "checkbox") {
                        if (func == 1) {
                            element.className = "inp updating";
                        } else if (func == 2) {
                            element.className = "inp updated";
                        } else if (func == 3) {
                            element.className = "inp error";
                        }
                    } else {
                        if (func == 1) {
                            element.className = "form-control updating";
                        } else if (func == 2) {
                            element.className = "form-control updated";
                        } else if (func == 3) {
                            element.className = "form-control error";
                        } else {
                            element.className = "form-control";
                        }
                    }
                } else {

                    if (func == 1) {
                        element.className = "img-rounded updating";
                    } else if (func == 2) {
                        element.className = "img-rounded updated";
                    } else if (func == 3) {
                        element.className = "img-rounded error";
                    } else {
                        element.className = "img-rounded";
                    }
                }
            }

        } else {
            if (element.type != null) {
                if (element.type == "checkbox") {
                    if (func == 1) {
                        element.className = "inp updating";
                    } else if (func == 2) {
                        element.className = "inp updated";
                    } else if (func == 3) {
                        element.className = "inp error";
                    }
                } else {
                    if (func == 1) {
                        element.className = "form-control updating";
                    } else if (func == 2) {
                        element.className = "form-control updated";
                    } else if (func == 3) {
                        element.className = "form-control error";
                    } else {
                        element.className = "form-control";
                    }
                }
            } else {

                if (func == 1) {
                    element.className = "img-rounded updating";
                } else if (func == 2) {
                    element.className = "img-rounded updated";
                } else if (func == 3) {
                    element.className = "img-rounded error";
                } else {
                    element.className = "img-rounded";
                }
            }
        }
    }

    static createUserDefinedInputs(object, mode, container, pageLocation, locationModal) {
        if (document.getElementById("divUserDefined") == null) {
            
            if (mode == "edit") {
                GeneralFunctions.createHeader(container, "Company Specific Data");
            } else if (mode == "add") {
                GeneralFunctions.createHeader(container, "New Employee Form");
            }
        }
        var element = document.getElementById(object.AttributeName);
        if (element != null) {
            $(element).closest('.form-group').remove();
        }
        this.createInput(container, object);
        var element = document.getElementById(object.AttributeName);
        this.injectionType(element, object, pageLocation, locationModal);
        if (mode == "edit") {
            GeneralFunctions.injectionDisabled(element, object);
            GeneralFunctions.injectValues(element, object);
            this.injectFunction(element, object);
        } else {
            this.injectionRequired(element, object);
            this.injectionMandatory(element, object);
            this.MaxMinFunction(element, object);
        }
    }

    static createHeader(element, text) {
        var htmlStr = "<div id='divUserDefined' class='form-group'><div class='col-sm-12'><h3>" + text + "</h3></div></div>";
        element.insertAdjacentHTML('beforeend', htmlStr);
    }

    static createInput(element, object) {
        var checkBoxHtml = `
                <div class='form-group'>
                    <div class='col-sm-2'><p title="`+ object.HoverText + `">` + object.DefaultLabel + `</p></div>
                    <div class='col-sm-8'><input id='` + object.AttributeName + `'/></div>
                </div>`,
            inpHtml = `
                <div class='form-group'>
                    <div class='col-sm-2'><p title="`+ object.HoverText + `">` + object.DefaultLabel + `</p></div>
                    <div class='col-sm-8'><input id='` + object.AttributeName + `' class='form-control disable' disabled placeholder='` + object.DefaultLabel + `' /></div>
        </div>
                `,
            selectHtml = `
                <div class='form-group'>
                    <div class='col-sm-2'><p title="`+ object.HoverText + `">` + object.DefaultLabel + `</p></div>
                    <div class='col-sm-8'><select id='` + object.AttributeName + `' class='form-control disable' disabled placeholder='` + object.DefaultLabel + `' /></div>
        </div>
                `, htmlStr = "", type = "", name = object.Name;

        if (object.IsSelectList) {
            htmlStr = selectHtml;
        } else if (name === "String") {
            htmlStr = inpHtml;
        } else if (name === "Date" || name === "Time" || name === "DateTime") {
            htmlStr = inpHtml;
        } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {
            htmlStr = inpHtml;
        } else if (name === "Bit") {
            htmlStr = checkBoxHtml;
        } else if (name === "Email") {
            htmlStr = inpHtml;
        }
        element.insertAdjacentHTML('beforeend', htmlStr);
    }

    static createResponseModal() {

        try {

            let cont = document.getElementById("modalContainer"),
                modalHtml =
                    `<div class="modal fade" id="modalResponse">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h4 class="modal-title" id="modalResponseHeader"></h4>
                                </div>
                                <div class="modal-body" id="modalResponseBody">
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <p id="modalResponseParag"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button id="modalResponseNeg" type="button" class="btn btn-default" data-dismiss="modal"></button>
                                    <button id="modalResponsePos" type="button" class="btn btn-primary"></button>
                                </div>
                            </div>
                        </div>
                    </div>`;

            if (document.getElementById("modalResponse") != null) {
                $("#modalResponse").remove();
            }

            cont.insertAdjacentHTML('beforeend', modalHtml);

        } catch(e){
            console.log(e)
        }
    }

    static createResponseModalHistory() {
        let cont = document.getElementById("modalContainerForHistoryFMEmployeeID"),
            modalHtml =
                `<div class="modal fade" id="modalResponse">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h4 class="modal-title" id="modalResponseHeader"></h4>
                            </div>
                            <div class="modal-body" id="modalResponseBody">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <p id="modalResponseParag"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button id="modalResponseNeg" type="button" class="btn btn-default" data-dismiss="modal"></button>
                                <button id="modalResponsePos" type="button" class="btn btn-primary"></button>
                            </div>
                        </div>
                    </div>
                </div>`;

        if (document.getElementById("modalResponse") != null) {
            $("#modalResponse").remove();
        }
        cont.insertAdjacentHTML('beforeend', modalHtml);
    }

    static createInformationModal() {

        try {

            var cont = document.getElementById("modalContainer"),
                modalHtml =
                    `<div class="modal fade" id="modalInformation">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h4 class="modal-title" id="modalInformationHeader"></h4>
                                </div>
                                <div class="modal-body" id="modalInformationBody">
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <p id="modalInformationParag"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button id="modalInformationPos" type="button" class="btn btn-primary" data-dismiss="modal"></button>
                                </div>
                            </div>
                        </div>
                    </div>`;

            if (document.getElementById("modalInformation") != null) {
                $("#modalInformation").remove();
            }

            cont.insertAdjacentHTML('beforeend', modalHtml);

        } catch(e){
            console.log(e)
        }
    }

    static showResponseModal(element, object, data, newValue, employeeName, parentEmployeePosition) {
        if (object.AttributeName == "[22].PositionID") {
            if (newValue == null || newValue == "") {
                Swal2.fire({
                    title: "Error",
                    text: "Nothing Selected",
                    icon: 'error',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Ok"
                })
            }
            else {
                getEmployeePositionAttributesAssignNewPosition(object.GroupData.ElementID, "Assign New Position", newValue)
            }
        }
        else if (object.AttributeName == "[22].ParentEmployeePositionID") {
            getEmployeeAttributesHierarchicalManager(object.GroupData.ElementID, "Assign New Hierarchical Manager", newValue, employeeName, parentEmployeePosition)
        }
        else {
            var modalResponse = document.getElementById("modalResponse"), modalResponseHeader = document.getElementById("modalResponseHeader"),
                modalResponseParag = document.getElementById("modalResponseParag"), modalResponseNeg = document.getElementById("modalResponseNeg"),
                modalResponsePos = document.getElementById("modalResponsePos"),
                self = this;

            modalResponseNeg.style.display = "none";
            modalResponsePos.style.display = "none";

            var responses = object.ResponseText.split(";");
            var responsesBtns = responses[2].split(",");
            

            modalResponseHeader.innerText = responses[0];
            modalResponseParag.innerText = responses[1];
            responsesBtns.forEach(function (item) {
                if (item == "Yes" || item == "Ok") {
                    modalResponsePos.style.display = "inline";
                    modalResponsePos.innerText = item;
                    $('#' + modalResponsePos.id).unbind();
                    $('#' + modalResponsePos.id).on('click', function () {
                        hideModal(document.getElementById("modalResponse"));
                        onClickPos(object, element, newValue, data, self);
                    });

                } else {
                    modalResponseNeg.style.display = "inline";
                    modalResponseNeg.innerText = item;
                    $('#' + modalResponseNeg.id).unbind();
                    $('#' + modalResponseNeg.id).on('click', function () {
                        onClickNeg(object);
                    });

                }
            }, this);

            function onClickPos(object, element, newValue, data, self) {
                self.updateByRID(data, object, newValue, element);

                if (document.getElementById("modal" + object.AttributeName.split("].")[1] != null)) {
                    hideModal(document.getElementById("modal" + object.AttributeName.split("].")[1]));
                }
                hideModal(document.getElementById("modalResponse"));
            }

            function onClickNeg(object) {
                hideModal(document.getElementById("modalResponse"));
                self.injectValues(element, object);
            }
            
            showStaticModal(modalResponse);
        }
    }

    static showInformationModal(message) {
        var modalInformation = document.getElementById("modalInformation"), modalInformationHeader = document.getElementById("modalInformationHeader"),
            modalInformationParag = document.getElementById("modalInformationParag"), modalInformationPos = document.getElementById("modalInformationPos");

        var responses = message.split(";");

        modalInformationHeader.innerText = responses[1];
        modalInformationParag.innerText = responses[2];
        modalInformationPos.innerText = responses[3];

        showModal(modalInformation);
    }

    static choiceOnMethod(element, object) {

        showModal(document.getElementById(`selectMethodModal`));
        $("#btn-assign-vacant").unbind();
        $("#btn-assign-vacant").on("click", function () {
            getEmployeePositionAttributesAssignNewPosition(object.GroupData.ElementID, "Assign New Position")
        })

        $("#btn-move-position").unbind();
        $("#btn-move-position").on("click", function () {
            getEmployeePositionAttributesMoveNewPosition(object.GroupData.ElementID, "Move Position - Position Title")
        })
    }

    static choiceOnMethodHierarchicalManager(element, object) {

        showModal(document.getElementById(`selectMethodManager`));
        $("#btn-assign-vacant-hierarchy").unbind();
        $("#btn-assign-vacant-hierarchy").on("click", function () {
            checkEmployeePositionObjectives(object.GroupData.ElementID, "Assign New Hierarchical Manager");
        })

        $("#btn-move-position-hierarchy").unbind();
        $("#btn-move-position-hierarchy").on("click", function () {
            checkEmployeePositionObjectives(object.GroupData.ElementID, "Move Position - Hierarchical Manager")
        })
    }

    static injectManagerOnClick(element, object, inpBtn) {
        let self = this,
            elementID = element.id,
            elementIDSplit = splitAttributeName(elementID);

        if (object.IsPicker) {
            if (object.AttributeName == "[22].PositionID") {
                if(document.getElementById("inpBtn_group_[22].PositionID_"+object.GroupData.ElementID))
                {
                    document.getElementById("inpBtn_group_[22].PositionID_" + object.GroupData.ElementID).addEventListener("click", function () {
                        self.choiceOnMethod(element, object);
                    });
                }
                else
                {
                    document.getElementById("[22].PositionID").addEventListener("click", function () {
                        self.choiceOnMethod(element, object);
                    });
                }
                
            }
            else if (object.AttributeName == "[22].ParentEmployeePosition") {
                if(document.getElementById("inpBtn_group_[22].ParentEmployeePosition_" + object.GroupData.ElementID))
                {
                    document.getElementById("inpBtn_group_[22].ParentEmployeePosition_" + object.GroupData.ElementID).addEventListener("click", function () {
                        self.choiceOnMethodHierarchicalManager(element, object)
                    })
                }
                else
                {
                    document.getElementById("[22].ParentEmployeePosition").addEventListener("click", function () {
                        self.choiceOnMethodHierarchicalManager(element, object)
                    })
                }
            }
            else {
                createModal(element, object);
                initEditableSelect(element, object);
                hiddenListener(element, object);
                selectedListener(element, object);
                this.initKeyPress(element, object, employeeId);
                suggestions[object.AttributeName] = [];
            }
        } else if (object.IsHierarchyList) {
            createTreeModal(element, object);
        }

        if (object.RID == null || object.RID == undefined) {
            this.saveChangesBtnModal(element, object);
        }

        if ((object.RID != null && object.RW == 2) || object.RID == null) {
            inpBtn.setAttribute("data-target", "#modal" + elementIDSplit + "");
            inpBtn.setAttribute("data-toggle", "modal");
        } else {
            inpBtn.setAttribute("disabled", true);
            element.className = "form-control";
        }

        function createModal(element, object) {
            let elementID = element.id,
                elementIDSplit = splitAttributeName(elementID);
            var cont = document.getElementById("modalContainer"),
                funcModalHtml =
                    `<div class="modal fade" id="modal` + elementIDSplit + `" style="z-index: 99999 !important">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 class="modal-title">`+ object.DefaultLabel + `</h4>
                        </div>
                        <div class="modal-body" id="appendTo-place`+ elementIDSplit + `" data-append-to=".modal-body">
                            <div class="row">
                                <div class="col-xs-12">
                                    <select placeholder="Manager Name/Position Name" id="editableSelect`+ elementIDSplit + `" class="form-control" >
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-3"><p><strong>Global Employee ID</strong></p></div>
                                <div class="col-xs-1"><strong>:</strong></div>
                                <div class="col-xs-7" id="gei`+ elementIDSplit + `"></div>
                            </div>
                            <div class="row">
                                <div class="col-xs-3"><p><strong>Employee ID</strong></p></div>
                                <div class="col-xs-1"><strong>:</strong></div>
                                <div class="col-xs-7" id="ei`+ elementIDSplit + `"></div>
                            </div>
                            <div class="row">
                                <div class="col-xs-3"><p><strong>Name</strong></p></div>
                                <div class="col-xs-1"><strong>:</strong></div>
                                <div class="col-xs-7" id="name`+ elementIDSplit + `"></div>
                            </div>
                            <div class="row">
                                <div class="col-xs-3"><p><strong>Position</strong></p></div>
                                <div class="col-xs-1"><strong>:</strong></div>
                                <div class="col-xs-7" id="position`+ elementIDSplit + `"></div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button id="btnSave`+ elementIDSplit + `" type="button" class="btn btn-primary">
                                Save changes</button>
                        </div>
                    </div>
                </div>
                    </div>`,
                hieModalHtml =
                    `<div class="modal fade" id="modal` + elementIDSplit + `" style="z-index: 99999 !important">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 class="modal-title">`+ object.DefaultLabel + `</h4>
                        </div>
                        <div class="modal-body" id="appendTo-place`+ elementIDSplit + `" data-append-to=".modal-body">
                            <div class="row">
                                <div class="col-xs-12">
                                    <select placeholder="Manager Name/Position Name" id="editableSelect`+ elementIDSplit + `" class="form-control" >
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-3"><p><strong>Name</strong></p></div>
                                <div class="col-xs-1"><strong>:</strong></div>
                                <div class="col-xs-7" id="name`+ elementIDSplit + `"></div>
                            </div>
                            <div class="row">
                                <div class="col-xs-3"><p><strong>Position</strong></p></div>
                                <div class="col-xs-1"><strong>:</strong></div>
                                <div class="col-xs-7" id="position`+ elementIDSplit + `"></div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button id="btnSave`+ elementIDSplit + `" type="button" class="btn btn-primary">
                                Save changes</button>
                        </div>
                    </div>
                </div>
                    </div>`,
                vacantPositionModalHtml =
                    `<div class="modal fade" id="modal` + elementIDSplit + `" style="z-index: 99999 !important">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h4 class="modal-title">`+ object.DefaultLabel + `</h4>
                            </div>
                            <div class="modal-body" id="appendTo-place`+ elementIDSplit + `" data-append-to=".modal-body">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <label>Search a Hierarchical Manager with a Vacant Position</label>
                                        <select placeholder="Manager Name/Position Name" id="editableSelect`+ elementIDSplit + `" class="form-control" >
                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-3"><p><strong>Name</strong></p></div>
                                    <div class="col-xs-1"><strong>:</strong></div>
                                    <div class="col-xs-7" id="name`+ elementIDSplit + `"></div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-3"><p><strong>Position</strong></p></div>
                                    <div class="col-xs-1"><strong>:</strong></div>
                                    <div class="col-xs-7" id="position`+ elementIDSplit + `"></div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-3"><p><strong>Vacant Position</strong></p></div>
                                    <div class="col-xs-1"><strong>:</strong></div>
                                    <div class="col-xs-7" id="vacantPosition`+ elementIDSplit + `"></div>
                                </div>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                <button id="btnSave`+ elementIDSplit + `" type="button" class="btn btn-primary">
                                    Save changes</button>
                            </div>
                        </div>
                    </div>
                        </div>`,
                generalPickerModal =
                    `<div class="modal fade" id="modal` + elementIDSplit + `" style="z-index: 99999 !important">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h4 class="modal-title">`+ object.DefaultLabel + `</h4>
                                </div>
                                <div class="modal-body" id="appendTo-place`+ elementIDSplit + `" data-append-to=".modal-body">
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <label>Search an Employee</label>
                                            <select placeholder="Please enter the name of the employee" id="editableSelect`+ elementIDSplit + `" class="form-control" >
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-3"><p><strong>Name</strong></p></div>
                                        <div class="col-xs-1"><strong>:</strong></div>
                                        <div class="col-xs-7" id="name`+ elementIDSplit + `"></div>
                                    </div>
                                </div>
    
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                    <button id="btnSave`+ elementIDSplit + `" type="button" class="btn btn-primary">
                                        Save changes</button>
                                </div>
                            </div>
                        </div>
                            </div>`;
            if (document.getElementById("modal") + elementIDSplit != null) {
                $("#modal" + elementIDSplit).remove();
            }
            if (object.RID != null) {
                if (object.RuleValue == "UpdateFunctionalManager") {
                    cont.insertAdjacentHTML('beforeend', funcModalHtml);
                }
                else if (object.CustomCall != undefined) {
                    cont.insertAdjacentHTML('beforeend', vacantPositionModalHtml);
                }
                else {
                    cont.insertAdjacentHTML('beforeend', hieModalHtml);
                }
            }
            else {
                if (object.AttributeName == "[87].FMEmployeeID") {
                    cont.insertAdjacentHTML('beforeend', funcModalHtml);
                }
                else if (object.CustomCall != undefined) {
                    if (object.AttributeName == "[22].EmployeeID") {
                        cont.insertAdjacentHTML('beforeend', generalPickerModal);
                    }
                    else {
                        cont.insertAdjacentHTML('beforeend', vacantPositionModalHtml);
                    }
                }
                else {
                    cont.insertAdjacentHTML('beforeend', hieModalHtml);
                }
            }
        }

        function createTreeModal(element, object) {
            let elementID = element.id,
                elementIDSplit = splitAttributeName(elementID);
            var cont = document.getElementById("modalContainer"),
                modalHtml =
                    `<div class="modal fade" id="modal` + elementIDSplit + `">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 class="modal-title">`+ object.DefaultLabel + `</h4>
                        </div>
                        <div class="modal-body" style="height: 500px; overflow-Y: scroll" id="appendTo-place`+ elementIDSplit + `" data-append-to=".modal-body">
                            <div class="row">
                                <div class="col-lg-offset-5 col-lg-7">
                                    <div class="input-group">
                                        <input type="text" class="form-control pull-right" id="treeSearch`+ elementIDSplit + `" placeholder="Search for..." />
                                        <span class="input-group-btn">
                                            <button class="btn btn-danger" id="btnTreeClear`+ elementIDSplit + `" type="button">Clear</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div id="tree`+ elementIDSplit + `" > </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button id="btnSave`+ elementIDSplit + `" type="button" class="btn btn-primary">
                                Save changes</button>
                        </div>
                    </div>
                </div>
                    </div>`;

            if (document.getElementById("modal") + elementIDSplit != null) {
                $("#modal" + elementIDSplit).remove();
            }
            cont.insertAdjacentHTML('beforeend', modalHtml);

            getTreeData(object);
        }

        function saveChangesManager(event) {
            var object = event.target.paramObject, element = event.target.paramElement,
                paramThis = event.target.paramThis; // Get the parameters of the function

            paramThis.saveChangesManager(element, object);

         
        }

        function getTreeData(object) {
            var api = object.ListValue,
                call = api,
                thePendingObjectForQuery = self.getQueuedObject(call);

            if (thePendingObjectForQuery == undefined) {
                self.createQueuedObject(call, object, null, null);

                axios.get(API_URL + "/" + api + "/", {
                    headers: {
                        Token: Auth.getToken()
                    }
                }).then(function (response) {
                    initTree(element, object, response.data[0].Hierarchy);
                    bindTree(element, object);

                    self.updateQueuedObject(call, "Data", response.data[0].Hierarchy);
                    self.processQueuedObjects(element, call);
                })
            } else {
                self.updateQueuedObject(call, "Objects", {
                    "Element": element,
                    "IsDone": false,
                    "Object": object,
                    "Type": null
                });
                self.processQueuedObjects(element, call);
            }
        }

        function saveChangesTree() {
            var object = event.target.paramObject, element = event.target.paramElement,
                paramThis = event.target.paramThis; // Get the parameters of the function

            paramThis.saveChangesTree(element, object);
        }
    }

    static saveChangesBtnModal(element, object) {
        let elementID = element.id,
            elementIDSplit = splitAttributeName(elementID);

        var btnSave = document.getElementById("btnSave" + elementIDSplit); // initialize save modal button
        var inpBtn = document.getElementById("inpBtn" + element.id);
        var self = this;

        if (inpBtn == null) {
            this.createButtonExtension(element, object);
        } else {
            if (object.IsPicker) {
                if (btnSave != null) {
                    var self = this;
                    $('#' + btnSave.id).unbind();
                    $('#' + btnSave.id).on('click', function () {

                        saveChangesManager(element, object, self);
                    });
                } else {
                    this.injectManagerOnClick(element, object, inpBtn);
                }
            } else if (object.IsHierarchyList) {
                if (btnSave != null) {
                    $('#' + btnSave.id).unbind();
                    $('#' + btnSave.id).on('click', function () {
                        saveChangesTree(element, object, self);
                    });
                } else {
                    this.injectManagerOnClick(element, object, inpBtn);
                }
            }
        }

        if (btnSave != null) {
            btnSave.paramObject = object;
            btnSave.paramElement = element;
            btnSave.paramThis = this;
        }

        function saveChangesTree(element, object, self) {
            self.saveChangesTree(element, object);
        }

        function saveChangesManager(element, object, self) { // WE HAVE TO CHECK IF THE USER IS A FUNCTIONAL MANAGER
            self.saveChangesManager(element, object);
        }
    }

    static saveChangesManager(element, object) {
        let elementID = element.id,
            elementIDSplit = splitAttributeName(elementID);

        var AttributeName = object.AttributeName.split("].")[1],
            selectedManager = getSelectedManager(object);

        if (object.ResponseText != null) { // edit employee mode
            if (object.RID) {
                let isMandatory = object.IsMandatory,
                    shouldProceed = false;

                if (isMandatory) {
                    shouldProceed = selectedManager ? true : false;
                } else {
                    shouldProceed = true;
                }

                if (shouldProceed) {
                    let UserLogin = Auth.getEmployeeId();
                    let data = new FormData();
                    let newValue = selectedManager != null ? selectedManager.ID : '';
                    let employeeName = selectedManager != null ? selectedManager.Employee : '';
                    let parentEmployeePosition = selectedManager != null ? selectedManager.EmployeePositionID : '';

                    data.append("Value", newValue);
                    data.append("OldValue", (object.Value == null) ? '' : object.Value);
                    data.append("RID", object.RID + "." + object.AttributeName.split("].")[1]);
                    data.append("userID", UserLogin);

                    if ((object.IsSelectList) || !object.IsSelectList) {
                        hideModal(document.getElementById("modal" + elementIDSplit));
                        this.showResponseModal(element, object, data, newValue, employeeName, parentEmployeePosition);
                    }
                } else {
                    if (isMandatory) {
                        Swal2.fire({
                            title: "Error",
                            text: 'You have not selected a manager',
                            icon: 'error',
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonColor: '#d33',
                            cancelButtonText: "Ok"
                        })

                    }
                }
            } else {
                if (selectedManager) {
                    const groupData = object.GroupData;

                    if (groupData) {
                        let value = selectedManager != undefined ? selectedManager.ID : null;

                        if (value) {
                            let data = {
                                Value: value,
                                ReferenceID: groupData.ElementID,
                                DBObjectAttributeID: object.DBObjectAttributeID,
                                EmployeeID: employeeId,
                                object
                            };

                            this.genericAddByDBObjectAttributeID({ data });
                        }
                    }
                } else {
                    Swal2.fire({
                        title: "Information",
                        text: "You have not selected a manager",
                        icon: 'info',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#d33',
                        cancelButtonText: "Ok"
                    })
                }
            }
        } else { // add employee mode
            if (selectedManager) {
                if (object.CustomCall != undefined) {
                    if (object.AttributeName == "[22].EmployeeID") {
                        element.setAttribute("employeeID", selectedManager.EmployeeID);
                        element.value = selectedManager["EmployeeName"]
                        hideModal(document.getElementById("modal" + elementIDSplit));
                    }
                    else {
                        element.setAttribute("employeePositionID", selectedManager.EmployeePositionID);
                        element.setAttribute("positionID", selectedManager.PositionID);
                        element.setAttribute("parentEmployeePositionID", selectedManager.ParentEmployeePositionID);
                        element.setAttribute("vacantPosition", selectedManager.VacantPosition);
                        element.value = selectedManager.Employee;
                        //START : THIS IS ONLY FOR DISPLAY PURPOSES FOR THE REACTIVATION MODAL IN employeeManagementList.js
                        //  under the showModal() function 
                        // can be deleted without the functionalities breaking
                        // let vacantPositionInp = document.getElementById("vacantPositionInputParentEmployeePositionID")
                        // vacantPositionInp.value = selectedManager.VacantPosition;
                        object.RelatedAttributes.map(relAttribute => {
                            this.setRelatedValues(relAttribute, selectedManager)
                        })
                        //END:
                        hideModal(document.getElementById("modal" + elementIDSplit));
                    }
                }
                else {
                    element.setAttribute("employeeID", selectedManager.ID);
                    element.setAttribute("parentEmployeePositionID", selectedManager.ParentEmployeePositionID);
                    element.setAttribute("employeePositionID", selectedManager.EmployeePositionID);
                    element.value = selectedManager.Employee;

                    hideModal(document.getElementById("modal" + elementIDSplit));
                }
            } else {
                Swal2.fire({
                    title: "Information",
                    text: "You have not selected a manager",
                    icon: 'info',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Ok"
                })

            }
        }
    }

    static setRelatedValues(attribute, selectedManager) {
        let relatedInput = document.getElementById(attribute);
        let attributeName = splitAttributeName(attribute);
        if (relatedInput == null) {
            let relatedInputNew = document.getElementById("new_[22].PositionID")
            if (relatedInputNew == null) {

            }
            else {
                relatedInputNew.value = selectedManager[`${attributeName}`];
                relatedInputNew.disabled = true;
            }

        }
        else {
            relatedInput.value = selectedManager[`${attributeName}`];
            relatedInput.disabled = true;
        }
    }

    static saveChangesTree(element, object) {
        let elementID = element.id,
            elementIDSplit = splitAttributeName(elementID);

        var AttributeName = elementIDSplit;
        var selectedTreeObj = treeSelectedNode[AttributeName];
        if (selectedTreeObj != null) {
            if (object.RID != null) {

                let newValue = selectedTreeObj.id;
                let typeOfUpdate = element.id.includes("Override") ? "Override" : "Generic";

                let UserLogin = this.getEmployeeId();
                let data = new FormData();

                data.append("Value", newValue);
                data.append("OldValue", (object.Value == null) ? '' : object.Value);
                data.append("RID", object.RID + "." + object.AttributeName.split("].")[1]);
                data.append("userID", UserLogin);


                if (typeOfUpdate === "Override") {
                    let employeePositionID = object.GroupData.ElementID;
                    let disciplineID = object.Value;
                    this.overrideUpdateByRID(963, UserLogin, employeePositionID, newValue, element, object);
                }
                else {
                    this.updateByRID(data, object, newValue, element);
                }
            } else {
                element.value = selectedTreeObj.value;
            }
        } else {
            if (object.RID == null) {
                element.value = "";
            }
        }
        hideModal(document.getElementById("modal" + AttributeName));
    }

    static initKeyPress(element, object, employeeID) {
        let elementID = element.id,
            elementIDSplit = splitAttributeName(elementID);

        $('#editableSelect' + elementIDSplit).on("input", function (event) {
            clearManagerSearch(element, object);
        
            var value = event.target.value.trim();
            var prevInp = (previousInput[elementIDSplit] != null ? previousInput[elementIDSplit] : "");
            var sugg = (suggestions[elementIDSplit] != null ? suggestions[elementIDSplit] : []);
            if (value.length >= 2) {
                if (value.length >= 2 && (prevInp.length > value.length || sugg.length == 0)) {
                    source.cancel("Cancel request because another request will be made.");
                    setTimeout(function makeRequest() {
                        clearOptions(elementIDSplit, object);
                        var newCancelToken = axios.CancelToken;
                        var newSource = newCancelToken.source();
                        suggestions[elementIDSplit] = [];
                        if (object.CustomCall) {
                            axios.get((API_URL) + '/single_api/', {
                                params: {
                                    requestjson: {
                                        Module: object.CustomCall.Module,
                                        Parameters: {
                                            SearchValue: value,
                                            IsExit: false,
                                        }
                                    }
                                }, headers: {
                                    Token: Auth.getToken()
                                },
                                cancelToken: newSource.token
                            }).then(function (response) {
                                switch (response.status) {
                                    case 200:
                                        if (object.AttributeName == "[22].EmployeeID") {
                                            clearOptions(elementIDSplit, object);
                                            changeOptions(elementIDSplit, object, response.data.Data.EmployeeList, employeeId);
                                            suggestions[elementIDSplit] = response.data.Data.EmployeeName;
                                        }
                                        else {
                                            clearOptions(elementIDSplit, object);
                                            changeOptions(elementIDSplit, object, response.data.Data.EmployeeName, employeeId);
                                            suggestions[elementIDSplit] = response.data.Data.EmployeeName;
                                        }
                                        break;
                                }
                            }).catch(function (thrown) {
                                console.log(thrown);
                            });
                        }
                        else {

                            axios.get((API_URL) + '/employee_search/', {
                                params: {
                                    SearchTerm: value,
                                    EmployeeID: employeeID,
                                    IsExit: false
                                }, headers: {
                                    Token: Auth.getToken()
                                },
                                cancelToken: newSource.token
                            }).then(function (response) {
                                switch (response.status) {
                                    case 200:
                                        clearOptions(elementIDSplit, object);
                                        changeOptions(elementIDSplit, object, response.data.EmployeeName, employeeId);
                                        suggestions[elementIDSplit] = response.data.EmployeeName;
                                        break;
                                }
                            }).catch(function (thrown) {
                                console.log(thrown);
                            });
                        }
                        source = newSource;
                    }(), 50);
                } 
            } else {
                clearOptions(elementIDSplit, object);
                suggestions[elementIDSplit] = [];
            }

            previousInput[elementIDSplit] = value;

        });
    }

    static onClickTab(thatClass) {
        $(".nav-tabs a").click(function () {
            var tabId = $(this).attr('href');

            var tabs = thatClass.state.tabs;
            if (!tabs[tabId].isActivated) {
                tabs[tabId].isActivated = true;
                thatClass.setState({
                    tabs: tabs
                });
            }
        });
    }

    static appendDeleteConfirmationModal(id) {
        var container = document.getElementById(id);
        container.insertAdjacentHTML("beforeend", `<div class="modal fade" id="modalConfirmation">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 class="modal-title" id="999137">Confirmation</h4>
                        </div>
                        <div class="modal-body">
                            <p id="999138">Are you sure you want to delete this record?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal" id="999139">No</button>
                            <button id="modalConfirmationPos" type="button" class="btn btn-danger" id="999140">Yes</button>
                        </div>
                    </div>
                </div>
        </div>`);
    }

    static showDeleteConfirmationGeneral(RID, thatClass, savedReport = false) {
        showModal(document.getElementById("modalConfirmation"));
        let self = this;
        $("#modalConfirmationPos").unbind();
        $("#modalConfirmationPos").on('click', () => {
            self.deleteByRID(RID, thatClass, savedReport);
            hideModal(document.getElementById("modalConfirmation"));
        });
    }
    //DELETE BY RID
    static deleteByRID(RID, thatClass, savedReport) {
        let formData = new FormData();
        formData.append("RID", RID);
        waitingDialog.show("Deleting record...", { dialogSize: 'sm', progressType: 'danger' })
        
        fetch(API_URL + "/generic_delete/", {
            method: 'DELETE',
            headers: {
                'token': Auth.getToken()
            },
            body: formData
        }).then(response => response.json()
        ).then(success => {
            waitingDialog.hide();
            if (success.Success != undefined) {
                if (savedReport) {
                    thatClass.fetchSavedReport();
                }
                else {
                    thatClass.refreshTable();
                }
            } else {
                Swal2.fire({
                    title: "Information",
                    text: success.Error.Message,
                    icon: 'info',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Ok"
                })
            }
        }).catch(error => {
            waitingDialog.hide();
            console.log('error', error);
        });
    }

    static showDeleteConfirmationPerformance(RID, thatClass) {
        showModal(document.getElementById("modalConfirmation"));
        let self = this;
        $("#modalConfirmationPos").unbind();
        $("#modalConfirmationPos").on('click', () => {
            self.deletePerformanceManagementByRID(RID, thatClass);
            hideModal(document.getElementById("modalConfirmation"));
        });
    }

    static deletePerformanceManagementByRID(RID, thatClass) {
        let formData = new FormData();
        formData.append("RID", RID);
        waitingDialog.show("Deleting record...", { dialogSize: 'sm', progressType: 'danger' });

        fetch(API_URL + "/generic_delete/", {
            method: 'DELETE',
            headers: {
                'token': Auth.getToken()
            },
            body: formData
        }).then(response => response.json()
        ).then(success => {
            waitingDialog.hide();
            if (success.Success != undefined) {
                thatClass.refreshTable();
            } else {
                Swal2.fire({
                    title: "Error",
                    text: success.Error.Message,
                    icon: 'error',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Ok"
                })
            }
        }).catch(error => {
            waitingDialog.hide();
            console.log('error', error);
        });
    }

    /*
        FOR MONEY AND NUMBER STUFF
    */

    static initMoneyFormatMasking(element, object) {
        let self = this,
            separators = self.getSeparators(object),
            theDecimalSeparator = separators.DecimalSeparator,
            theDigitSeparator = separators.DigitSeparator;

        $(element).unbind();
        $(element).on("keyup", function (e) {
            let value = e.target.value,
                decimalSeparatorIndex = value.indexOf(theDecimalSeparator),
                precision = object.Precision == undefined ? defaultDecimalFixedValue : object.Precision;

            if (decimalSeparatorIndex != -1) {
                let decimalValue = value.split(theDecimalSeparator)[1];
                if (decimalValue.length > precision) {
                    value = value.substring(0, value.length - 1);
                    $(this).val(value);
                }
            }
        })
        $(element).on("keypress", function (e) {
            let value = e.target.value,
                charCode = e.originalEvent.which,
                digitCharCode = theDigitSeparator.charCodeAt(0),
                decimalCharCode = theDecimalSeparator.charCodeAt(0),
                defaultDecimalCharCode = defaultDecimalSeparator.charCodeAt(0),
                objectLength = object.Length;

            if (objectLength == undefined || objectLength == null || (value.length < objectLength)) {
                if ((charCode < 48 || charCode > 57) && charCode != 8) { // CHECKS IF INPUT IS NOT A NUMBER
                    if (!e.originalEvent.ctrlKey) {
                        if (charCode != decimalCharCode && charCode != digitCharCode) {
                            return false;
                        } else {
                            let selectionStart = e.target.selectionStart;
                            if (charCode == decimalCharCode) { // CHECKS IF THE INPUT IS A DECIMAL
                                if (value.indexOf(theDecimalSeparator) != -1) {
                                    return false;
                                }
                            }
                        }
                    }
                }
            } else {
                return false;
            }
        })

        $(element).on("paste", function (e) {
            let originalText = e.originalEvent.clipboardData.getData('text'),
                value = e.target.value,
                shouldPaste = true;

            if (isNaN(Number(originalText))) {
                shouldPaste = false;
            } else {
                shouldPaste = true;
            }

            if (isNaN(Number(self.returnFromFormatMoneyMasking(originalText, object.Precision, separators)))) {
                shouldPaste = false;
            } else {
                shouldPaste = true;
            }

            if (!shouldPaste) {
                e.preventDefault();
            }
        })

        $(element).on("drop", function (e) {
            let originalText = e.originalEvent.dataTransfer.getData("Text"),
                value = e.target.value,
                shouldPaste = true;

            if (isNaN(Number(originalText))) {
                shouldPaste = false;
            } else {
                shouldPaste = true;
            }

            if (isNaN(Number(self.returnFromFormatMoneyMasking(originalText, object.Precision, separators)))) {
                shouldPaste = false;
            } else {
                shouldPaste = true;
            }

            if (!shouldPaste) {
                e.preventDefault();
            }
        })
    }

    static onChangeMoneyFormat(element, object) {
        let self = this,
            separators = self.getSeparators(object),
            theDecimalSeparator = separators.DecimalSeparator,
            theDigitSeparator = separators.DigitSeparator;

        $(element).unbind("change");
        $(element).on("change", function (e) {
            let newValue = self.returnFromFormatMoneyMasking(e.target.value, object.Precision, separators),
                formData = new FormData(),
                shouldUpdate = true,
                errorMessage = "",
                inputLength = object.Length == undefined || object.Length == null ? -1 : object.Length;

            if (newValue.length > 0) { // REMOVES THE LEADING 0's
                let shouldContinue = true;
                while (shouldContinue) {
                    let firstChar = newValue.charAt(0);
                    if (firstChar == "0") {
                        newValue = newValue.substring(1);
                    } else {
                        shouldContinue = false;
                    }
                }
                $(this).val(self.returnToFormatMoneyMasking(newValue, object.Precision, separators));
            }

            if (newValue.charAt(newValue.length - 1) == defaultDecimalSeparator) { // REMOVE THE LEADING DEFAULT DECIMAL SEPARATOR
                newValue = newValue.substring(0, newValue.length - 1);
            }
            if (newValue.split(defaultDecimalSeparator).length > 2) {
                shouldUpdate = false;
                errorMessage = "Invalid input";
            } else if (isNaN(Number(newValue))) {
                shouldUpdate = false;
                errorMessage = "Invalid input";
            } else if (inputLength != -1 && newValue.length > inputLength) {
                shouldUpdate = false;
                errorMessage = "Input length should not be greater than " + inputLength;
            }

            if (shouldUpdate) {
                if (newValue != object.Value) {
                    formData.append("Value", newValue);
                    formData.append("RID", object.RID + "." + object.AttributeName.split("].")[1]);
                    formData.append("OldValue", (object.Value == null) ? '' : object.Value);
                    if (object.RW != 2) {
                        self.updateByRID(formData, object, newValue, element);
                    }
                }
            } else {
                self.changeClassForValidation(element, 3, object);
                Swal2.fire({
                    title: "Information",
                    text: errorMessage,
                    icon: 'info',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Ok"
                })

                $(this).val(self.returnToFormatMoneyMasking(object.Value, object.Precision, self.getSeparators(object)));
            }
        });
    }

    static injectValueMoneyFormatMasking(element, object) {
        let newFormatedValue = this.returnToFormatMoneyMasking(object.Value, object.Precision, this.getSeparators(object));
        element.value = newFormatedValue;
    }

    static returnToFormatMoneyMasking(value, precision, separators) {
        let newValue = Number(value),
            theDecimalSeparator = separators != undefined ? separators.DecimalSeparator : defaultStandardDecimalSeparator,
            theDigitSeparator = separators != undefined ? separators.DigitSeparator : defaultStandardGroupingSeparator;

        if (isNaN(newValue) || value == null || value == undefined) {
            return "";
        } else {
            let nArrayStr = newValue.toString().split(defaultStandardDecimalSeparator),
                digitStr = nArrayStr[0],
                decimalStr = nArrayStr[1],
                newDigitStr = "",
                newDecimalStr = "",
                newValueStr = "";

            if (digitStr.length > 3) { // MASKING FOR THE DIGIT GROUPING
                let digitCounter = 0;
                for (let x = digitStr.length - 1; x >= 0; x--) {
                    ++digitCounter;
                    if (digitCounter == 3) {
                        digitCounter = 0;
                        newDigitStr = digitStr[x] + newDigitStr;
                        if (x > 0) {
                            newDigitStr = theDigitSeparator + newDigitStr;
                        }
                    } else {
                        newDigitStr = digitStr[x] + newDigitStr;
                    }
                }
            } else {
                newDigitStr = digitStr;
            }
            if (decimalStr) {
                precision = precision == undefined ? decimalStr.length - 1 : precision;
            }

            if (decimalStr != undefined && decimalStr.length > 0) { // MASKING FOR THE DECIMAL
                decimalStr = defaultStandardDecimalSeparator + decimalStr;
                newDecimalStr = Number(decimalStr).toFixed(precision);
                newDecimalStr = theDecimalSeparator + newDecimalStr.split(defaultStandardDecimalSeparator)[1];
            } else {
                while (precision > 0) {
                    newDecimalStr = newDecimalStr + "0";
                    --precision;
                }
                newDecimalStr = theDecimalSeparator + newDecimalStr;
            }
            newValueStr = newDigitStr + newDecimalStr;

            return newValueStr;
        }
    }

    static returnFromFormatMoneyMasking(value, precision, separators) {
        let theDecimalSeparator = separators != undefined ? separators.DecimalSeparator : defaultStandardDecimalSeparator,
            theDigitSeparator = separators != undefined ? separators.DigitSeparator : defaultStandardGroupingSeparator,
            valueArray = value.split(theDecimalSeparator);
        let valueDigitArray = valueArray[0].split(theDigitSeparator),
            valueDecimal = valueArray[1],
            newDigitStr = "",
            newDecimalStr = "",
            newValueStr = "";

        if (valueDigitArray.length > 0) { // DIGIT PROCESSING FIELD
            for (let x = 0; x < valueDigitArray.length; x++) {
                let valueDigitArrayValue = valueDigitArray[x];
                if (valueDigitArrayValue.length == 0) {
                    if (x == 0) {
                        newDigitStr = newDigitStr + "0";
                    }
                } else {
                    newDigitStr = newDigitStr + valueDigitArrayValue;
                }
            }
        }

        if (valueDecimal != undefined && valueDecimal.length > 0) { // DECIMAL PROCESSING FIELD
            precision = precision == undefined ? valueDecimal.length - 1 : precision;
            valueDecimal = defaultStandardDecimalSeparator + valueDecimal;
            newDecimalStr = Number(valueDecimal).toFixed(precision);
            newDecimalStr = defaultStandardDecimalSeparator + newDecimalStr.split(".")[1];
        }
        newValueStr = newDigitStr + newDecimalStr;

        return newValueStr;
    }

    static setUserApplicationSettings(theUserApplicationSettings) {
        userApplicationSettings = theUserApplicationSettings;
    }

    static getUserApplicationSettingsByAttributeName(attributeName) {
        let obj = {};
        if (userApplicationSettings != undefined) {
            if (userApplicationSettings.length > 0) {
                for (let x = 0; x < userApplicationSettings.length; x++) {
                    if (userApplicationSettings[x].AttributeName == attributeName) {
                        obj = userApplicationSettings[x];
                    }
                }
            }
        }
        return obj;
    }

    static initSeparators() {
        /*
            GET THE SEPARATORS FROM THE SYSTEM SETTINGS AND USER APPLICATION SETTINGS
        */
        let digitGroupingSeparatorObject = this.getUserApplicationSettingsByAttributeName("[44].DigitGroupSeparator"),
            decimalSeparatorObject = this.getUserApplicationSettingsByAttributeName("[44].DecimalSeparator"),
            systemDefaultSettingsNumberFormatObject = undefined,
            systemDefaultSettingsNumberFormatVal = undefined;

        try {
            systemDefaultSettingsNumberFormatObject = Auth.getSettingsByID(18);
            systemDefaultSettingsNumberFormatVal = systemDefaultSettingsNumberFormatObject.value != undefined ? systemDefaultSettingsNumberFormatObject.value : defaultStandardMoneyFormat;
        } catch (ex) {
            systemDefaultSettingsNumberFormatObject = undefined;
            systemDefaultSettingsNumberFormatVal = undefined;
        }
        /*
            SYSTEM SETTINGS SEPARATOR PROCESSING AREA...
        */
        if (systemDefaultSettingsNumberFormatVal != undefined && systemDefaultSettingsNumberFormatVal != null) {
            let systemDefaultSettingsNumberFormatValArray = systemDefaultSettingsNumberFormatVal.split("#"),
                systemDefaultSettingsNumberFormatValArrayVals = [];
            for (let x in systemDefaultSettingsNumberFormatValArray) {
                if (systemDefaultSettingsNumberFormatValArray[x].length > 0) {
                    systemDefaultSettingsNumberFormatValArrayVals.push(systemDefaultSettingsNumberFormatValArray[x]);
                }
            }
            systemDefaultSettingsDecimalSeparator = systemDefaultSettingsNumberFormatValArrayVals[systemDefaultSettingsNumberFormatValArrayVals.length - 1];

            if (systemDefaultSettingsDecimalSeparator != null && !isNaN(systemDefaultSettingsDecimalSeparator.charCodeAt(0))) {
                systemDefaultSettingsDecimalSeparator = systemDefaultSettingsDecimalSeparator.charCodeAt(0) == 160 ? " " : systemDefaultSettingsDecimalSeparator; // IF WHITE-SPACE, CONVERT TO SPACE
            }

            systemDefaultSettingsDigitSeparator = systemDefaultSettingsNumberFormatValArrayVals[systemDefaultSettingsNumberFormatValArrayVals.length - 2];
            if (systemDefaultSettingsDigitSeparator != null && !isNaN(systemDefaultSettingsDigitSeparator.charCodeAt(0))) {
                systemDefaultSettingsDigitSeparator = systemDefaultSettingsDigitSeparator.charCodeAt(0) == 160 ? " " : systemDefaultSettingsDigitSeparator; // IF WHITE-SPACE, CONVERT TO SPACE
            }
        }

        /*
            USER APPLICATION SEPARATOR PROCESSING AREA...
        */
        try {
            userDefaultSettingsDigitSeparator = digitGroupingSeparatorObject.Value;
            userDefaultSettingsDecimalSeparator = decimalSeparatorObject.Value;

            if (!isNaN(userDefaultSettingsDigitSeparator.charCodeAt(0))) {
                userDefaultSettingsDigitSeparator = userDefaultSettingsDigitSeparator.charCodeAt(0) == 160 ? " " : userDefaultSettingsDigitSeparator; // IF WHITE-SPACE, CONVERT TO SPACE
            }
            if (!isNaN(userDefaultSettingsDecimalSeparator.charCodeAt(0))) {
                userDefaultSettingsDecimalSeparator = userDefaultSettingsDecimalSeparator.charCodeAt(0) == 160 ? " " : userDefaultSettingsDecimalSeparator; // IF WHITE-SPACE, CONVERT TO SPACE
            }
        } catch (ex) {
            console.log(ex);
        }



    }

    static getSeparators(object) {
        let objectFormatMask = undefined,
            objectDecimalSeparator = undefined,
            objectDigitSeparator = undefined;

        if (userDefaultSettingsDecimalSeparator != undefined && userDefaultSettingsDigitSeparator != undefined) {
            return { "DecimalSeparator": userDefaultSettingsDecimalSeparator, "DigitSeparator": userDefaultSettingsDigitSeparator };
        }

        if (object != undefined && object != null && object.FormatMask != null && object.FormatMask != undefined) {
            let systemDefaultSettingsNumberFormatValArray = object.FormatMask.split("#"),
                systemDefaultSettingsNumberFormatValArrayVals = [];
            for (let x in systemDefaultSettingsNumberFormatValArray) {
                if (systemDefaultSettingsNumberFormatValArray[x].length > 0) {
                    systemDefaultSettingsNumberFormatValArrayVals.push(systemDefaultSettingsNumberFormatValArray[x]);
                }
            }
            objectDecimalSeparator = systemDefaultSettingsNumberFormatValArrayVals[systemDefaultSettingsNumberFormatValArrayVals.length - 1];
            if (objectDecimalSeparator != null && !isNaN(objectDecimalSeparator.charCodeAt(0))) {
                objectDecimalSeparator = objectDecimalSeparator.charCodeAt(0) == 160 ? " " : objectDecimalSeparator; // IF WHITE-SPACE, CONVERT TO SPACE
            }

            objectDigitSeparator = systemDefaultSettingsNumberFormatValArrayVals[systemDefaultSettingsNumberFormatValArrayVals.length - 2];
            if (objectDigitSeparator != null && !isNaN(objectDigitSeparator.charCodeAt(0))) {
                objectDigitSeparator = objectDigitSeparator.charCodeAt(0) == 160 ? " " : objectDigitSeparator; // IF WHITE-SPACE, CONVERT TO SPACE
            }
            if (systemDefaultSettingsDecimalSeparator != undefined && systemDefaultSettingsDigitSeparator != undefined)
                return { "DecimalSeparator": objectDecimalSeparator, "DigitSeparator": objectDigitSeparator };
        }

        if (systemDefaultSettingsDecimalSeparator != undefined && systemDefaultSettingsDigitSeparator != undefined) {
            return { "DecimalSeparator": systemDefaultSettingsDecimalSeparator, "DigitSeparator": systemDefaultSettingsDigitSeparator };
        }
        return { "DecimalSeparator": defaultStandardDecimalSeparator, "DigitSeparator": defaultStandardGroupingSeparator };
    }

    /*
        FOR IP ADDRESS STUFF
    */

    static initIPFormatMasking(element, object) {
        let self = this,
            octetSeparator = ".",
            decimalCharCode = (".").charCodeAt(0),
            spaceCharCode = (" ").charCodeAt(0);
    }

    static onChangeIPFormat(element, object) {
        let self = this;

        $(element).unbind("change");
        $(element).on("change", function (e) {
            let newValue = e.target.value,
                formData = new FormData(),
                shouldUpdate = true,
                errorMessage = "Invalid IP address",
                inputLength = object.Length == undefined || object.Length == null ? -1 : object.Length,
                isMandatory = object.IsMandatory;

            shouldUpdate = self.IPValidator(newValue, isMandatory);

            if (shouldUpdate) {
                if (newValue != object.Value) {
                    formData.append("Value", newValue);
                    formData.append("RID", object.RID + "." + object.AttributeName.split("].")[1]);
                    formData.append("OldValue", (object.Value == null) ? '' : object.Value);
                    self.updateByRID(formData, object, newValue, element);
                }
            } else {
                self.changeClassForValidation(element, 3, object);
                Swal2.fire({
                    title: "Information",
                    text: errorMessage,
                    icon: 'info',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Ok"
                })

                $(this).val(object.Value);
            }
        });
    }

    static ValidateIPaddress(ipaddress) {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return (true)
        }
        return (false)
    }

    static IPValidator(value, isMandatory) {
        if (this.ValidateIPaddress(value)) {
            return true;
        } else {
            if (value.length == 0) {
                if (isMandatory) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    }

    static EncodeKey(value) {
        return window.btoa(value);
    }

    static DecodeKey(value) {
        return window.atob(value);
    }
    // GENERIC QUERY
    static genericQuery(config) {
        let newCancelToken = axios.CancelToken,
            newSource = newCancelToken.source();

        if (config.CancelToken) {
            config.CancelToken.cancel("Cancel request because another request will be made.");
        }

        axios({
            baseURL: config.BaseURL ? config.BaseURL : API_URL,
            cancelToken: newSource.token,
            data: config.Method != "GET" ? config.Data.requestjson : null,
            params: config.Method == "GET" ? config.Data : null,
            headers: {
                token: Auth.getToken()
            },
            method: config.Method,
            responseType: 'json',
            url: config.Url,
        })
            .then(function (response) {
                config.ResponseSuccessCallback(response, config);
            })
            .catch(function (thrown) {
                config.ResponseFailCallback(thrown, config);
            });

        if (config.CancelToken) {
            config.CancelToken = newSource;
        }

        return config;
    }

    static genericAddByDBObjectAttributeID(params) {
        const _this = this,
            paramsData = params.data,
            requestjson = {
                Module: "system_generic_add",
                Parameters: paramsData
            },
            object = params.object;
        let formData = new FormData(),
            config = {};

        formData.append('requestjson', JSON.stringify(requestjson));
        config = {
            Data: { requestjson: formData },
            Method: "POST",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        }

        waitingDialog.show("Processing...", { dialogSize: 'sm', progressType: 'danger' });
        _this.genericQuery(config);

        function responseSuccessCallback(responseJson) {
            
            const data = responseJson.data,
                status = data.Status;

            if (status.IsSuccess) {
                const _data = data.Data.Attributes[0],
                    newEmployeeAttributeFKName = data.Data.EmployeeAttributeFKName;

                if (_data) {
                    if(paramsData.Parameters.object.ResponseText == "Information!;Functional Manager on History Change, Are you sure you want to continue;Yes,No")
                    {
                        const element = document.getElementById("[87].FMEmployeeID");

                        if (_data.IsPicker) {
                            _this.modifyObjectDataArray({
                                newObject: _data
                            });
                            _this.setObjFkNameArray(newEmployeeAttributeFKName);
                            _this.injectValues(element, _data);
                            _this.injectFunction(element, _data);
                            _this.changeClassForValidation(element, 2, _data);
                        } else if (_data.IsSelectList) {

                        } else if (_data.isFileUpload || _data.IsFileUpload) {

                        } else if (_data.IsHierarchyList) {

                        } else if (_data.AttributeName.toLowerCase().includes("password")) {

                        } else if (_data.IsColourPicker) {

                        } else {
                            const name = _data.Name;
                            if (name === "String") {

                            } else if (name === "Date" || name === "Time" || name === "DateTime" || name === "date") {

                            } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {

                            } else if (name === "Bit") {

                            } else if (name === "Email") {

                            }
                        }
                    }
                    else
                    {
                        const element = _this.getDynamicElement({ object: _data });

                        if (_data.IsPicker) {
                            _this.modifyObjectDataArray({
                                newObject: _data
                            });
                            _this.setObjFkNameArray(newEmployeeAttributeFKName);
                            _this.injectValues(element, _data);
                            _this.injectFunction(element, _data);
                            _this.changeClassForValidation(element, 2, _data);
                        } else if (_data.IsSelectList) {

                        } else if (_data.isFileUpload || _data.IsFileUpload) {

                        } else if (_data.IsHierarchyList) {

                        } else if (_data.AttributeName.toLowerCase().includes("password")) {

                        } else if (_data.IsColourPicker) {

                        } else {
                            const name = _data.Name;

                            if (name === "String") {

                            } else if (name === "Date" || name === "Time" || name === "DateTime" || name === "date") {

                            } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {

                            } else if (name === "Bit") {

                            } else if (name === "Email") {

                            }
                        }
                    }
                    
                }
            } else {
                Swal2.fire({
                    title: "Information",
                    text: status.Message,
                    icon: 'info',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Ok"
                })
            }
            waitingDialog.hide();
        }

        function responseFailCallback(responseJson) {
            console.log("responseFailCallback", responseJson);
            waitingDialog.hide();
            alert(responseJson.message);
        }
    }

    static modifyObjectDataArray(params) {
        let newObject = params.newObject;

        objectData.forEach(obj => {

            if (obj) {
                Object.keys(obj).forEach(objKey => {
                    let objTargetProp = obj[objKey];

                    if (objTargetProp) {
                        if (objTargetProp.length) {
                            objTargetProp.forEach(attrib1 => {
                                let attribs = attrib1.Attributes;

                                if (attribs) {
                                    attribs.map(attrib2 => {
                                        if (attrib2.DBObjectAttributeID == newObject.DBObjectAttributeID) {
                                            let attrib2GroupData = attrib2.GroupData,
                                                newObjectGroupData = newObject.GroupData;

                                            if (attrib2GroupData && newObjectGroupData) {
                                                if (newObjectGroupData.GroupID == attrib2GroupData.GroupID && newObjectGroupData.ElementID == attrib2GroupData.ElementID) {
                                                    attrib2 = newObject;
                                                }
                                            }
                                        }
                                    });
                                }
                            });
                        } else {
                            let objTargetPropAttributes = objTargetProp.Attributes;

                            if (objTargetPropAttributes) {
                                objTargetPropAttributes.map(attribute => {
                                    if (attribute.DBObjectAttributeID == newObject.DBObjectAttributeID) {
                                        attribute = newObject;
                                    }
                                });
                            }
                        }
                    }
                });
            }
        })
    }


    static getDelimiter() {
        let doesPrecisionExist = Auth.getPrecision() !== null;
        let doesDecimalSeparatorExist = Auth.getDecimalSeparator() !== null;
        let doesDigitGroupSeparatorExist = Auth.getDigitGroupSeparator() !== null;
        if (
            doesPrecisionExist
            && doesDecimalSeparatorExist
            && doesDecimalSeparatorExist
        ) {
            return {
                defaultDigitGroupingSeparator: Auth.getDigitGroupSeparator(),
                defaultDecimalSeparator: Auth.getDecimalSeparator(),
                defaultPrecision: Auth.getPrecision()
            }
        }
        else {
            return {
                defaultDigitGroupingSeparator: ".",
                defaultDecimalSeparator: ",",
                defaultPrecision: 2
            }
        }
    }

    static formatWithDelimiter(str, type) {

        let replaceStr = "\\" + defaultDigitGroupingSeparator;
        let re = new RegExp(replaceStr, "g")
        let wholeNumber = this.getWholeNumber(str);
        let decimalNumber = this.getDecimalNumber(str);

        if (decimalNumber != undefined) {
            return wholeNumber.toString().replace(re, "").replace(/\B(?=(\d{3})+(?!\d))/g, defaultDigitGroupingSeparator) + defaultDecimalSeparator + this.limitDecimalByPrecision(decimalNumber, type)
        }
        else {
            return wholeNumber.toString().replace(re, "").replace(/\B(?=(\d{3})+(?!\d))/g, defaultDigitGroupingSeparator)
        }
    }

    static formatWithoutDigitGroupingDelimiter(str, isNegative) {
        let replaceStr = "\\" + defaultDigitGroupingSeparator;
        let re = new RegExp(replaceStr, "g")
        let wholeNumber = this.getWholeNumber(str);
        let decimalNumber = this.getDecimalNumber(str);
        let isNegativePrefix = isNegative && str[0] === "-" ? "-" : ""
        if (decimalNumber != undefined) {
            return isNegativePrefix + wholeNumber.toString().replace(re, "").replace(/\B(?=(\d{3})+(?!\d))/g, '') + defaultDecimalSeparator + this.limitDecimalByPrecision(decimalNumber)
        }
        else {
            return isNegativePrefix + wholeNumber.toString().replace(re, "").replace(/\B(?=(\d{3})+(?!\d))/g, '')
        }
    }

    static getWholeNumber(str) {
        return this.removeAllNonDigitsExceptGroupingSeparator(str.split(defaultDecimalSeparator)[0])
    }

    static getDecimalNumber(str) {
        if (str.split(defaultDecimalSeparator)[1] != undefined) {
            let newStr = this.removeNonDigits(str.split(defaultDecimalSeparator)[1])
            return (newStr)
        }
    }

    static checkPrecision(str) {
        let newStr = str.split(defaultDecimalSeparator)[1]
        if (newStr != undefined) {
            return newStr.slice(0, defaultPrecision + 1)
        }
    }

    static limitDecimalByPrecision(str, type) {
        if (type == "Currency")
        {
            return str.slice(0, 6);
        }
        else
        {
            return str.slice(0, defaultPrecision);
        }
    }

    static removeNonDigits(str) {
        return str.replace(/\D/g, '')
    }

    static removeAllNonDigitsExceptGroupingSeparator(str) {
        let re = new RegExp("(?!^\\" + defaultDigitGroupingSeparator + "\)\\D", "ig")
        return str.replace(re, "");
    }

    static checkForDuplicateComma(str) {
        if (str.length > 0) {
            let secondLastChar = str[str.length - 2];
            let lastChar = str[str.length - 1];
            if (secondLastChar === "," && lastChar === ",") {
                return str.substring(0, str.length - 1)
            }
            else {
                return str
            }
        }
    }

    static checkForLeadingZero(str) {
        let wholeNumber = str.split(defaultDecimalSeparator)[0];
        let decimals = str.split(defaultDecimalSeparator)[1];
        if (decimals != undefined) {
            if (wholeNumber == "" || wholeNumber == "0") {
                return wholeNumber + defaultDecimalSeparator + decimals
            }
            return wholeNumber.replace(/^0+/, '') + defaultDecimalSeparator + decimals
        }
        else {
            return wholeNumber.replace(/^0+/, '')
        }
    }

    static checkCurrencyFormat(value, type, isNegative) {
        let newValue;

        if (value == '0' || value == '00') {
            return 0
        }
        newValue = this.checkForLeadingZero(value)
        if (type === "Currency") {
            newValue = this.formatWithDelimiter(newValue, type)
        }
        else {
            newValue = this.formatWithoutDigitGroupingDelimiter(newValue, isNegative)
        }
        newValue = this.checkIfZero(newValue)

        return newValue
    }



    static checkIfZero(value) {
        if (value == '0' || value == "," || value == ".") {
            return '0'
        }
        return value
    }

    static allowOneZeroIfFirstValue(value) {
        if (value != '') {
            return value
        }
        else {
            return '0'
        }
    }

    static deleteZeroOnBackSpace(value) {
        return ''
    }

    static formatCurrency(value, type, isNegative = true) {
        let numString = value.toString()
        return this.checkCurrencyFormat(numString, type, isNegative)
    }

    static removeDigitGroupSeparator(str) {

        let re = new RegExp("\\" + defaultDigitGroupingSeparator, "g")
        return str.replace(re, ".");
    }

    static addDecimal(numberString) {
        if (numberString % 1 == 0) {
            return Number(numberString).toFixed(Auth.getPrecision())
        }
        else {
            return numberString
        }
    }
    static checkifNumericOrNotNumeric(n) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0)
    }

    static addDecimalPDF(numberString) {

        if (numberString == null || numberString === "") {
            return numberString;
        }
        else if (this.checkifNumericOrNotNumeric(numberString) == false) {
            return numberString;
        }
        else if (numberString % 1 == 0) {
            return Number(numberString).toFixed(Auth.getPrecision()).replace(".", ",");
        }
        else {
            if (numberString % 1 != 0) {
                return Number(numberString).toFixed(Auth.getPrecision()).replace(".", ",");
            }
            else {
                return numberString;
            }
        }
    }

    static replaceDecimalSeparator(str) {
        let re = new RegExp("\\" + defaultDecimalSeparator, "g")
        return str.replace(re, ".");
    }

    static formatMoneyToNumber(str) {
        str = this.removeDigitGroupSeparator(str);
        str = this.replaceDecimalSeparator(str);

        if (str == "." || str == ",") {
            return 0
        }
        else {
            return str
        }
    }

    static replaceDecimalWithDefaultDecimalSeparator(str) {
        return str.replace('.', defaultDecimalSeparator)
    }

    static formatNumberToMoney(num, type) {
        let newNum = num == null ? num : num.toString();
        try {
            if (isNaN(Number(newNum))) {
                return newNum
            }
            else if (num == null) {
                return newNum = ""
            }
            else {
                return (
                    Number(newNum)
                        .toFixed(defaultPrecision) // always two decimal digits
                        .replace('.', defaultDecimalSeparator) // replace decimal point character with ,
                    
                ) // use . as a separator
            }
        }
        catch (e) {
            console.log('the num', num)
        }
    }

    static formatNumberToMoneyForCurrencyFX(num, type) {
        let newNum = num == null ? num : num.toString();
        try {
            if (isNaN(Number(newNum))) {
                return newNum
            }
            else if (num == null) {
                return newNum = ""
            }
            else {
                return (
                    Number(newNum)
                        .toString()
                        .replace('.', defaultDecimalSeparator) // replace decimal point character with ,
                    // .replace(/(\d)(?=(\d{3})+(?!\d))/g, `${type === "Currency" ? '$1.' : '$1'}`)
                ) // use . as a separator
            }
        }
        catch (e) {
            console.log('the num', num)
        }
    }

    static formatNumberToMoneySpecific(num) {
        let newNum = num.toString();
        try {

            if (isNaN(Number(newNum))) {
                return newNum

            }else {
                return (
                    Number(newNum)
                        .toFixed(Auth.getPrecision()) // always two decimal digits
                        .replace('.', defaultDecimalSeparator)
                ) // use . as a separator
            }
        }
        catch (e) {
            console.log('the num', num)
        }
    }

    static formatNumberToMoneyBonusApprovalWorkflow(num) {

        if(num == null || num == undefined)
        {
            return "Result not calculated";
        }
        else
        {
            try {
                let newNum = num.toString().replace(',', '.');
            
                if (isNaN(Number(newNum))) {
                    return newNum
                }
                else {
                    return (
                        Number(newNum)
                            .toFixed(Auth.getPrecision()) // always two decimal digits
                            .replace('.', defaultDecimalSeparator)
                    ) // use . as a separator
                }
            }
            catch (e) {
                console.log('the num', num)
            }
        }
        
    }

    static formatNumberToMoneyPDF(num) {
        
        try {
            let newNum = num.toString().replace(',', '.');

            if (isNaN(Number(newNum))) {
                return newNum
            }else {
                return (
                    Number(newNum)
                        .toFixed(Auth.getPrecision()) // always two decimal digits
                        .replace('.', defaultDecimalSeparator)
                ) // use . as a separator
            }
        }
        catch (e) {
            console.log('error', e)
        }
    }

    checkIfNumberAlreadyFormatted(str) {
        let isNumberAlreadyFormatted = strLen.split(defaultDecimalSeparator).length > 1;

        return isNumberAlreadyFormatted
    }

    formatDecimalDelimiter(num) {
        let numStr = num.toString();
        str = this.replaceDecimalSeparator(str);

        if (isNaN(Number(numStr))) {
            return numStr
        }
        else {

        }
    }

    /*
        NEW FUNCTIONALITY
    */
    static setLabel(params) {
        try {
            const objectDetail = params.objectDetail,
                attribute = objectDetail.attribute,
                element = objectDetail.element,
                labelElement = objectDetail.labelElement,
                defaultLabel = attribute.DefaultLabel;

            element.placeholder = defaultLabel;
            labelElement.innerText = defaultLabel;
        } catch (Exception) {
            console.log(Exception);
        }
    }

    static setRW(params) {
        try {
            const objectDetail = params.objectDetail,
                attribute = objectDetail.attribute,
                element = objectDetail.element,
                RW = attribute.RW,
                RID = objectDetail.RID;

            if (RID) {
                this.injectionDisabled(element, attribute);

                if (RW) {
                    this.setValue(params);
                }
            }
        } catch (Exception) {
            console.log(Exception);
        }
    }

    static setValue(params) {
        try {
            const objectDetail = params.objectDetail,
                attribute = objectDetail.attribute,
                element = objectDetail.element,
                value = objectDetail.value;

            switch (attribute.Name) {
                case "Bit":
                    element.checked = value;
                    break;
                case "DateTime":
                case "Date":
                    break;
                case "String":
                    break;
            }
        } catch (Exception) {
            console.log(Exception);
        }
    }

    static setOnChangeFunction(params) {
        try {
            const objectDetail = params.objectDetail,
                attribute = objectDetail.attribute,
                element = objectDetail.element,
                value = objectDetail.value;

            switch (attribute.Name) {
                case "Bit":
                    element.checked = value;
                    break;
                case "DateTime":
                case "Date":
                    break;
                case "String":
                    break;
            }
        } catch (Exception) {
            console.log(Exception);
        }
    }

    // START GROUP DATA
    static initGroupData(params) {
        const mainClass = params.mainClass;
        let object = params.object;
        let inactiveGroupContainerID = params.inactiveGroupContainerID;
        if (object) {
            let groupDataTemplate = getGroupDataTemplate({
                object, inactiveGroupContainerID
            });

            groupDataRemoveOriginalElement({ object });

            if (groupDataTemplate.container) { // check if template container is available
                if (!isGroupDataTemplateLoaded({ object })) { // check if group's template is not yet loaded, and load if not yet.
                    loadGroupData({
                        object, groupDataTemplate, inactiveGroupContainerID
                    });
                }
                if (object.DBObjectAttributeID == 169) {
                    if (object.RW == 2) {
                        initEmployeePositionGroupDataFn({ object, mainClass, generalFunction: this });
                    } else {
                        hideEmployeePositionGroupDataFn({ hide: true, object });
                    }
                } else if (object.DBObjectAttributeID == 935) {
                    if (object.Value == "1") {
                        hideEmployeePositionGroupDataFn({ hide: true, object });
                    }
                }
                else if (object.DBObjectAttributeID == 881) {
                    var lineBusinessButton = document.getElementById("line_of_bussiness").id = "line_of_business_" + object.Value;
                    $("#" + lineBusinessButton).unbind();
                    $("#" + lineBusinessButton).on("click", function () {

                        mainClass.fetchLineBusinessList(object.Value);
                        showModal(document.getElementById('line-of-business-modal'));
                    })
                }

                loadGroupDataElement({ object });
            }
        }
    }
    // END GROUP DATA

    static getDynamicElement(params) {
        let object = params.object;
        if (object.GroupData) {
            return document.getElementById(`${staticGroupElementIndicator}${object.AttributeName}_${object.GroupData.ElementID}`);
        } else if (object.HasMultipleValues) {
            return document.getElementById(object.AttributeName + "-" + object.RID);
        } else {
            return document.getElementById(object.AttributeName);
        }
    }

    static initAddNewBtn(params) {
        const _this = this,
            container = params.container,
            mainclass = params.mainClass;

        if (container) {
            let $elem = $(`#${container}`).find('.extra > a');
            $elem.off('click', onClick);
            $elem.on('click', onClick);
        }

        function onClick(event) {
            initNewEmployeePosition();
        }

        function initNewEmployeePosition() {
            if (attributeSpecificationContainer[objectTypes[0]] == null) {
                var scriptUrl = "../../../templates/employeePosition.html";
                $.ajax({
                    url: scriptUrl,
                    type: 'get',
                    dataType: 'html',
                    async: false,
                    success: function (data) {
                        employeePositionTemplate = data;
                        let $modal = $(`#${container}`).find('#empPosInfoTab-add-new-position_modal');

                        $('#modalContainer').prepend($modal);
                        $('#modalContainer').find($modal);

                        let $modalBody = $modal.find('.modal-body'),
                            $modalPositive = $modal.find('.btn-primary'),
                            newTemplate = transormTemplateIDToNew({
                                template: employeePositionTemplate,
                                searchValue: new RegExp('id="\\[', 'g'),
                                conversionValue: 'id="new_['
                            }),
                            objectAttributeSpecification = _this.getObjectAttributeSpecification({
                                objectType: objectTypes[0],
                                responseSuccessCallback: responseSuccessCallbackGetObjAttributeSpecification,
                                responseFailCallback: responseFailCallbackGetObjAttributeSpecification
                            });

                        newTemplate = transormTemplateIDToNew({
                            template: newTemplate,
                            searchValue: new RegExp('col-sm-8', 'g'),
                            conversionValue: 'col-sm-10'
                        });



                        $modalBody.find('form').remove();
                        $modalBody.append('<form class="form-horizontal"></form>');
                        $modalBody.find('form').empty().append(newTemplate);

                        $modalPositive.off('click', onClickSaveNewEmployeePosition);
                        $modalPositive.on('click', onClickSaveNewEmployeePosition);
                    }
                });

            }
        }

        function onClickSaveNewEmployeePosition(event) {
            let attributeSpecification = attributeSpecificationContainer[objectTypes[0]],
                data = {};

            if (attributeSpecification) {
                attributeSpecification.forEach(attribute => {
                    let element = _this.getDynamicElement({ object: attribute }),
                        name = attribute.Name,
                        attributeName = attribute.AttributeName,
                        val = null;

                    if (attributeName == "new_[22].EmployeeID") {
                        data[attributeName] = employeeId;
                    }

                    if (element != null) {
                        if (attribute.IsPicker) {
                            val = element.getAttribute("employeeid");

                            if (val) {
                                data[attributeName] = val;
                            }
                        } else if (attribute.IsSelectList) {
                            val = element.value;

                            if (val) {
                                val.trim();
                                data[attributeName] = val;
                            }
                        } else if (attribute.isFileUpload || attribute.IsFileUpload) {

                        } else if (attribute.IsHierarchyList) {

                        } else if (attribute.AttributeName.toLowerCase().includes("password")) {

                        } else if (attribute.IsColourPicker) {

                        } else {
                            if (name === "String") {
                                data[attributeName] = (element.value).trim();
                            } else if (name === "Date" || name === "Time" || name === "DateTime" || name === "date") {
                                data[attributeName] = (element.value).trim();
                            } else if (name === "Money" || name === "Double" || name === "BigInt" || name === "Int" || name === "Real") {
                                data[attributeName] = (element.value).trim();
                            } else if (name === "Bit") {
                                data[attributeName] = element.checked ? 1 : 0;
                            } else if (name === "Email") {

                            }
                        }
                    }
                });

                validateDataNewPosition({ data, attributeSpecification });
            }
        }

        function validateDataNewPosition(params) {
            let data = params.data,
                newData = {},
                attributeSpecification = params.attributeSpecification,
                messageParams = {
                    messageContainer: document.getElementById(messageContainers[0])
                };

            try {
                let errors = [];

                messageParams.command = 0;
                _this.initMessage(messageParams);
                messageParams.command = null;
                attributeSpecification.forEach(attribute => {
                    let attributeName = attribute.AttributeName,
                        _data = data[attributeName];
                    if (attribute.IsMandatory) {
                        if (_data == null) {
                            errors.push(`${attribute.DefaultLabel} is mandatory.`);
                        }
                    }
                });

                Object.keys(data).forEach(key => {
                    let newKey = transormTemplateIDToNew({
                        template: key,
                        searchValue: "new_[",
                        conversionValue: "["
                    });

                    newData[newKey] = data[key];
                });

                const requestjson = {
                    Module: "employee_position_crud",
                    Parameters: {
                        Action: "Assign New Position",
                        EmployeePositionID: document.getElementById("new_[22].ParentEmployeePositionID").getAttribute("employeepositionid"),
                        EmployeeID: employeeId,
                        PositionID: document.getElementById("new_[22].ParentEmployeePositionID").getAttribute("positionid"),
                        DT_StartPosition: document.getElementById("new_[22].DT_StartPosition").value,
                        DT_EndPosition: document.getElementById("new_[22].DT_EndPosition").value,
                        Page: "Position Information"
                    }
                };
                let formData = new FormData(),
                    config = {};
                formData.append('requestjson', JSON.stringify(requestjson));
                config = {
                    Data: { requestjson: formData },
                    Method: "POST",
                    ResponseSuccessCallback: responseSuccessCallbackSaveNewEmployeePosition,
                    ResponseFailCallback: responseFailCallbackSaveNewEmployeePosition,
                    Url: "/single_api/"
                };

                GeneralFunctions.genericQuery(config);
                waitingDialog.show("Processing...", { dialogSize: 'sm', progressType: 'danger' });

            } catch (Exception) {
                messageParams.isError = true;
                messageParams.message = Exception.message;

                _this.initMessage(messageParams);
                waitingDialog.hide();
            }
        }

        function responseSuccessCallbackSaveNewEmployeePosition(responseJson) {
            
            const data = responseJson.data,
                status = data.Status;

            if (status.IsSuccess) {
                let _data = data.Data,
                    employeePosition = _data.EmployeePositions,
                    employeeAttributeFKName = _data.EmployeeAttributeFKName;

                if (employeePosition && employeeAttributeFKName) {
                    $(`#${groupDataContainers[0]}`).empty();

                    Object.keys(_data).forEach(key => {
                        _this.modifyObjectDataArrayElement({
                            key,
                            data: _data[key]
                        });
                    });

                    hideModal(document.getElementById('empPosInfoTab-add-new-position_modal'));

                    mainclass.updateOnDemand({ data: objectData, fkData: employeeAttributeFKName, undefined });
                    mainclass.reloadPage();
                }
            } else {
                let messageParams = {
                    messageContainer: document.getElementById(messageContainers[0])
                };

                messageParams.isError = true;
                messageParams.message = status.Message;

                _this.initMessage(messageParams);
            }
            waitingDialog.hide();
        }

        function responseFailCallbackSaveNewEmployeePosition(responseJson) {
            console.log("responseFailCallbackSaveNewEmployeePosition", responseJson.message);
            waitingDialog.hide();
            alert(responseJson.message);
        }

        function processAttributeSpecification(params) {
            let attributeSpecification = params.attributeSpecification;
            attributeSpecificationContainer[params.objectType] = attributeSpecification;

            attributeSpecification.map(object => {
                try {
                    let element = document.getElementById(object.AttributeName);

                    if (element != null) {
                        _this.injectionType(element, object, "Manage Employee Positions");
                        _this.injectLabels(element, object);
                        _this.injectionRequired(element, object);
                        _this.injectionMandatory(element, object);
                    }
                } catch (Exception) {
                    console.log(Exception);
                }
            });
        }

        function responseSuccessCallbackGetObjAttributeSpecification(params) {
            const response = params.response,
                objectType = params.objectType,
                responseData = response.data;

            if (responseData) {
                let attributeSpecification = responseData.AttributeSpecification;

                attributeSpecification.map(attribute => {
                    attribute.AttributeName = transormTemplateIDToNew({
                        template: attribute.AttributeName,
                        searchValue: "[",
                        conversionValue: "new_["
                    })
                });

                processAttributeSpecification({ objectType, attributeSpecification });
            }
        }

        function responseFailCallbackGetObjAttributeSpecification(params) {
            console.log("responseFailCallbackGetObjAttributeSpecification", params);
        }

        function transormTemplateIDToNew(params) {
            let paramsTemplate = params.template,
                paramsSearchValue = params.searchValue,
                paramsConversionValue = params.conversionValue;
            return paramsTemplate.replace(paramsSearchValue, paramsConversionValue);
        }
    }

    static getObjectAttributeSpecification(params) {
        let responseSuccessCallback = params.responseSuccessCallback,
            responseFailCallback = params.responseFailCallback,
            objectType = params.objectType;

        axios({
            baseURL: API_URL,
            params: {
                ObjectType: objectType
            },
            headers: {
                token: Auth.getToken()
            },
            method: "GET",
            url: "/object_attribute_specification/"
        })
            .then(response => {
                responseSuccessCallback({ response, objectType });
            })
            .catch(thrown => {
                responseFailCallback({ thrown });
            });
    }

    // FOR APPROVAL
    static initApproval(params) {
        let object = params.object,
            element = this.getDynamicElement({ object }),
            $parentElem = $(element).parent().closest("[class^='col-']"),
            attributeName = object.AttributeName,
            value = object.Value,
            pendingValue = null;

        if (objFkNameArray) {
            let attributeNameSplit = attributeName.split('].'),
                splitName = attributeNameSplit[1];

            objFkNameArray.forEach(obj => {
                if (obj[splitName] == value) {
                    pendingValue = obj[`${splitName}_Pending`];
                }
            });
        }

        $parentElem.find('.pending-class').remove();
        if (pendingValue) {
            $parentElem.append(`<span class="label label-primary pending-class">Pending: ${pendingValue}</span>`);
        }
    }

    static onChangeUpdateFlag(element) {
        element.onchange = this.insertNumericValueFlag;
    }

    static addFlag = () => {
        let newObject, newTarget, newPlace, newBaseValue, newConditions, newFlagMessage, newIdExtender;
        let flagDisplay;
        let init = (object, target, place, baseValue, conditions, flagMessage, idExtender) => {
            flagDisplay = document.createElement('div');
            newObject = object;
            newTarget = target;
            newPlace = place;
            newBaseValue = baseValue;
            newConditions = conditions;
            newFlagMessage = flagMessage;
            newIdExtender = idExtender;
        };

        let flagValue = (object) => {

        }

        let insertNumericValueFlag = () => {
            let conditionValue = 0;
            let valueLabel;
            let flagContent;
            let targetAttribute;
            let containerCondition;

            let getObjectKey = (object) => {
                return Object.keys(object)[0]
            }

            let checkIfGroup = (object) => {
                if(object[0] == undefined)
                {
                    return Array.isArray(object[getObjectKey(object)])
                }
                else
                {
                    return object[0]['GroupData']
                }
            }

            let getAttributeTargetValue = (object, target) => {
                if (checkIfGroup(object)) {
                    return object[getObjectKey(object)].map(attribute => {
                        return attribute.Attributes.filter(attrib => {
                            return attrib.AttributeName === target
                        })[0].Value
                    })
                }
                else {

                }
            }

            let checkIfFlagExist = () => {
                return null
            }

            let conditionAdder = (object, conditions) => {
                if (conditions[0] === "includes") {
                    return object
                }
                else {
                    return conditions
                }
            }

            let conditionChecker = (conditions, baseValue, elem) => {
                if (newConditions[0] === 'includes') {
                    flagContent = `${valueLabel} has a ${newBaseValue}`;

                    flagDisplay.setAttribute('class', "tag is-warning");

                    flagDisplay.setAttribute('id', `flag-${newTarget}${newIdExtender}`);
                    if ([...elem].filter(element => element.value == "0").length === 0) {
                        flagContent = undefined;
                        flagDisplay.innerHTML = "";
                        flagDisplay.setAttribute('class', "");
                    }
                }
                else {
                    conditions.map(condition => {
                        if (eval(`${conditionValue} ${condition} ${baseValue}`)) {
                            flagContent = `${valueLabel} is ${condition} ${baseValue}`;

                            flagDisplay.setAttribute('class', "tag is-warning");
                            if (newIdExtender) {
                                flagDisplay.setAttribute('id', `flag-${newTarget}${newIdExtender}`);
                            }
                            else {
                                flagDisplay.setAttribute('id', `flag-${newTarget}`);
                            }
                        }
                    })
                    if (newBaseValue != conditionValue) {
                    }
                    else {
                        flagDisplay.innerHTML = "";
                        flagDisplay.setAttribute('class', "");
                    }
                }
            }

            let customFlagDisplay = () => {

            }

            let valueOverwrite = () => {

            }

            if (checkIfGroup(newObject)) {
                if (newIdExtender) {
                    [...document.querySelectorAll(`[id^="flag-${newTarget}${newIdExtender}"]`)].map(el => el.remove());
                }
                else {
                    [...document.querySelectorAll(`[id^="flag-${newTarget}"]`)].map(el => el.remove());
                }
            }
            else {
                if (newIdExtender) {
                    if (document.getElementById(`flag-${newTarget}${newIdExtender}`)) {
                        document.getElementById(`flag-${newTarget}${newIdExtender}`).remove();
                    }
                }
                else {

                    if (document.getElementById(`flag-${newTarget}`)) {
                        document.getElementById(`flag-${newTarget}`).remove();
                    }
                }
            }
            if (checkIfGroup(newObject)) {

                //To be change to dynmaic value for group
                let elem = document.querySelectorAll(`[id^="_group_${newTarget}"]`);
                elem.forEach(element => {
                    element.addEventListener('change', () => insertNumericValueFlag(newObject, newTarget, newPlace, newBaseValue, newConditions, newFlagMessage, newIdExtender));
                });
                newObject.forEach(attribute => {
                    if (attribute['AttributeName'] == newTarget) {
                        targetAttribute = attribute
                    }
                })
                elem.forEach(element => {
                    conditionValue += Number(element.value);
                });

                valueLabel = targetAttribute.DefaultLabel;
                conditionChecker(newConditions, newBaseValue, elem)

            }
            else {
                let elemFlag = document.getElementById(`${newTarget}`)
                elemFlag.addEventListener('change', () => insertNumericValueFlag(newObject, newTarget, newPlace, newBaseValue, newConditions, newFlagMessage, newIdExtender));
                let targetAttribute = newObject;
                let type = targetAttribute.Name;
                
                if (targetAttribute.Value === null) {
                    conditionValue = null
                }
                else {
                    conditionValue += Number(targetAttribute.Value)
                }
                valueLabel = targetAttribute.DefaultLabel
                if (type === "String") {

                }
                else if (type === "BigInt") {
                }
                else if (type === "Flag") {

                }

                if (targetAttribute.AttributeName === "[95].ManualSalaryOverwrite") {
                    newBaseValue = newBaseValue === null ? null : Number(newBaseValue);
                }

                newConditions.map(condition => {
                    newBaseValue = newBaseValue === null ? null : Number(newBaseValue);
                    if (eval(`${conditionValue} ${condition} ${newBaseValue}`)) {
                        if (targetAttribute.AttributeName === "[95].ManualSalaryOverwrite") {

                        }
                        containerCondition = condition;
                        flagContent = `${valueLabel} is ${condition} ${newBaseValue}`;
                        flagDisplay.setAttribute('class', "tag is-warning");
                        if (newIdExtender) {
                            flagDisplay.setAttribute('id', `flag-${newTarget}${newIdExtender}`);
                        }
                        else {
                            flagDisplay.setAttribute('id', `flag-${newTarget}`);
                        }
                    }
                })

                if (newBaseValue != conditionValue) {
                    flagDisplay.setAttribute('class', "tag is-warning");
                    if (newIdExtender) {
                        flagDisplay.setAttribute('id', `flag-${newTarget}${newIdExtender}`);
                    }
                    else {
                        flagDisplay.setAttribute('id', `flag-${newTarget}`);
                    }
                }
                else {
                    flagDisplay.innerHTML = "";
                    flagDisplay.setAttribute('class', "");
                }
            }

            flagDisplay.innerHTML = flagContent === undefined || null ? '' : newFlagMessage ? newFlagMessage : flagContent;
            flagDisplay.style.width = "100%";


            let containerPlace = document.getElementById(newPlace);
            containerPlace.after(flagDisplay);
        }

        return {
            initializeValue: (object, target, place, baseValue, conditions, flagMessage, idExtender) => {
                return init(object, target, place, baseValue, conditions, flagMessage, idExtender);
            },
            initializeFlag: () => {
                return insertNumericValueFlag();
            },
            initFlagValue: (object) => {
                return flagValue(object)
            }
        }

    };

    static initConfirmationModal(params) {
        const modalContainerID = 'modalContainer',
            confirmationModalID = 'gen-modal-confirmation',
            confirmationModalTitleID = 'gen-modal-confirmation-title',
            confirmationModalContentID = 'gen-modal-confirmation-content',
            confirmationModalBtnNegID = 'gen-modal-confirmation-btnNeg',
            confirmationModalBtnPosID = 'gen-modal-confirmation-btnPos';

        if ($(`#${modalContainerID}`).find(`#${confirmationModalID}`).length == 0) {
            $(`#${modalContainerID}`).prepend(
                `<div class="modal fade" id="${confirmationModalID}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 class="modal-title" id="${confirmationModalTitleID}"></h4>
                            </div>
                            <div class="modal-body"><p id="${confirmationModalContentID}"></p></div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-default" data-dismiss="modal" id="${confirmationModalBtnNegID}"><span></span></button>
                                <button type="button" class="btn btn-primary" id="${confirmationModalBtnPosID}"><span></span></button>
                            </div>
                        </div>
                    </div>
                </div>`
            );
        }

        if (params.show) {
            $(`#${confirmationModalTitleID}`).text(params.title);
            $(`#${confirmationModalContentID}`).text(params.content);
            $(`#${confirmationModalBtnPosID}`).unbind();
            $(`#${confirmationModalBtnPosID}`).on('click', params.btnPosCallback);
            $(`#${confirmationModalBtnPosID}`).find('span').text(params.textPos);
            $(`#${confirmationModalBtnNegID}`).unbind();
            $(`#${confirmationModalBtnNegID}`).on('click', params.btnNegCallback);
            $(`#${confirmationModalBtnNegID}`).find('span').text(params.textNeg);

            $(`#${confirmationModalID}`).modal("show");
        } else {
            $(`#${confirmationModalID}`).modal("hide");
        }


    }

    //translations
    static translatePage() {
        let self = this;
        let currentLanguage = localStorage.getItem("Language").toLowerCase();
        let languageList = localStorage.getItem("languageList");
        let JSONlanguageList = JSON.parse(languageList);
        let requestjson = {
            Module: "system_label_staticlist",
            Parameters: {
                Language: JSONlanguageList[currentLanguage],
            }
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };
        GeneralFunctions.genericQuery(config);
        function responseSuccessCallback(responseJson) {
            let data = responseJson.data;
            if (data.Status.IsSuccess) {
                localStorage.setItem("staticLabels", JSON.stringify(data.Data.StaticLabels));
                var datalength = JSON.parse(localStorage.getItem("staticLabels"));
                var labelID = document.querySelectorAll('[name="static-label"]');
                for (var i = 0, len = datalength.length; i < len; i++) {
                    for (var x = 0, lengths = labelID.length; x < lengths; x++) {
                        if (labelID[x].id == datalength[i].LabelID) {
                            document.getElementById(labelID[x].id).innerHTML = datalength[i].Label;
                            document.getElementById(labelID[x].id).placeholder = datalength[i].Label;
                        }
                    }
                }
            }
        }
        function responseFailCallback(responseJson) {
            alert(responseJson.message);
        }
    }

    static translatePageForTemplate() {
        let self = this;
        let currentLanguage = localStorage.getItem("Language").toLowerCase();
        let languageList = localStorage.getItem("languageList");
        let JSONlanguageList = JSON.parse(languageList);
        let requestjson = {
            Module: "system_label_staticlist",
            Parameters: {
                Language: JSONlanguageList[currentLanguage],
            }
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };
        GeneralFunctions.genericQuery(config);
        function responseSuccessCallback(responseJson) {
            let data = responseJson.data;
            if (data.Status.IsSuccess) {
                localStorage.setItem("staticLabels", JSON.stringify(data.Data.StaticLabels));
                var datalength = JSON.parse(localStorage.getItem("staticLabels"));
                var labelID = document.querySelectorAll('[name="static-label"]');
                for (var i = 0, len = datalength.length; i < len; i++) {
                    for (var x = 0, lengths = labelID.length; x < lengths; x++) {
                        if (labelID[x].id == datalength[i].LabelID) {
                            document.getElementById(labelID[x].id).innerHTML = datalength[i].Label;
                        }
                    }
                }
            }
        }
        function responseFailCallback(responseJson) {
            alert(responseJson.message);
        }
    }

    static dynamicLabelOnclick() {
        let self = this;

        var labelID = document.querySelectorAll('[name="static-label"]');
        for (var i = 0, len = labelID.length; i < len; i++) {

            document.getElementById(labelID[i].id).style.textDecoration = "underline";

            $('#' + labelID[i].id).bind('click', function (paramLabelID) {
                GeneralFunctions.fetchSpecificLabel(paramLabelID.target.id);
            });
        }
    }

    static dynamicLabelTemplateOnclick() {
        let self = this;

        var labelID = document.querySelectorAll('[name="label-id"]');
        for (var i = 0, len = labelID.length; i < len; i++) {

            document.getElementById(labelID[i].id).style.textDecoration = "underline";

            $('#' + labelID[i].id).bind('click', function (paramLabelID) {
                GeneralFunctions.fetchSpecificLabelTemplate(paramLabelID.target.id, Auth.getLanguageID());
            });
        }
    }

    static noFunctionLabelTemplateOnclick() {
        let self = this;
        var labelID = document.querySelectorAll('[name="label-id"]');
        for (var i = 0, len = labelID.length; i < len; i++) {

            if (document.getElementById(labelID[i].id) !== null) {

                document.getElementById(labelID[i].id).style.textDecoration = "none";

                $('#' + labelID[i].id).attr("onclick", "").unbind("click");
            }
        }
    }

    static noFunctionLabelOnclick() {
        let self = this;
        var labelID = document.querySelectorAll('[name="static-label"]');
        for (var i = 0, len = labelID.length; i < len; i++) {

            if (document.getElementById(labelID[i].id) !== null) {

                document.getElementById(labelID[i].id).style.textDecoration = "none";

                $('#' + labelID[i].id).attr("onclick", "").unbind("click");
            }
        }
    }


    static loopStaticLabel(labelID, staticLabel = '', labelTypeID) {
        let isStaticLabelUndefined = localStorage.getItem("staticLabels") === 'undefined';

        var dataLanguages;


        if (isStaticLabelUndefined) {
            this.updateStaticLabelLocalStorage();
        }
        else {
            dataLanguages = JSON.parse(localStorage.getItem("staticLabels"));
        }
        try {
            if (labelID == "-396") {
                return <span id={labelID} name="label-id">{dataLanguages.filter(obj => { return obj.LabelID === parseInt(labelID) })[0].Label}</span>
            }
            else {
                return <span id={labelID} name="static-label">{dataLanguages.filter(obj => { return obj.LabelID === parseInt(labelID) })[0].Label}</span>
            }
        }
        catch (e) {
            if (labelID == "-396") {
                return <span id={labelID} name="label-id">{staticLabel}</span>
            }
            else {
                return <span id={labelID} name="static-label">{staticLabel}</span>
            }

        }
    }

    static addLabelsOnLoad(labelID, label, labelTypeID = null) {
        
        let requestjson = {
            Module: "system_label_addupdate",
            Parameters: {
                LabelTypeID: labelTypeID,
                Label: label,
                LabelID: labelID
            }
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

        GeneralFunctions.genericQuery(config);

        function responseSuccessCallback(responseJson) {
            let data = responseJson.data;
            
            if (data.Status.IsSuccess) 
            {
                console.log(`static label added`)
            }
            else 
            {
                console.log(`static label - ${data.Data}`)
            }
            
        }

        function responseFailCallback(responseJson) {
            alert(responseJson.message);
        }
    }

    static fetchSpecificLabelTemplate(labelID, languageID) {
        var dataLanguages = JSON.parse(localStorage.getItem("staticLabels"));

        let currentLanguage = localStorage.getItem("Language").toLowerCase();
        let languageList = localStorage.getItem("languageList");

        let JSONlanguageList = JSON.parse(languageList);


        let requestjson = {
            Module: "system_label_data",
            Parameters: {
                DBObjectAttributeID: labelID,
                SelectedLanguageID: languageID
            }
        };
        let self = this,
            newCancelToken = axios.CancelToken,
            newSource = newCancelToken.source(),
            config = {
                CancelToken: newSource,
                Data: { requestjson: requestjson },
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            };

        config = GeneralFunctions.genericQuery(config);
        newSource = config.CancelToken;

        function responseSuccessCallback(responseJson) {
            let data = responseJson.data;

            if (data.Status.IsSuccess) {
                showModal(document.getElementById("translateModal"));
                document.getElementById("lblLabelLanguage").textContent = JSONlanguageList[currentLanguage];
                document.getElementById("editLabel").value = data.Data.LabelData[0].Label;
                document.getElementById("lblLabelDefault").textContent = data.Data.LabelData[0].DefaultLabel;
                document.getElementById("lblLabelID").textContent = data.Data.LabelData[0].LabelID;
                document.getElementById("selectedRID").value = data.Data.LabelData[0].RID;
                document.getElementById("selectedOldValue").value = data.Data.LabelData[0].Label;
                document.getElementById("templateLabel").value = "Yes";
                document.getElementById("templateLabelID").value = labelID;
                document.getElementById("labelTypeID").value = data.Data.LabelData[0].LabelTypeID;
                document.getElementById("translabelID").value = data.Data.LabelData[0].TransLabelID;
            }
        }

        function responseFailCallback(responseJson) {
            console.log("responseFailCallback", responseJson);
        }
    }

    static fetchSpecificLabel(labelID) {

        var dataLanguages = JSON.parse(localStorage.getItem("staticLabels"));

        let currentLanguage = localStorage.getItem("Language").toLowerCase();
        let languageList = localStorage.getItem("languageList");

        let JSONlanguageList = JSON.parse(languageList);

        document.getElementById("lblLabelLanguage").textContent = JSONlanguageList[currentLanguage];
        document.getElementById("editLabel").value = dataLanguages.filter(obj => { return obj.LabelID === parseInt(labelID) })[0].Label;
        document.getElementById("lblLabelDefault").textContent = dataLanguages.filter(obj => { return obj.LabelID === parseInt(labelID) })[0].DefaultLabel;
        document.getElementById("lblLabelID").textContent = dataLanguages.filter(obj => { return obj.LabelID === parseInt(labelID) })[0].LabelID;
        document.getElementById("selectedRID").value = dataLanguages.filter(obj => { return obj.LabelID === parseInt(labelID) })[0].RID;
        document.getElementById("selectedOldValue").value = dataLanguages.filter(obj => { return obj.LabelID === parseInt(labelID) })[0].Label;
        document.getElementById("labelTypeID").value = dataLanguages.filter(obj => { return obj.LabelID === parseInt(labelID) })[0].LabelTypeID;
        document.getElementById("translabelID").value = dataLanguages.filter(obj => { return obj.LabelID === parseInt(labelID) })[0].TransLabelID;
        document.getElementById("templateLabel").value = "No";
        showModal(document.getElementById("translateModal"));

    }

    static translateLabelFunction() {
        let oldValue = document.getElementById("selectedOldValue").value;
        let RID = document.getElementById("selectedRID").value;
        let newValue = document.getElementById("editLabel").value;
        let labelID = document.getElementById("lblLabelID").innerText;
        let labelTypeID = document.getElementById("labelTypeID").value;
        let translabelID = document.getElementById("translabelID").value;
        let templateLabelID = document.getElementById("templateLabelID").value;
        let isTemplate = document.getElementById("templateLabel").value;

        if(newValue == null || newValue == "")
        {
            Swal2.fire({
                text: "Adding blank label is not possible",
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#d33',
                cancelButtonText: "Ok"
            })
        }
        else
        {
            if (isTemplate == "Yes") {
                this.updateForStaticLabels(Auth.getLanguageID(), newValue, labelTypeID, translabelID, labelID, isTemplate, templateLabelID)
            }
            else {
                this.updateForStaticLabels(Auth.getLanguageID(), newValue, labelTypeID, translabelID, labelID, isTemplate);
            }
        }
    }

    static updateByRIDStaticLabel(data, labelID, newValue) {
        waitingDialog.show("Translating label...", { dialogSize: 'sm', progressType: 'danger' });

        fetch(API_URL + "/generic_update/", { // Your POST endpoint
            method: 'PUT',
            headers: {
                'token': Auth.getToken()
            },
            body: data
        })
            .then(response => response.json()
            )
            .then(success => {
                var detail = success.detail[0]
                if (detail[0] == "ok") {
                    document.getElementById(labelID).innerHTML = newValue;
                    document.getElementById(labelID).placeholder = newValue;
                    GeneralFunctions.updateStaticLabelLocalStorage();
                }
                waitingDialog.hide();
            })
            .catch(error => {
                console.log("error", error);
                waitingDialog.hide();
            });
    }

    static updateForStaticLabels(languageID, label, labelTypeID, transLabelID, labelID, isTemplate, templateLabelID) {
        let self = this;

        let requestjson = {
            Module: "system_label_onpageupdate",
            Parameters: {
                LanguageID: languageID,
                Label: label,
                LabelTypeID: labelTypeID,
                TransLabelID: transLabelID,
                LabelID: labelID
            }
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

        GeneralFunctions.genericQuery(config);

        function responseSuccessCallback(responseJson) {
            let data = responseJson.data;
            if (data.Status.IsSuccess) {
                if (isTemplate == "Yes") {
                    document.getElementById(templateLabelID).innerHTML = label;
                    GeneralFunctions.updateStaticLabelLocalStorage();
                }
                else {
                    document.getElementById(labelID).innerHTML = label;
                    document.getElementById(labelID).placeholder = label;
                    GeneralFunctions.updateStaticLabelLocalStorage();
                }
            }
        }
        function responseFailCallback(responseJson) {
            alert(responseJson.message);
        }

    }

    static appendSelectListByModuleCall(moduleName, callParams, elementID) {
        let self = this;

        let requestjson = {
            Module: moduleName,
            Parameters: callParams,
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

        GeneralFunctions.genericQuery(config);

        function responseSuccessCallback(responseJson) {
            let data = responseJson.data;
            if (data.Status.IsSuccess) {

                var CurrencySelect = document.getElementById(elementID);
                let arrayData = data.Data;
                for (var i = 0; i < arrayData.length; i++) {
                    var options = document.createElement("option");
                    options.value = arrayData[i].ID;
                    options.innerHTML = arrayData[i].Value;
                    CurrencySelect.appendChild(options);
                }
            }
        }
        function responseFailCallback(responseJson) {
            alert(responseJson.message);
        }

    }

    static updateStaticLabelLocalStorage = () => {
        let self = this;

        let currentLanguage = localStorage.getItem("Language").toLowerCase();
        let languageList = localStorage.getItem("languageList");

        let JSONlanguageList = JSON.parse(languageList);

        let requestjson = {
            Module: "system_label_staticlist",
            Parameters: {
                Language: JSONlanguageList[currentLanguage],
            }
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

        GeneralFunctions.genericQuery(config);

        function responseSuccessCallback(responseJson) {
            let data = responseJson.data;
            if (data.Status.IsSuccess) {
                localStorage.setItem("staticLabels", JSON.stringify(data.Data.StaticLabels));
            }
        }

        function responseFailCallback(responseJson) {
            alert(responseJson.message);
        }
    }

    static updateStaticLabel = (selectedLanguage) => {
        let self = this;

        let requestjson = {
            Module: "system_label_staticlist",
            Parameters: {
                Language: selectedLanguage,
            }
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

        GeneralFunctions.genericQuery(config);

        function responseSuccessCallback(responseJson) {
            let data = responseJson.data;
            if (data.Status.IsSuccess) {
                localStorage.setItem("staticLabels", JSON.stringify(data.Data.StaticLabels));
                window.location.reload();
            }
        }
        function responseFailCallback(responseJson) {
            alert(responseJson.message);
        }
    }

    static createSelectList(element, arraySelectList) {
        var mySelect = document.getElementById(element.id);
        var newOption = document.createElement('option');

        if (arraySelectList == null || arraySelectList == undefined) {
            mySelect.options[1] = new Option("AttributeFKJSON in Null", "-1")
        }
        else {
            var length = arraySelectList.length;
            
            for (var j = 0; j < length; j++) {
                mySelect.options[mySelect.options.length] = new Option(arraySelectList[j].Value, arraySelectList[j].ID, arraySelectList[j].Selected);
            }
        }


    }

    static convertDateTime(date, serverFormat = 'YYYY-MM-DD') {
        var date = moment(date, Auth.getDateFormat())
        return date.format(serverFormat);
    }

    static unShareObjectives(dynamicVariable, calledLocation, isLogicalDelete) {
        let dynamicParams;
        if(calledLocation == "Delete Objective")
        {
            dynamicParams = {PMEmployeeObjectiveID: dynamicVariable, IsLogicalDelete: isLogicalDelete}
        }
        else if(calledLocation == "Vacate or Assign Hierarchical Manager")
        {
            dynamicParams = {EmployeePositionID: dynamicVariable, IsLogicalDelete: isLogicalDelete}
        }
        else
        {
            dynamicParams = {ManagerID: dynamicVariable, IsLogicalDelete: isLogicalDelete}
        }

        let self = this,
            newCancelToken = axios.CancelToken,
            newSource = newCancelToken.source(),
            params = dynamicParams,
             requestjson = {
                Module: "performancemanagement_objective_unshare",
                Parameters: params
            }, config = {
                Data: { requestjson: requestjson },
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            };
    
        GeneralFunctions.genericQuery(config);
        function responseSuccessCallback(responseJson) {
            let data = responseJson.data;
    
            if (data.Status.IsSuccess) {
                let Data = data.Data;
                // console.log("Success", data.Status.Message);
            } else {
                Swal2.fire({
                    text: data.Status.Message,
                    icon: 'error',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Ok"
                })
            }
        }
    
        function responseFailCallback(responseJson) {
            console.log("responseFailCallback", responseJson);
        }
    }

}

function getObjectStringConcat(object) {
    return object.AttributeName + "-" + object.RID;
}

function createNewAttribute(attributeName, attributeValue) {
    let att = document.createAttribute(attributeName);
    att.value = attributeValue;

    return att; // RETURN THE NEW ATTRIBUTE
}

function findUpElementByClass(_el, _className) {
    while (_el.parentNode) {
        _el = _el.parentNode;
        if (_el.className === _className)
            return _el;
    }
    return null;
}

let decimalSeparator = ".",
    digitGroupingSeparator = ",",
    defaultDecimalFixedValue = 1,
    // SEPARATORS FROM USER DEFAULT SETTINGS
    userDefaultSettingsDecimalSeparator = undefined,
    userDefaultSettingsDigitSeparator = undefined,
    // SEPARATORS FROM SYSTEM DEFAULT SETTINGS
    systemDefaultSettingsDecimalSeparator = undefined,
    systemDefaultSettingsDigitSeparator = undefined,
    // STANDARDS
    defaultStandardGroupingSeparator = ",",
    defaultStandardDecimalSeparator = ".",
    defaultStandardMoneyFormat = "#,###.#";


let delimiters = GeneralFunctions.getDelimiter();
let {
    defaultDigitGroupingSeparator = Auth.getDigitGroupSeparator() != null ? Auth.getDigitGroupSeparator() : ".",
    defaultDecimalSeparator = Auth.getDecimalSeparator() != null ? Auth.getDecimalSeparator() : ",",
    defaultPrecision = Auth.getPrecision() != null ? Auth.getPrecision() : "2"
} = delimiters;
// NEW EMPLOYEE POSITION
let attributeSpecificationContainer = {},
    objectTypes = [
        'EmployeePosition'
    ],
    messageContainers = [
        'empPosInfoTab-add-new-position_modal-message-container'
    ];

// START GROUP DATA FUNCTIONS
const staticGroupIndicator = '_groupTemplate_',
    staticGroupElementIndicator = '_group_',
    groupDataContainers = [
        '_group_positionTab_container'
    ];
let groupEmployeePositionTemplate = null;

function getTemplate(params) {
    switch (params.container) {
        case groupDataContainers[0]:
            if (groupEmployeePositionTemplate == null && groupEmployeePositionTemplate != 0) {
                groupEmployeePositionTemplate = 0;
                var scriptUrl = "../../../templates/_group_employeePosition.html";
                $.ajax({
                    url: scriptUrl,
                    type: 'get',
                    dataType: 'html',
                    async: false,
                    success: function (data) {
                        groupEmployeePositionTemplate = data;
                    }
                });
                return groupEmployeePositionTemplate;
            } else if (groupEmployeePositionTemplate != null && groupEmployeePositionTemplate != 0) {
                return groupEmployeePositionTemplate;
            }
        default:
            if (groupEmployeePositionTemplate == null && groupEmployeePositionTemplate != 0) {
                groupEmployeePositionTemplate = 0;
                var scriptUrl = "../../../templates/_group_employeePosition.html";
                $.ajax({
                    url: scriptUrl,
                    type: 'get',
                    dataType: 'html',
                    async: false,
                    success: function (data) {
                        groupEmployeePositionTemplate = data;
                    }
                });
                return groupEmployeePositionTemplate;
            } else if (groupEmployeePositionTemplate != null && groupEmployeePositionTemplate != 0) {
                return groupEmployeePositionTemplate;
            }
    }
}

function getGroupDataTemplate(params) {
    let object = params.object,
        inactiveGroupContainerID = params.inactiveGroupContainerID,
        groupDataContainer = null;
    const groupID = object.GroupData.GroupID;

    if (groupID) {
        switch (groupID) {
            case 1:
                groupDataContainer = inactiveGroupContainerID != undefined ? inactiveGroupContainerID : groupDataContainers[0];
                return {
                    template: getTemplate({ container: groupDataContainer }),
                    container: document.getElementById(groupDataContainer)
                };
        }
    }

    return {
        template: null,
        container: null
    };
}

function isGroupDataTemplateLoaded(params) {
    const object = params.object,
        groupData = object.GroupData;

    return document.getElementById(`${staticGroupIndicator}${groupData.GroupID}_${groupData.ElementID}`);
}

function loadGroupData(params) {
    let object = params.object,
        groupData = object.GroupData,
        inactiveGroupContainerID = params.inactiveGroupContainerID,
        groupDataTemplate = params.groupDataTemplate;
    let isGroupDataContainerActive = inactiveGroupContainerID ? inactiveGroupContainerID : groupDataContainers[0];
    $(`#${isGroupDataContainerActive}`).append(groupDataTemplate.template);
    $(`#${staticGroupIndicator}${groupData.GroupID}`).attr('id', `${staticGroupIndicator}${groupData.GroupID}_${groupData.ElementID}`);
}

function loadGroupDataElement(params) {
    let object = params.object,
        groupData = object.GroupData;

    $(`[id='${staticGroupElementIndicator}${object.AttributeName}']`).attr('id', `${staticGroupElementIndicator}${object.AttributeName}_${groupData.ElementID}`);
}

function initEmployeePositionGroupDataFn(params) {
    let object = params.object,
        generalFunction = params.generalFunction,
        mainClass = params.mainClass,
        groupData = object.GroupData,
        $groupDataContainer = $(`#${staticGroupIndicator}${groupData.GroupID}_${groupData.ElementID}`),
        $groupDataVacate = $groupDataContainer.find(`#${staticGroupElementIndicator}vacate`),
        $groupDataAssignPosition = $groupDataContainer.find(`#${staticGroupElementIndicator}assign_position`);

    $groupDataVacate.on('click', { object, generalFunction, mainClass }, onClickGroupDataVacatePosition);
    $groupDataAssignPosition.on('click', { object, generalFunction, mainClass }, onClickGroupDataAssignPosition);
}

function hideEmployeePositionGroupDataFn(params) {
    let object = params.object,
        hide = params.hide,
        groupData = object.GroupData,
        $groupDataContainer = $(`#${staticGroupIndicator}${groupData.GroupID}_${groupData.ElementID}`);

    if (hide) {
        $groupDataContainer.find('.flex-card-title').hide();
    } else {
        $groupDataContainer.find('.flex-card-title').show();
    }

}

function groupDataRemoveOriginalElement(params) {
    let object = params.object;
    $(`[id='${object.AttributeName}']`).parents('.form-group').remove();
}

function onClickGroupDataVacatePosition(event) {
    checkEmployeePositionObjectivesVacating(event.data.object.GroupData.ElementID, "Vacate Position", event);
}


function checkEmployeePositionObjectivesVacating(employeePositionID, method, event) {
    let self = this,
        newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source(),
        params = {
            EmployeePositionID: employeePositionID,
        }, requestjson = {
            Module: "performancemanagement_objective_checkshared",
            Parameters: params
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    GeneralFunctions.genericQuery(config);
    function responseSuccessCallback(responseJson) {
        let data = responseJson.data;

        if (data.Status.IsSuccess) {
            let Data = data.Data;
            if(method == "Vacate Position")
            {
                getEmployeePositionAttributesVacate(employeePositionID, "Vacate Position", event, null);
            }
        } else {
            Swal2.fire({
                title: 'Are you sure?',
                text: responseJson.data.Status.Message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm'
              }).then((result) => {
                if (result.isConfirmed) {
                    getEmployeePositionAttributesVacate(employeePositionID, "Vacate Position", event);
                }
              })
        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
    }
}

function checkEmployeePositionObjectives(employeePositionID, method) {
    let self = this,
        newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source(),
        params = {
            EmployeePositionID: employeePositionID,
        }, requestjson = {
            Module: "performancemanagement_objective_checkshared",
            Parameters: params
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    GeneralFunctions.genericQuery(config);
    function responseSuccessCallback(responseJson) {
        let data = responseJson.data;

        if (data.Status.IsSuccess) {
            let Data = data.Data;

            if(method == "Assign New Hierarchical Manager")
            {
                getEmployeeAttributesHierarchicalManager(employeePositionID, "Assign New Hierarchical Manager");
            }
            else
            {
                getEmployeeAttributesMoveHierarchicalManager(employeePositionID, "Move Position - Hierarchical Manager");
            }

        } else {
            Swal2.fire({
                title: 'Are you sure?',
                text: responseJson.data.Status.Message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm'
              }).then((result) => {
                if (result.isConfirmed) {
                    if(method == "Assign New Hierarchical Manager")
                    {
                        getEmployeeAttributesHierarchicalManager(employeePositionID, "Assign New Hierarchical Manager");
                    }
                    else
                    {
                        getEmployeeAttributesMoveHierarchicalManager(employeePositionID, "Move Position - Hierarchical Manager")
                    }
                }
              })
        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
    }
}

function getEmployeePositionAttributesAssignNewPosition(employeePositionID, method) {
    let self = this,
        newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source(),
        params = {
            EmployeePositionID: employeePositionID,
            Action: method,
            Page: "Position Information"
        }, requestjson = {
            Module: "employee_position_crudattributes",
            Parameters: params
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    GeneralFunctions.genericQuery(config);
    function responseSuccessCallback(responseJson) {
        let data = responseJson.data;

        if (data.Status.IsSuccess) {
            let Data = data.Data;
            handleModalAssignPosition(Data.AttributeSpecification, employeePositionID, "edit-position-modal", method)
        } else {
            Swal2.fire({
                title: "Error",
                text: data.Status.Message,
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#d33',
                cancelButtonText: "Ok"
            })

        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
    }
}

function getEmployeePositionAttributesMoveNewPosition(employeePositionID, method) {
    hideModal(document.getElementById(`selectMethodModal`))
    let self = this,
        newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source(),
        params = {
            EmployeePositionID: employeePositionID,
            Action: method
        }, requestjson = {
            Module: "employee_position_crudattributes",
            Parameters: params
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    GeneralFunctions.genericQuery(config);
    function responseSuccessCallback(responseJson) {
        let data = responseJson.data;

        if (data.Status.IsSuccess) {
            let Data = data.Data;
            handleModalMovePosition(Data.AttributeSpecification, employeePositionID, "edit-position-modal", method)
        } else {
            Swal2.fire({
                title: "Error",
                text: data.Status.Message,
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#d33',
                cancelButtonText: "Ok"
            })

        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
    }
}

function getEmployeeAttributesHierarchicalManager(employeePositionID, method) {
    let self = this,
        newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source(),
        params = {
            EmployeePositionID: employeePositionID,
            Action: method
        }, requestjson = {
            Module: "employee_position_crudattributes",
            Parameters: params
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    GeneralFunctions.genericQuery(config);
    function responseSuccessCallback(responseJson) {
        let data = responseJson.data;

        if (data.Status.IsSuccess) {
            let Data = data.Data;
            handleModalAssignHierarchicalManager(Data.AttributeSpecification, employeePositionID, "edit-position-modal", method)
        } else {
            Swal2.fire({
                title: "Error",
                text: data.Status.Message,
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#d33',
                cancelButtonText: "Ok"
            })
        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
    }
}

function getEmployeeAttributesMoveHierarchicalManager(employeePositionID, method) {
    hideModal(document.getElementById(`selectMethodManager`))
    let self = this,
        newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source(),
        params = {
            EmployeePositionID: employeePositionID,
            Action: method
        }, requestjson = {
            Module: "employee_position_crudattributes",
            Parameters: params
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    GeneralFunctions.genericQuery(config);
    function responseSuccessCallback(responseJson) {
        let data = responseJson.data;

        if (data.Status.IsSuccess) {
            let Data = data.Data;
            handleModalAssignMoveHierarchicalManager(Data.AttributeSpecification, employeePositionID, "edit-position-modal", method);
        } else {
            Swal2.fire({
                title: "Error",
                text: data.Status.Message,
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#d33',
                cancelButtonText: "Ok"
            })

        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
    }
}

function getEmployeePositionAttributesVacate(elementID, method, event) {
    let self = this,
        newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source(),
        params = {
            EmployeePositionID: elementID.toString(),
            Action: "Vacate Position"
        }, requestjson = {
            Module: "employee_position_crudattributes",
            Parameters: params
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    GeneralFunctions.genericQuery(config);
    function responseSuccessCallback(responseJson) {
        let data = responseJson.data;

        if (data.Status.IsSuccess) {
            let Data = data.Data;
            handleModalVacate(Data.AttributeSpecification, elementID, "vacate-position-modal", method, event)
        } else {

        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
    }
}

function handleModalVacate(attributes, Row, modalID, method, event) {

    emptyElementContent(document.getElementById(`cont-${modalID}`));
    showModal(document.getElementById(`${modalID}`));
    injectionAdd(attributes, Row, `cont-${modalID}`);
    if (modalID == "vacate-position-modal") {
        $("#btn-vacate-position").unbind();
        $("#btn-vacate-position").on("click", function () {
            groupDataVacatePosition({
                Action: method,
                EmployeePositionID: Row,
                EmployeeID: null,
                DT_StartPosition: null,
                DT_EndPosition: $('#\\[22\\]\\.DT_EndPosition').val(),
            }, modalID, Row);
        })
    }
}

function handleModalAssignPosition(attributes, positionID, modalID, method) {
    document.getElementById("modalTitleMethod").innerHTML = "Assigning Vacate Position";
    emptyElementContent(document.getElementById(`cont-${modalID}`));
    showModal(document.getElementById(`${modalID}`));
    injectionAddNoCondition(attributes, positionID, `cont-${modalID}`, "Manage Employee Positions");
    if (modalID == "edit-position-modal") {
        $("#btn-edit-position").unbind();
        $("#btn-edit-position").on("click", function () {
            groupDataAssignNewPosition({
                Action: "Assign New Position",
                EmployeePositionID: positionID,
                PositionID: null,
                DT_StartPosition: $('#\\[22\\]\\.DT_StartPosition').val(),
                DT_EndPosition: $('#\\[22\\]\\.DT_EndPosition').val(),
                Page: "Position Information",
                ReferenceEmployeePositionID: document.getElementById("[22].ParentEmployeePositionID").getAttribute("employeepositionid"),
                Page: "Position Information"
               
            }, modalID, positionID);
        })
    }
}

function handleModalMovePosition(attributes, positionID, modalID, method) {
    document.getElementById("modalTitleMethod").innerHTML = "Move Position";
    emptyElementContent(document.getElementById(`cont-${modalID}`));
    showModal(document.getElementById(`${modalID}`));
    injectionAddNoCondition(attributes, positionID, `cont-${modalID}`, "Manage Employee Positions");
    if (modalID == "edit-position-modal") {
        $("#btn-edit-position").unbind();
        $("#btn-edit-position").on("click", function () {
            groupDataMoveNewPosition({
                Action: "Move Position - Position Title",
                EmployeePositionID: positionID,
                PositionID: $('#\\[22\\]\\.PositionID').val(),
                DT_StartPosition: $('#\\[22\\]\\.DT_StartPosition').val(),
                DT_EndPosition: $('#\\[22\\]\\.DT_EndPosition').val(),
            }, modalID, positionID, $('#\\[22\\]\\.PositionID option:selected').text());
        })
    }
}

function handleModalAssignHierarchicalManager(attributes, positionID, modalID, method) {
    document.getElementById("modalTitleMethod").innerHTML = "Assigning Vacate Position";
    emptyElementContent(document.getElementById(`cont-${modalID}`));
    showModal(document.getElementById(`${modalID}`));
    injectionAddNoCondition(attributes, positionID, `cont-${modalID}`, "Manage Employee Positions");

    if (modalID == "edit-position-modal") {
        $("#btn-edit-position").unbind();
        $("#btn-edit-position").on("click", function () {
            groupDataAssignNewHierarchicalManager({
                Action: "Assign New Hierarchical Manager",
                EmployeePositionID: positionID,
                ParentEmployeePositionID: null,
                DT_StartPosition: $('#\\[22\\]\\.DT_StartPosition').val(),
                DT_EndPosition: $('#\\[22\\]\\.DT_EndPosition').val(),
                Page: "Position Information",
                ReferenceEmployeePositionID: document.getElementById("[22].ParentEmployeePositionID").getAttribute("employeepositionid")
            }, modalID, positionID, document.getElementById("[22].ParentEmployeePositionID").value);
        })
    }
}

function handleModalAssignMoveHierarchicalManager(attributes, positionID, modalID, method) {
    document.getElementById("modalTitleMethod").innerHTML = "Move Hierarchical Manager";
    emptyElementContent(document.getElementById(`cont-${modalID}`));
    showModal(document.getElementById(`${modalID}`));
    injectionAddNoCondition(attributes, positionID, `cont-${modalID}`, "Manage Employee Positions");

    if (modalID == "edit-position-modal") {
        $("#btn-edit-position").unbind();
        $("#btn-edit-position").on("click", function () {
            groupDataAssignMoveNewHierarchicalManager({
                Action: "Move Position - Hierarchical Manager",
                EmployeePositionID: positionID,
                ParentEmployeePositionID: document.getElementById("[22].ParentEmployeePositionID").getAttribute("employeepositionid"),
                DT_StartPosition: $('#\\[22\\]\\.DT_StartPosition').val(),
                DT_EndPosition: $('#\\[22\\]\\.DT_EndPosition').val(),
                ReferenceEmployeePositionID: document.getElementById("[22].ParentEmployeePositionID").getAttribute("employeepositionid")
            }, modalID, positionID, document.getElementById("[22].ParentEmployeePositionID").value);
        })
    }
}

function injectionAdd(objData, Row, modalID) {
    objData.map((object) => {
        try {
            if (object.IsMandatory && !(object.AttributeName.split("].")[1] == "EmployeeID")) {
                GeneralFunctions.createUserDefinedInputs(object, null, document.getElementById(`${modalID}`));
            }
        } catch (ex) {
            console.log(ex);
        }
    });
}

function injectionAddNoCondition(objData, Row, modalID, pageLocation) {
    objData.map((object) => {
        
        try {

            GeneralFunctions.createUserDefinedInputs(object, null, document.getElementById(`${modalID}`), pageLocation);

        } catch (ex) {
            console.log(ex);
        }
    });
}

function updateEmployeePositions(params, modalID) {
    let requestjson = {
        Module: "employee_position_crud",
        Parameters: {
            ...params
        }
    };

    newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source(),
        config = {
            CancelToken: newSource,
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    config = GeneralFunctions.genericQuery(config);
    newSource = config.CancelToken;

    function responseSuccessCallback(responseJson) {
        let data = responseJson.data;

        if (data.Status.IsSuccess) {
            let Data = data.Data;
            hideModal(document.getElementById(modalID));
            

        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
    }
}

function onClickGroupDataAssignPosition(event) {
    const generalFunction = event.data.generalFunction;

    generalFunction.initConfirmationModal({
        show: true,
        title: 'Assign Position',
        content: 'Are you sure in assigning new position?',
        textPos: 'Yes',
        textNeg: 'No',
        btnPosCallback: () => {
            generalFunction.initConfirmationModal({
                show: false
            });
            getEmployeePositionAttributesAssignNewPosition(event.data.object.GroupData.ElementID, "Add New Position", event);
        }
    });
}

function groupDataAssignNewPosition(params, modalID, positionID, newValue) {
    waitingDialog.show("Assign New Position", { dialogSize: 'sm', progressType: 'danger' });

    let requestjson = {
        Module: "employee_position_crud",
        Parameters: {
            ...params
        }
    };
    let self = this,
        newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source(),
        config = {
            CancelToken: newSource,
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    config = GeneralFunctions.genericQuery(config);
    newSource = config.CancelToken;

    function responseSuccessCallback(responseJson) {
        let data = responseJson.data;

        if (data.Status.IsSuccess) {
            hideModal(document.getElementById(modalID));
            document.getElementById("_group_[22].DT_StartPosition_" + positionID).value = document.getElementById("[22].DT_StartPosition").value;
            document.getElementById("_group_[22].DT_EndPosition_" + positionID).value = document.getElementById("[22].DT_EndPosition").value;
            document.getElementById("_group_[22].PositionID_" + positionID).value = document.getElementById("[22].ParentEmployeePositionID").getAttribute("vacantposition");
            document.getElementById("_group_[22].ParentEmployeePosition_" + positionID).value = document.getElementById("[22].ParentEmployeePositionID").value;

            mainClass.reloadPage();

            waitingDialog.hide();
        }
        else {
            waitingDialog.hide();
            Swal2.fire({
                title: "Error",
                text: data.Status.Message,
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#d33',
                cancelButtonText: "Ok"
            })

        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
        waitingDialog.hide();
    }
}

function groupDataMoveNewPosition(params, modalID, positionID, newValue) {
    waitingDialog.show("Moving New Position", { dialogSize: 'sm', progressType: 'danger' });

    let requestjson = {
        Module: "employee_position_crud",
        Parameters: {
            ...params
        }
    };
    let self = this,
        newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source(),
        config = {
            CancelToken: newSource,
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    config = GeneralFunctions.genericQuery(config);
    newSource = config.CancelToken;

    function responseSuccessCallback(responseJson) {
        let data = responseJson.data;

        if (data.Status.IsSuccess) {
            hideModal(document.getElementById(modalID));
            document.getElementById("_group_[22].PositionID_" + positionID).value = newValue;
            document.getElementById("_group_[22].DT_StartPosition_" + positionID).value = document.getElementById("[22].DT_StartPosition").value;
            document.getElementById("_group_[22].DT_EndPosition_" + positionID).value = document.getElementById("[22].DT_EndPosition").value;

            waitingDialog.hide();
        }
        else {
            waitingDialog.hide();
            Swal2.fire({
                title: "Error",
                text: data.Status.Message,
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#d33',
                cancelButtonText: "Ok"
            })

        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
        waitingDialog.hide();
    }
}

function groupDataAssignNewHierarchicalManager(params, modalID, positionID, newValue) {
    waitingDialog.show("Assign New Hierarchical Manager", { dialogSize: 'sm', progressType: 'danger' });

    let requestjson = {
        Module: "employee_position_crud",
        Parameters: {
            ...params
        }
    };
    let self = this,
        newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source(),
        config = {
            CancelToken: newSource,
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    config = GeneralFunctions.genericQuery(config);
    newSource = config.CancelToken;

    function responseSuccessCallback(responseJson) {
        let data = responseJson.data;

        if (data.Status.IsSuccess) {
            hideModal(document.getElementById(modalID));
            document.getElementById("_group_[22].ParentEmployeePosition_" + positionID).value = newValue;
            document.getElementById("_group_[22].DT_StartPosition_" + positionID).value = document.getElementById("[22].DT_StartPosition").value;
            document.getElementById("_group_[22].DT_EndPosition_" + positionID).value = document.getElementById("[22].DT_EndPosition").value;
            document.getElementById("_group_[22].PositionID_" + positionID).value = data.Data[0].PositionTitle;

            waitingDialog.hide();
            mainClass.reloadPage();
        }
        else {
            Swal2.fire({
                title: "Error",
                text: data.Status.Message,
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#d33',
                cancelButtonText: "Ok"
            })
            waitingDialog.hide();
        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
        if (responseJson.message != undefined && responseJson.message != "Cancel request because another request will be made.") {
            waitingDialog.hide();
        }
        else {
            waitingDialog.hide();
        }
    }
}

function groupDataAssignMoveNewHierarchicalManager(params, modalID, positionID, newValue) {
    waitingDialog.show("Moving New Hierarchical Manager", { dialogSize: 'sm', progressType: 'danger' });

    let requestjson = {
        Module: "employee_position_crud",
        Parameters: {
            ...params
        }
    };
    let self = this,
        newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source(),
        config = {
            CancelToken: newSource,
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    config = GeneralFunctions.genericQuery(config);
    newSource = config.CancelToken;

    function responseSuccessCallback(responseJson) {
        let data = responseJson.data;

        if (data.Status.IsSuccess) {
            hideModal(document.getElementById(modalID));
            document.getElementById("_group_[22].ParentEmployeePosition_" + positionID).value = newValue;
            document.getElementById("_group_[22].DT_StartPosition_" + positionID).value = document.getElementById("[22].DT_StartPosition").value;
            document.getElementById("_group_[22].DT_EndPosition_" + positionID).value = document.getElementById("[22].DT_EndPosition").value;
            document.getElementById("_group_[22].PositionID_" + positionID).value = data.Data[0].PositionTitle;
            waitingDialog.hide();
            mainClass.reloadPage();
        }
        else {
            Swal2.fire({
                title: "Error",
                text: data.Status.Message,
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#d33',
                cancelButtonText: "Ok"
            })
            waitingDialog.hide();
        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
        if (responseJson.message != undefined && responseJson.message != "Cancel request because another request will be made.") {
            waitingDialog.hide();
        }
        else {
            waitingDialog.hide();
        }
    }
}

function groupDataVacatePosition(params, modalID, oldPosition) {
    let currentDate = moment(new Date(), Auth.getDateFormat()),
        givenDate = moment(params.DT_EndPosition, Auth.getDateFormat());

    waitingDialog.show('Vacating position...', { dialogSize: 'sm', progressType: 'danger' });
    let requestjson = {
        Module: "employee_position_crud",
        Parameters: {
            ...params
        }
    };
    let self = this,
        newCancelToken = axios.CancelToken,
        newSource = newCancelToken.source(), 
        config = {
            CancelToken: newSource,
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    config = GeneralFunctions.genericQuery(config);
    newSource = config.CancelToken;

    function responseSuccessCallback(responseJson) {
        let data = responseJson.data;

        if (data.Status.IsSuccess) {
            let Data = data.Data;
            hideModal(document.getElementById(modalID));
            if(currentDate > givenDate)
            {
                $("#_groupTemplate_1_"+oldPosition).remove();
            }
            else
            {
                Swal2.fire({
                    title: "Success",
                    text: Data.Success.Message,
                    icon: 'success',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Ok"
                })
                document.getElementById("_group_[22].DT_EndPosition_"+params.EmployeePositionID).value = givenDate._i;
                mainClass.reloadPage()
            }
            waitingDialog.hide();
        }
        else
        {
            waitingDialog.hide();
            Swal2.fire({
                title: "Error",
                text: data.Status.Message,
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#d33',
                cancelButtonText: "Ok"
            })
        }
    }

    function responseFailCallback(responseJson) {
        console.log("responseFailCallback", responseJson);
        if (responseJson.message != undefined && responseJson.message != "Cancel request because another request will be made.") {
            waitingDialog.hide();
        }
        else
        {
            waitingDialog.hide();
        }
    }
}

function groupDataRemovePosition(event) {
    const object = event.data.object,
        generalFunction = event.data.generalFunction,
        mainClass = event.data.mainClass,
        RID = object.RID,
        requestjson = {
            Module: "employee_position_remove",
            Parameters: { RID }
        };
    let formData = new FormData(),
        config = {
            Data: { requestjson: requestjson },
            Method: "POST",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

    formData.append('requestjson', JSON.stringify(requestjson));
    config.Data.requestjson = formData;

    waitingDialog.show('Removing position...', { dialogSize: 'sm', progressType: 'danger' });
    generalFunction.genericQuery(config);

    function responseSuccessCallback(responseJson) {
        const data = responseJson.data;
        waitingDialog.hide();

        if (data.Status.IsSuccess) {
            const _data = data.Data,
                message = _data.Message;

            if (message) {
                Swal2.fire({
                    title: "Information",
                    text: message,
                    icon: 'info',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                    cancelButtonText: "Ok"
                })


                if (message.includes('[131]')) {
                    let employeePosition = objectData.find(obj => {
                        let instance = obj['EmployeePosition'];

                        return instance ? instance : false;
                    }), newEmployeePosition;

                    newEmployeePosition = employeePosition.EmployeePosition.filter(obj => {
                        let attributes = obj.Attributes;

                        return attributes.find(attribute => {
                            if (attribute.DBObjectAttributeID == 169) {
                                return attribute.RID != RID;
                            }
                        }) != null;
                    });

                    $(`#${groupDataContainers[0]}`).empty();


                    Object.keys(_data).forEach(key => {
                        generalFunction.modifyObjectDataArrayElement({
                            key,
                            data: _data[key]
                        });
                    });

                    mainClass.updateOnDemand({ data: objectData });
                }
            }
        } else {
            Swal2.fire({
                title: "Information",
                text: data.Status.Message,
                icon: 'info',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#d33',
                cancelButtonText: "Ok"
            })

        }
    }

    function responseFailCallback(responseJson) {
        waitingDialog.hide();
        alert(responseJson.message);
    }
}



// END GROUP DATA FUNCTIONS

export default GeneralFunctions;