var element = null,
    subelement = null;

const removeMenuStyle = (className) => {
    element.classList.remove(className);
}

const addMenuStyle = (className) => {
    element = document.querySelector(className);
    element.classList.add('is-active');
}

const removeSubMenuStyle = (className) => {
    var elem = document.querySelector(`.${className}`);
   
    if( elem !== null){
        elem.classList.remove(className);
    }
}

const addSubMenuStyle = (className, url) => {
    subelement = document.getElementById(className);
            
    var lists = subelement.getElementsByTagName('li'),
        atags = subelement.getElementsByTagName('a'),
        i  = subelement.childNodes.length;

    while(i--){
        
        if(atags[i].pathname === url){
            
            lists[i].classList.add(`${className}-active`);
        }
    }
}

const addSubMenuNested = (className, url) => {
    subelement = document.getElementById(className)
            
    var elements = subelement.getElementsByClassName('have-children'),
        length = elements.length;
    
    while(length--){
        var x = elements[length].querySelectorAll('ul')
        x.forEach( xy => {
            var i = xy.childNodes.length
            
            while( i-- ){
                var a = xy.children[i].querySelector('a')
                if(a.pathname === url){
                    
                    elements[length].classList.add(`${className}-active`);
                }
                
            }
            return;
        })
    }
}

const addSubMenuSettings = (url) => {
    subelement = document.querySelectorAll('#systemadministration-menu>li');
            
    var subelements = Array.from(subelement),
        list = [],
        i  = subelements.length;

    while(i--){

        if(!subelements[i].classList.contains('have-children')){
            list.push(subelements[i])
        }
        
    }

    var len = list.length;

    while( len-- ){
        var elem = list[len].querySelector('a')

        if(elem.pathname === url){
            list[len].classList.add('systemadministration-menu-active');
        }
        
    }
}

export const addSidebarStyle = () => {
        
    var url = window.location.href;

    try
    {
        // start personaldata-menu
        // if(url.indexOf("employee-list") > -1 && url.indexOf("?") === -1 && url.indexOf("employee-management") === -1){
        //     if(element !== null){
        //         removeMenuStyle('is-active')
        //     }

        //     addMenuStyle('.sl-icon-user');
            
        //     if(subelement !== null){
        //         removeSubMenuStyle('personaldata-menu-active');
        //         removeSubMenuStyle('organisation-menu-active');
        //         removeSubMenuStyle('performancemanagement-menu-active');
        //         removeSubMenuStyle('talentdevelopment-menu-active');
        //         removeSubMenuStyle('email-menu-active');
        //         removeSubMenuStyle('settings-menu-active');
        //         removeSubMenuStyle('cms-menu-active');
        //         removeSubMenuStyle('systemadministration-menu-active');
        //         removeSubMenuStyle('bonusadministration-menu-active');
        //         removeSubMenuStyle('dataadministration-menu-active');
        //     }

        //     subelement = document.getElementById('personaldata-menu');
            
        //     var lists = subelement.getElementsByTagName('li')
        //     var atags = subelement.getElementsByTagName('a')
        //     var i  = subelement.childNodes.length;
            
        //     while(i--){
                
        //         if(atags[i].pathname.indexOf("employee-list") > -1){
                    
        //             lists[i].classList.add('personaldata-menu-active');
        //         }
        //     }
        // }
        
        if(window.location.pathname ==="/self-talent-assessment-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-user');

            if(subelement !== null){
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
            }

            var className = 'personaldata-menu',
                pathname = '/self-talent-assessment-list';

            addSubMenuStyle(className, pathname);
        }

        if(url.indexOf("self-competency") === 22){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-user');

            if(subelement !== null){
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
            }

            var className = 'personaldata-menu',
                pathname = '/self-competency-assessment';

            addSubMenuStyle(className, pathname);
        }
        // end personaldata-menu

        // start talentdevelopment-menu
        if(url.indexOf("talent-assessment-list") === 22){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-badge');

            if(subelement !== null){
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
            }

            var className = 'talentdevelopment-menu',
                pathname = '/talent-assessment-list';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === '/competency-assessment'){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-badge');

            if(subelement !== null){
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
            }
            
            var className = 'talentdevelopment-menu',
                pathname = '/competency-assessment';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/succession-planning"){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-badge');

            if(subelement !== null){
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
            }

            var className = 'talentdevelopment-menu',
                pathname = '/succession-planning';

            addSubMenuStyle(className, pathname);
        }

        if(url.indexOf("manage-employee") > -1){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-badge');

            if(subelement !== null){
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
            }

            var className = 'talentdevelopment-menu',
                pathname = '/manage-employee-talent-assessment';

            addSubMenuStyle(className, pathname);
        }
        // end talentdevelopment-menu

        // start performancemanagement-menu
        if(url.indexOf("plan-personal") > -1){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-graph');

            if(subelement !== null){
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
            }

            var className = 'performancemanagement-menu',
                pathname = '/plan-personal';

            addSubMenuStyle(className, pathname);
        }

        if(url.indexOf("plan-list") > -1){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-graph');

            if(subelement !== null){
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
            }

            var className = 'performancemanagement-menu',
                pathname = '/plan-list';

            addSubMenuStyle(className, pathname);
        }

        if(url.indexOf("plan-functional-manager") > -1){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-graph');

            if(subelement !== null){
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
            }

            var className = 'performancemanagement-menu',
                pathname = '/plan-functional-manager';

            addSubMenuStyle(className, pathname);
        }

        if(url.indexOf("employee-phase-management") > -1){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-graph');

            if(subelement !== null){
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
            }

            var className = 'performancemanagement-menu',
                pathname = '/employee-phase-management';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/manage-employee-performance"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-graph');

            if(subelement !== null){
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
            }

            var className = 'performancemanagement-menu',
                pathname = '/manage-employee-performance';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/my-team-bonus"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-graph');

            if(subelement !== null){
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
            }

            var className = 'performancemanagement-menu',
                pathname = '/my-team-bonus';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/employee-bonus-overview-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-graph');

            if(subelement !== null){
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
            }

            var className = 'performancemanagement-menu',
                pathname = '/employee-bonus-overview-list';

            addSubMenuStyle(className, pathname);
        }
        // end performancemanagement-menu

        if(window.location.pathname === "/"){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            addMenuStyle('.sl-icon-home');
        }

        // start organisation-menu
        if(window.location.pathname === "/my-team"){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-chart');

            if(subelement !== null){
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'organisation-menu',
                pathname = '/my-team';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/organisation-chart"){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-chart');

            if(subelement !== null){
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'organisation-menu',
                pathname = '/organisation-chart';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/report-dashboard"){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-chart');

            if(subelement !== null){
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'organisation-menu',
                pathname = '/report-dashboard';

            addSubMenuStyle(className, pathname);
        }
        // end organisation-menu

        // start bonusadministration-menu
        if(window.location.pathname === "/bonus-approval-workflow"){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-credit-card');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'bonusadministration-menu',
                pathname = '/bonus-approval-workflow';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/bonus-workflow-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-credit-card');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'bonusadministration-menu',
                pathname = '/bonus-workflow-list';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/bonus-workflow-state-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-credit-card');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'bonusadministration-menu',
                pathname = '/bonus-workflow-state-list';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/bonus-workflow-state-role-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-credit-card');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'bonusadministration-menu',
                pathname = '/bonus-workflow-state-role-list';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/bonus-workflow-state-transition-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }

            addMenuStyle('.sl-icon-credit-card');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'bonusadministration-menu',
                pathname = '/bonus-workflow-state-transition-list';

            addSubMenuStyle(className, pathname);
        }
        // end bonusadministration-menu

        // start dataadministration-menu
        if(window.location.pathname === "/employee-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-people');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'dataadministration-menu',
                pathname = '/employee-list';

            addSubMenuStyle(className, pathname);
        }

        // if(url.indexOf("bonusPlanID") > -1){
        //     if(element !== null){
        //         removeMenuStyle('is-active');
        //     }

        //     addMenuStyle('.sl-icon-people');
        // }

        if(window.location.pathname === "/position-hierarchy-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-people');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'dataadministration-menu',
                pathname = '/position-hierarchy-list';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/position-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-people');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'dataadministration-menu',
                pathname = '/position-list';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/functional-manager-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-people');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'dataadministration-menu',
                pathname = '/functional-manager-list';

            addSubMenuStyle(className, pathname);
        }
        // end dataadministration-menu

        // start systemadministration-menu
        if(window.location.pathname === "/competency-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/competency-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/competency-type-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/competency-type-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/data-management-access-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/data-management-access-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/location-logon-management"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/location-logon-management';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/permission-management"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/permission-management';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/application-function-management"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/application-function-management';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/plan-management"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/plan-management';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/active-plan-management"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/active-plan-management';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/company-value-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/company-value-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/objective-type-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/objective-type-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/objective-weight-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/objective-weight-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/performance-rating-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/performance-rating-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/talent-plan-management"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/talent-plan-management';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/bonus-plan-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/bonus-plan-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/bonus-objectives"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/bonus-objectives';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/bonus-rules-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/bonus-rules-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/bonus-performance-multiplier-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/bonus-performance-multiplier-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/employment-history-reason-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/employment-history-reason-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/document-type-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }
            
            const pathname = '/document-type-list';
            addSubMenuSettings(pathname);
        }

        if(window.location.pathname === "/system-default-settings-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }
            
            const pathname = '/system-default-settings-list';
            addSubMenuSettings(pathname);
        }

        if(window.location.pathname === "/sso-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }
            
            const pathname = '/sso-list';
            addSubMenuSettings(pathname);
        }

        if(window.location.pathname === "/location-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');
            
            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/location-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/organizational-unit-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/organizational-unit-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/employee-discipline-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/employee-discipline-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/currency-management-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/currency-management-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/employee-classification-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/employee-classification-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/position-type-management-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/position-type-management-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/succession-term-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/succession-term-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/legal-entity-management-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/legal-entity-management-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/tag-management"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/tag-management';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/position-category-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/position-category-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/organisation-unit-type-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/organisation-unit-type-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/line-of-business-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/line-of-business-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/performance-type-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/performance-type-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/performance-type-explanation-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/performance-type-explanation-list';

            addSubMenuNested(className, pathname);
        }

        if(window.location.pathname === "/kpi-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-wrench');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'systemadministration-menu',
                pathname = '/kpi-list';

            addSubMenuNested(className, pathname);
        }
        // end systemadministration-menu

        // start email-menu
        if(window.location.pathname === "/send-reminder"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-envelope-open');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'email-menu',
                pathname = '/send-reminder';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/email-template-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-envelope-open');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'email-menu',
                pathname = '/email-template-list';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/email-type-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-envelope-open');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'email-menu',
                pathname = '/email-type-list';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/email-schedule-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-envelope-open');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'email-menu',
                pathname = '/email-schedule-list';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/email-settings"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-envelope-open');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'email-menu',
                pathname = '/email-settings';

            addSubMenuStyle(className, pathname);
        }
        // end email-menu

        if(window.location.pathname === "/ip-control-management-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-globe');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }
        }

        // start settings-menu
        if(window.location.pathname === "/faq"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-settings');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'settings-menu',
                pathname = '/faq';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/change-password"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-settings');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'settings-menu',
                pathname = '/change-password';

            addSubMenuStyle(className, pathname);
        }

        if(window.location.pathname === "/user-application-settings-list"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-settings');
            
            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu-active');
            }

            var className = 'settings-menu',
                pathname = '/user-application-settings-list';

            addSubMenuStyle(className, pathname);
        }
        // end settings-menu

        // start cms-menu
        if(window.location.pathname === "/label-management"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-book-open');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('systemadministration-menu');
            }

            subelement = document.getElementById('cms-menu');
            var parentList = subelement.getElementsByClassName('have-children');
            parentList[0].classList.add('cms-menu-active');
        }

        if(window.location.pathname === "/default-label-management"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-book-open');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('settings-menu-active');
            }

            subelement = document.getElementById('cms-menu');
            var parentList = subelement.getElementsByClassName('have-children');
            parentList[0].classList.add('cms-menu-active');
        }

        if(window.location.pathname === "/language-management"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-book-open');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('settings-menu-active');
            }

            subelement = document.getElementById('cms-menu');
            var parentList = subelement.getElementsByClassName('have-children');
            parentList[0].classList.add('cms-menu-active');
        }

        if(window.location.pathname === "/translation"){
            if(element !== null){
                removeMenuStyle('is-active');
            }
            
            addMenuStyle('.sl-icon-book-open');

            if(subelement !== null){
                removeSubMenuStyle('organisation-menu-active');
                removeSubMenuStyle('performancemanagement-menu-active');
                removeSubMenuStyle('talentdevelopment-menu-active');
                removeSubMenuStyle('personaldata-menu-active');
                removeSubMenuStyle('bonusadministration-menu-active');
                removeSubMenuStyle('dataadministration-menu-active');
                removeSubMenuStyle('email-menu-active');
                removeSubMenuStyle('settings-menu-active');
                removeSubMenuStyle('cms-menu-active');
                removeSubMenuStyle('settings-menu-active');
            }

            subelement = document.getElementById('cms-menu');
            var parentList = subelement.getElementsByClassName('have-children');
            parentList[0].classList.add('cms-menu-active');
        }
        // end cms-menu
    } 
    catch(e)
    {
        console.log(e)
    }

    return;
}

export default { addSidebarStyle };