import React from 'react'
import { Editor } from 'react-draft-wysiwyg';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';

import GeneralFunctions from '../../../functions/general_function1';
import backgroundImage from '../../../assets/images/home-background.png';
import TranslateModal from '../translationModalComponent';
import { addSidebarStyle }  from '../../../functions/funcs';



const style = {
    warningSign: {
        width: "350px",
        height: "350px",
        backgroundImage: `url(${API_URL_ROOT_FS}/media/content/home/hangingboard.png)`,
        backgroundSize: 'cover'
    }
}

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            systemMessage: [],
            message: null
        };
    }

    renderCoreValues() {
        const txts = texts.map((text, id) => {
            return (
                <div key={id}>
                    <p><strong>{text.title}</strong></p>
                    <p>{text.content}</p>
                </div>
            );
        });
        return txts;
    }

    toastError = (msg) => {
        toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
        });
    }

    loadPage() {
        var self = this;
        
        axios.get((API_URL_CMS) + '/home/', {
        }).then(function (response) {
            if (response.status === 200) {
                self.setState({ data: response.data.home });
                    
            }
        }).catch(function (thrown) {
            console.log("error", response)
            // self.toastError(thrown);
        });

    }

    maintenanceCheck = () => {
        let self = this;
        let requestjson = {
            Module: "system_maintenance_check",
            Parameters: {
            }
        }, config = {
            Data: { requestjson: requestjson },
            Method: "GET",
            ResponseSuccessCallback: responseSuccessCallback,
            ResponseFailCallback: responseFailCallback,
            Url: "/single_api/"
        };

        GeneralFunctions.genericQuery(config);

        function responseSuccessCallback(responseJson) {

            let data = responseJson.data;

            if (data.Status.IsSuccess) {
                self.setState({ 
                    systemMessage: data.Data.MaintenanceCheck,
                    message: data.Data.MaintenanceCheck.length == 0 ? "" : data.Data.MaintenanceCheck[0].Message,
                })
            }
        }

        function responseFailCallback(responseJson) {
            console.log(responseJson);
            self.toastError(responseJson.message);
        }
    }

    renderFunc() {
        var home = this.state.data;
        return (
            home.map(function (obj) {
                return (
                    <div className="row home-row-margin" key={obj.id}>
                        <div className="col-md-offset-2 col-md-2">
                            <img src={API_URL_ROOT_FS + obj.imageurl} className="home-panel-header-img" id={"image" + obj.id} />
                        </div>
                        <div className="col-md-7">
                            <div id={"editable" + obj.id} >

                                <Editor
                                    readOnly
                                    toolbarClassName="demo-toolbar-absolute"
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    defaultContentState={JSON.parse(obj.text)}
                                    toolbarOnFocus
                                    toolbar={{
                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily'],
                                        inline: {
                                            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                            bold: { className: 'bordered-option-classname' },
                                            italic: { className: 'bordered-option-classname' },
                                            underline: { className: 'bordered-option-classname' },
                                            strikethrough: { className: 'bordered-option-classname' },
                                            code: { className: 'bordered-option-classname' },
                                        },
                                        blockType: {
                                            className: 'bordered-option-classname',
                                        },
                                        fontSize: {
                                            className: 'bordered-option-classname',
                                        },
                                        fontFamily: {
                                            className: 'bordered-option-classname',
                                        },
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                )
            })
        )
    }

    componentDidMount()
    {
        addSidebarStyle();
        this.loadPage();
        this.maintenanceCheck();
    }

    componentDidUpdate(prevProps, prevState)
    {
        this.props.toggleLabelReducer.labelToggle 
        ? GeneralFunctions.dynamicLabelOnclick() 
        : GeneralFunctions.noFunctionLabelOnclick();
    }

    render() {
        let self = this;
        var blockMessage = <div style={style.warningSign}>
                                <div className="home-block-message--div">
                                    <strong className="home-block-message--strong">Reminder</strong>
                                        <p>{self.state.message}</p>
                                </div>
                            </div>
        
        return (
            <div>
                <div className="page-header home-page-header">
                    <h4>{GeneralFunctions.loopStaticLabel('-1', 'Welcome to the world of HYVA', 3)}</h4>
                </div>
                <div id="home-content" className="page-content home-content" style={{backgroundImage: `url(${backgroundImage})`}}>
                    <div className="row">
                            <div className={this.state.systemMessage.length == 0 ? "col-lg-11": "col-lg-8" }>
                                <div className="">
                                {
                                    this.renderFunc()
                                }
                                </div>
                            </div>
                            <div className={this.state.systemMessage.length == 0 ? "col-lg-0":"col-lg-4"}>
                                {this.state.systemMessage.length == 0 ? "" : blockMessage}
                            </div>

                    </div>
                </div>
                <TranslateModal />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    toggleLabelReducer: state.toggleLabelReducer
})

export default connect(mapStateToProps)(Home);