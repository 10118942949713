class Auth {
  static authenticateChismisGuestUser(token) {
    localStorage.setItem('guestChismisToken', token);
  }

  static deauthenticateChismisGuestUser() {
    localStorage.removeItem('guestChismisToken');
  }

  static isChimismisGuestUserAuthenticated() {
    return localStorage.getItem('guestChismisToken') !== null;
  }

  static getChismisToken() {
    return localStorage.getItem('guestChismisToken');
  }

  static setGuestDetails(detailsJSON) {
    localStorage.setItem('guestDetails', detailsJSON);
  }

  static getGuestDetails() {
    return localStorage.getItem('guestDetails');
  }

  static removeGuestDetails() {
    localStorage.removeItem('guestDetails');
  }

  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static authenticateUser(token) {
    localStorage.setItem('token', token);
  }

  static isUserLoggedIn() {
    return localStorage.getItem('isUserLoggedIn') == "true";
  }

  static setIsUserLoggedIn(isUserLoggedIn) {
    localStorage.setItem('isUserLoggedIn', isUserLoggedIn);
  }


  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static authenticateX(X) {
    localStorage.setItem('X', X);
  }

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated() {
    return localStorage.getItem('token') !== null;
  }

  static removeUserAuthentication() {
    localStorage.removeItem('token');
  }

  /**
   * Check if a user is Successfully Login as Test API - check if True is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserValidationX() {
    return localStorage.getItem("X") !== null;
  }


  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static deauthenticateUser() {
    localStorage.removeItem('token');
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken() {
    return localStorage.getItem('token');
  }

  static setWebsiteTermSigned(websiteTermSigned) {
    localStorage.setItem('websiteTermSigned', "" + websiteTermSigned);
  }

  static isWebsiteTermSigned() {
    return localStorage.getItem('websiteTermSigned') == "true";
  }

  static removeWebsiteTermSigned() {
    localStorage.removeItem('websiteTermSigned');
  }

  static setRID(RID) {
    localStorage.setItem('RID', RID);

  }
  static getRID() {
    return localStorage.getItem('RID');
  }

  static removeRID() {
    localStorage.removeItem('RID');
  }

  static setEmployeeId(employeeId) {
    localStorage.setItem('employeeId', employeeId);
  }
  static getEmployeeId() {
    return localStorage.getItem('employeeId');
  }

  static removeEmployeeId() {
    localStorage.removeItem('employeeId');
  }

  //Start Username
  static setRememberedUsername(rememberedUsername) {
    localStorage.setItem('rememberedUsername', rememberedUsername);
  }
  static getRememberedUsername() {
    return localStorage.getItem('rememberedUsername');
  }

  static removeRememberedUsername() {
    localStorage.removeItem('rememberedUsername');
  }
  //End Username

  //Start UserAccessLevel
  static setUserAccessLevel(userAccessLevel) {
    localStorage.setItem('userAccessLevel', userAccessLevel);
  }

  static getUserAccessLevel() {
    return localStorage.getItem('userAccessLevel');
  }

  static isUserAccessLevelMatches(accessLevel) {
    return ((accessLevel & localStorage.getItem('userAccessLevel')) == accessLevel);
  }

  static removeUserAccessLevel() {
    localStorage.removeItem('userAccessLevel');
  }

  static isUserAccessLevelOnly(accessLevel) {
    let isTrue = false;

    if (accessLevel == 8) {
      isTrue = (parseInt((localStorage.getItem('userAccessLevel'))) - accessLevel == 0);
    } else {
      isTrue = (parseInt((localStorage.getItem('userAccessLevel'))) - accessLevel == 8);
    }

    return isTrue;
  }
  //End UserAccessLevel

  // START SYS MODULE
  static setSysModule(sysModule) {
    localStorage.setItem('sysModule', JSON.stringify(sysModule));
  }

  static getSysModuleByID(sysModuleID) {
    let sysModule = localStorage.getItem('sysModule');

    if (sysModule) {
      sysModule = JSON.parse(sysModule);

      return sysModule.find(_sysModule => {
        return _sysModule.ModuleID == sysModuleID;
      });
    }

    return null;
  }

  static removeSysModule() {
    localStorage.removeItem('sysModule');
  }
  // END SYS MODULE

  // START BUNDLE STORAGE
  static setBundleVersion() {
    let scripts = document.scripts;

    Object.keys(scripts).forEach(key => {
      let script = scripts[key],
        scriptSrc = script.src;

      if (scriptSrc) {
        if (scriptSrc.includes('bundle')) {
          if (scriptSrc.includes('public')) {
            let scriptSrcSplit = scriptSrc.split('/'),
              bundleVersion;

            if (scriptSrcSplit) {
              bundleVersion = `${scriptSrcSplit[4]}`;
            }

            if (bundleVersion) {
              localStorage.setItem('currentBundleVersion', bundleVersion);
            }
          }
        }
      }
    });
  }

  static getBundleVersion() {
    return localStorage.getItem('currentBundleVersion');
  }

  static removeBundleVersion() {
    localStorage.removeItem('currentBundleVersion');
  }
  // END BUNDLE STORAGE

  //Start userHasTeam
  static setUserHasTeam(userHasTeam) {
    localStorage.setItem('userHasTeam', userHasTeam);
  }
  static getUserHasTeam() {
    return localStorage.getItem('userHasTeam') == "true";
  }

  static removeUserHasTeam() {
    localStorage.removeItem('userHasTeam');
  }
  //End userHasTeam

  //Start userName
  static setUserName(userName) {
    localStorage.setItem('userName', userName);
  }
  static getUserName() {
    return localStorage.getItem('userName');
  }
  static removeUserName() {
    localStorage.removeItem('userName');
  }
  //End userName

  //Start MenuAccess
  static setMenuAccess(MenuAccess) {
    localStorage.setItem('MenuAccess', JSON.stringify(MenuAccess));
  }
  static getMenuAccess() {
    return JSON.parse(localStorage.getItem('MenuAccess'));
  }
  static removeMenuAccess() {
    localStorage.removeItem('MenuAccess');
  }

  static MenuAccessRights(id) {
    var MenuAccessVal = JSON.parse(localStorage.getItem('MenuAccess'));
    var Result = 1;
    if (MenuAccessVal != null) {
      Object.keys(MenuAccessVal).map(function (Obj) {
        if (id === MenuAccessVal[Obj].ApplicationFunctionID) {
          Result = 0;
        }
      })
      return Result;
    }
  }

  //START KEN'S MENU SYS FUNCTION
  static setEmployeeMenuAccess(EmployeeMenuAccess) {
    localStorage.setItem('EmployeeMenuAccess', JSON.stringify(EmployeeMenuAccess));
  }

  static getEmployeeMenuAccess() {
    return JSON.parse(localStorage.getItem('EmployeeMenuAccess'));
  }

  static removeEmployeeMenuAccess() {
    localStorage.removeItem('EmployeeMenuAccess');
  }

  static getEmployeeMenuAccessByApplicationFunctionID(applicationFunctionID) {
    let employeeMenuAccess = JSON.parse(localStorage.getItem('EmployeeMenuAccess')),
      returnedEmployeeMenuAccess = null;

    if (employeeMenuAccess) {
      returnedEmployeeMenuAccess = employeeMenuAccess.find(menuAccess => {
        return menuAccess.ApplicationFunctionID == applicationFunctionID ? menuAccess : null;
      });
    }

    return returnedEmployeeMenuAccess;
  }

  static getSysApplicationFunctionFromArray(params) {
    const sysApplicationFunction = params.sysApplicationFunction,
      sysApplicationFunctionID = params.sysApplicationFunctionID;
    let returnedEmployeeMenuAccess = null;

    if (sysApplicationFunction) {
      returnedEmployeeMenuAccess = sysApplicationFunction.find(menuAccess => {
        return menuAccess.ApplicationFunctionID == sysApplicationFunctionID ? menuAccess : null;
      });
    }

    return returnedEmployeeMenuAccess;
  }
  //END MENU SYS FUNCTION

  //End MenuAccess

  //Start module
  static setModule(sysModule) {
    localStorage.setItem('SystemModule', JSON.stringify(sysModule));
  }
  static getModule() {
    return localStorage.getItem('SystemModule');
  }
  static removeModule() {
    localStorage.removeItem('SystemModule');
  }
  //End module

  //Start Login Time
  static setLoginTime(LoginTime) {
    localStorage.setItem('LoginTime', LoginTime);
  }
  static getLoginTime() {
    return localStorage.getItem('LoginTime');
  }
  static removeLoginTime() {
    localStorage.removeItem('LoginTime');
  }
  //End Login Time

  //Start Login Time
  static setSettings(Settings) {
    localStorage.setItem('Settings', Settings);
  }
  static getSettings() {
    return localStorage.getItem('Settings');
  }

  static getSettingsByID(id) {
    var settingbyid = JSON.parse(localStorage.getItem('Settings'));
    if (settingbyid) {
      return settingbyid.find(o => o.ID === id);
    }
    return {};
  }

  static removeSettings() {
    localStorage.removeItem('Settings');
  }

  static setEmpPhoto(photo) {
    localStorage.setItem('photo', photo);
  }
  static getEmpPhoto() {
    return localStorage.getItem('photo');
  }
  static removePhoto() {
    localStorage.removeItem('photo');
  }

  static setLanguage(Language) {
    localStorage.setItem('Language', Language);
  }
  static getLanguage() {
    return localStorage.getItem('Language');
  }
  static removeLanguage() {
    localStorage.removeItem('Language');
  }
  //End Login Time

  static setLogoPhoto(LogoPhoto) {
    localStorage.setItem('LogoPhoto', LogoPhoto);
  }
  static getLogoPhoto() {
    return localStorage.getItem('LogoPhoto');
  }
  static removeLogoPhoto() {
    localStorage.removeItem('LogoPhoto');
  }

  static setJSonOrgchart(Language) {
    localStorage.setItem('JSonOrgchart', Language);
  }
  static getJSonOrgchart() {
    return localStorage.getItem('JSonOrgchart');
  }
  static removeJSonOrgchart() {
    localStorage.removeItem('JSonOrgchart');
  }

  static setLastActiveTime(lastActiveTime) {
    localStorage.setItem('lastActiveTime', lastActiveTime);
  }

  static getLastActiveTime() {
    return localStorage.getItem('lastActiveTime');
  }

  static removeLastActiveTime() {
    localStorage.removeItem('lastActiveTime');
  }

  static removeLanguages() {
    localStorage.removeItem('languageList');
    localStorage.removeItem('languages');
    localStorage.removeItem('activeLanguages');
  }

  static checkLink(path, MenuJSON) {
    return true;
    let accessibleRoutes = [];
    let menuAccess = Auth.getEmployeeMenuAccess();

    let getAllRoutes = (route) => {
      if (route.children.length === 0) {
        if (menuAccess != null) {
          menuAccess.forEach(menu => {
            if (menu.ApplicationFunctionID == route.applicationFunctionID) {
              accessibleRoutes.push(route.link)
            }
          })
        }

      }
      else {
        route.children.map(routeChild => getAllRoutes(routeChild))
      }
    };

    MenuJSON.map(menu => {
      getAllRoutes(menu);
    });

    accessibleRoutes.splice(0, 1);

    let checkLinkAccess = (path) => {
      if (path === "/") {
        return "/"
      }

      return accessibleRoutes.find(route => {
        return path.includes(route);
      })
    }

    return checkLinkAccess(path)
  }

  static setCurrentLanguage(currentLanguage) {
    localStorage.setItem('currentLanguage', currentLanguage);
  }

  static getCurrentLanguage() {
    let currentLanguage = localStorage.getItem('currentLanguage'),
      language = this.getLanguage();

    if (currentLanguage) {
      currentLanguage = currentLanguage.replace(/\\"/g, '"');
      currentLanguage = JSON.parse(currentLanguage);

      if (language == null) {
        let twoLetterCode = currentLanguage.TwoLetterCode;

        this.setLanguage(twoLetterCode.toUpperCase());
      }

      return currentLanguage;
    }

    return null;
  }

  static removeCurrentLanguage() {
    localStorage.removeItem('currentLanguage');
  }

  static setDateFormat(dateFormat) {
    localStorage.setItem('dateFormat', dateFormat)
  }

  static getDateFormat(){
    return localStorage.getItem('dateFormat')
  }

  static setDelimiterSettings({DecimalSeparator = ",", DigitGroupSeparator = ".", Precision = 2}){
    localStorage.setItem("decimalSeparator", DecimalSeparator)
    localStorage.setItem("digitGroupSeparator", DigitGroupSeparator)
    localStorage.setItem("precision", Precision)
  }

  static getPrecision(){
    return localStorage.getItem("precision")
  }

  static getDecimalSeparator(){
    return localStorage.getItem("decimalSeparator")
  }

  static getDigitGroupSeparator(){
    return localStorage.getItem("digitGroupSeparator")
  }

  static setStaticLabels(staticLabels){
    localStorage.setItem("staticLabels", staticLabels);
  }

  static setLanguageID(languageID){
    localStorage.setItem("currentLanguageID", languageID);
  }

  static getLanguageID(){
    return localStorage.getItem("currentLanguageID");
  }

  static setIsSysAdmin(isSysAdmin) {
    localStorage.setItem("isSysAdmin", isSysAdmin);
  }

  static getIsSysAdmin() {
    return localStorage.getItem("isSysAdmin");
  }
}

export default Auth;