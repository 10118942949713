import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { browserHistory } from 'react-router';

import Auth from '../../../functions/auth';
import GeneralFunctions from '../../../functions/general_function1';
import auth from '../../../utils/auth';
import { toast } from 'react-toastify';

import './index.scss';


let checkUserCancelToken = axios.CancelToken.source();


export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            isLoggingIn: false,
            isCaptchaOn: false,
            isDisable: false,
            isMessageHidden: true,
            message: "",
            logo: "",
            pathname: props.location.pathname
        };
    }

    GlobalSettings() {

        axios.get((API_URL) + '/system_default_settings_proc/', {
            params: {
                PageSize: 999,
                PageNum: 1,
                SortField: "ID",
            },
            headers: {
                'token': Auth.getToken()
            },
        }).then((res) => {
            
            Auth.setSettings(JSON.stringify(res.data[0].SystemDefaultsettings));

        }).catch(function (thrown) {
            console.log(thrown);
        });

    }

    loadPage() {

        fetch(API_URL_CMS + '/logo_header/', { 
            method: 'GET'
        }).then(
            response => response.json() 
        ).then(
            success => {
                this.setState({
                    logo: success
                });
            }
        ).catch( error => { 
            console.log('error', error);
        });
    }

    componentDidMount() {
        this.loadPage();
        this.GlobalSettings();
        if (this.state.isCaptchaOn) initGenerateCaptcha();
    }

    onClickRefresh(event) {
        event.preventDefault();
        initGenerateCaptcha();
    }

    toastError = (msg) => {
        toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
        });
    }

    toastSuccess = (msg) => {
        toast.success(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
        });
    }

    onClickVerify(event) {
        event.preventDefault();

        if (ValidCaptcha()) {
            this.setState({ isDisable: false });
            $('#captchaRefresh').attr('disabled', true);
            $('#captchaVerify').attr('disabled', true);
            // alert('Verified!');
            this.toastSuccess('Verified!');
        }
    }

    onClickSubmit(event) {
        event.preventDefault();
        let data = null;
        let self = this;
        const username = (this.refs.username.value).trim();
        const password = (this.refs.password.value).trim();
        const isRememberMeChecked = this.refs.cbRememberMe.checked;

        if (username == "" || password == "") {
            this.changeStateOfMessage("Both fields are required", false);
        } else {
            this.setState({ isLoggingIn: true, isDisable: true });

            $.ajax({
                type: 'POST',
                url: (API_URL) + API_LOGIN,
                data: {
                    LogonID: username,
                    Password: password
                },
            }).then(function (response) {
                
                if (response["token"] !== undefined) {
                    var message = response.jsonAccess.logonControl[0].message;
                    if (message != null && message != "") {
                        self.changeStateOfMessage(message, false);
                    } else {
                        var now = new Date(),
                            logonControl = response.jsonAccess.logonControl[0],
                            sysApplicationFunction = response.jsonSysFunction.Sys_ApplicationFunction,
                            userAccessLevels = response.jsonSysFunction.userAccessLevels,
                            sysModule = response.SysModule.SysModule,
                            dateFormat = response.SystemSettings.DateFormat;

                        Auth.setStaticLabels(JSON.stringify(response.StaticLabels.StaticLabels));
                        Auth.setLanguageID(logonControl.defaultLanguageID)
                        Auth.setIsSysAdmin(logonControl.IsSysAdmin)


                        let delimiterSettings = JSON.parse(logonControl.DelimeterSettings).DelimeterSettings
                        
                        let userLanguage = logonControl.UserLanguage,
                            defaultLanguage = logonControl.DefaultLanguage,
                            getDefaultLanguage = false;

                        Auth.setLoginTime(now); // Sets the login time to current date
                        Auth.setEmployeeMenuAccess(sysApplicationFunction); // Sets the menus in the system
                        Auth.authenticateUser(response.token); // Verification if the user is logged in
                        Auth.setRID(logonControl.RID); // Sets the token for every SP call
                        Auth.setEmployeeId(logonControl.EmployeeID); // Sets the employee ID for the logged in user
                        Auth.setWebsiteTermSigned(logonControl.webSiteTermsSigned); // Sets the website term signed
                        Auth.setUserAccessLevel(logonControl.userAccessLevel); // Sets the total number of user access levels
                        Auth.setSysModule(sysModule);
                        Auth.setDateFormat(dateFormat);
                        Auth.setBundleVersion();
                        Auth.setDelimiterSettings(delimiterSettings)

                        if (userLanguage) {
                            userLanguage = JSON.parse(userLanguage);
                            userLanguage = userLanguage[0].Value;
                            if (userLanguage && userLanguage.length > 0) {
                                Auth.setCurrentLanguage(userLanguage);
                            } else {
                                getDefaultLanguage = true;
                            }
                        } else {
                            getDefaultLanguage = true;
                        }

                        if (getDefaultLanguage) {
                            defaultLanguage = JSON.parse(defaultLanguage);
                            defaultLanguage = defaultLanguage[0];
                            defaultLanguage = JSON.stringify(defaultLanguage);
                            Auth.setCurrentLanguage(defaultLanguage);
                        }

                        if (logonControl.EmployeePhoto != undefined && logonControl.EmployeePhoto.EmployeePhoto != undefined && logonControl.EmployeePhoto.EmployeePhoto.Attributes != undefined) {
                            logonControl.EmployeePhoto.EmployeePhoto.Attributes.map(obj => {
                                if (obj.AttributeName == "[38].LocationReference") {
                                    if(obj.Value == "undefined" || obj.Value == undefined)
                                    {
                                        Auth.setEmpPhoto(null);
                                    }
                                    else
                                    {
                                    Auth.setEmpPhoto(obj.Value);
                                    }
                                }
                            });
                        }

                        if (logonControl.EmployeeID != 0) {
                            Auth.setUserName(logonControl.UserDetails); // Sets the name for the user
                        } else {
                            Auth.setUserName("Admin"); // Sets the name for the user if the user is employee 0
                        }

                        if (self.refs.cbRememberMe.checked) {
                            Auth.setRememberedUsername(username); // Sets the username for "Remember my username"
                        }

                        Auth.removeLoginTime();
                       
                        if (self.props.location.query !== undefined && self.props.location.query.isRedirectToLMS == "1") {
                            window.location = `${EXTERNAL_LINKS[0].MainURL}login/?loginToken=${response.token}`;
                        }

                        self.checkBundle();
                        self.checkSession();
                    }
                } else {
                    self.changeStateOfMessage(response["detail"], false);
                }
            }).catch(e => {
                console.log(e);
                self.toastError(e);
            })
            
        }
    }

    checkBundle = () => {
        
        let scripts = document.scripts,
            token = localStorage.getItem('token');
    
        if (scripts) {
            Object.keys(scripts).forEach(key => {
                let script = scripts[key],
                    scriptSrc = script.src;
    
                if (scriptSrc) {
                    if (scriptSrc.includes('bundle')) {
                        if (scriptSrc.includes('public')) {
                            let scriptSrcSplit = scriptSrc.split('/'),
                                bundleVersion,
                                currentBundleVersion = localStorage.getItem('currentBundleVersion');
    
                            if (scriptSrcSplit) {
                                bundleVersion = `${scriptSrcSplit[4]}`;
                            }
    
                            if (token) {
                                if (bundleVersion) {
                                    if (bundleVersion != currentBundleVersion || currentBundleVersion == null) {
                                        alert('The system has been updated. You will be logged out.');
                                        localStorage.clear();
                                        return window.location = '/logout';
                                    }
                                }
                            }
                        }
                    }
                }
            });
        }
    }
    
    checkSession = () => {
    
        let token = localStorage.getItem('token');

        if (token) {
            checkUserCancelToken.cancel('');

            let requestjson = {
                Module: "system_access_check"
            }, config = {
                CancelToken: checkUserCancelToken,
                Data: { requestjson: requestjson },
                Method: "GET",
                ResponseSuccessCallback: responseSuccessCallback,
                ResponseFailCallback: responseFailCallback,
                Url: "/single_api/"
            };

            config = GeneralFunctions.genericQuery(config);
            checkUserCancelToken = config.CancelToken;

            function responseSuccessCallback(responseJson) {
                const data = responseJson.data.Data;

                if ( data.IsUserAllowed === false && token ) {
                    alert('You are not allowed to access the system. You will be logged out.');
                    localStorage.clear();
                    return window.location = '/logout';
                } 

                if ( !auth.isWebsiteTermSigned() ){
                    if(window.location.pathname != '/terms-and-condition' ){
                        return window.location.replace('/terms-and-condition');
                    }
                }

                window.location = '/';
            }

            function responseFailCallback(responseJson) {
                console.log(responseJson.message);
            }
        }
    }

    setUserHasTeam(empId, token, self) {
        axios.get((API_URL) + API_TEAM_MEMBERS, {
            params: {
                EmployeeID: empId,
                SearchDate: new Date().toJSON().slice(0, 10)
            },
            headers: {
                Token: Auth.getToken()
            },
        }).then(function (response) {
           
            if (response.status === 200) {
                
                if (response.data.detail != null || response.data.detail != undefined) {
                    Auth.setUserHasTeam(true);
                }
                
                browserHistory.push(self.state.pathname);
                   
            }
        }).catch(function (error) {
            Auth.setUserHasTeam(false);
            console.log(error);
            // self.toastError(error);
            browserHistory.push(self.state.pathname);
        });
    }
    
    changeStateOfMessage(message, isMessageHidden) {
        this.setState({
            message: message,
            isMessageHidden: isMessageHidden,
            isLoggingIn: false,
            isDisable: false
        });
    }
    hideMessage(event) {
        event.preventDefault();
        this.changeStateOfMessage("", true);
    }
    renderMessage() {
        if (!this.state.isMessageHidden) {
            return (
                <div className="form-group auth-login--message">
                    <div className="alert alert-danger alert-dismissable fade in">
                        <a onClick={this.hideMessage.bind(this)} className="close" data-dismiss="alert" aria-label="close">&times;</a>
                        {this.state.message}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
    render() {
        let { logo } = this.state
        Object.keys(logo).map(function (obj) {
            logo = logo[obj];
            logo = API_URL_ROOT_FS + logo;
        });
        const rememberedUsername = Auth.getRememberedUsername();
        
        return (
            <section id="pg-login" className="hero is-fullheight is-medium">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="account-wall">
                                <div className="account-wall-title">
                                    <i className="fa fa-globe"></i>
                                    <span>HR Information System (HRIS)</span>
                                </div>
                                <div className="account-wall-body">
                                    <form className="form-horizontal">
                                        {this.renderMessage()}
                                        <div className="form-group">
                                            <label className="control-label col-sm-2" htmlFor="username">Username:</label>
                                            <div className="col-sm-offset-1 col-sm-9">
                                                <input type="text" className="form-control" id="username" placeholder="Username" ref="username" defaultValue={rememberedUsername} autoFocus={true} required />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label col-sm-2" htmlFor="pwd">Password:</label>
                                            <div className="col-sm-offset-1 col-sm-9">
                                                <input type="password" className="form-control" id="pwd" placeholder="Password" ref="password" required />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="col-sm-offset-3 col-sm-5 col-xs-6">
                                                <div className="checkbox">
                                                    <label className="inp-label"><input ref="cbRememberMe" type="checkbox" /> Remember my username</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-xs-6 has-text-right">
                                                <Link to="/forgot-password/" className="btn btn-sm">Forgot password?</Link>
                                            </div>
                                        </div>
                                        {
                                            this.state.isCaptchaOn ?
                                                <div className="form-group">
                                                    <div className="col-sm-offset-3 col-sm-9">
                                                        <div id="captchaCanvas">
                                                        </div>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" id="captchaInput" placeholder="" />
                                                            <span className="input-group-btn">
                                                                <button className="btn btn-default" id="captchaRefresh" type="button" onClick={this.onClickRefresh.bind(this)}>Refresh</button>
                                                                <button className="btn btn-default" id="captchaVerify" type="button" onClick={this.onClickVerify.bind(this)}>Verify</button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div> </div>
                                        }
                                        <div className="form-group">
                                            <div className="col-sm-offset-3 col-sm-9">
                                                <button type="submit" className="btn btn-danger btn-sm btn-block" onClick={this.onClickSubmit.bind(this)} disabled={this.state.isDisable}>
                                                    {this.state.isLoggingIn ? "Logging in..." : "Login"}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="account-wall-footer">
                                    <div className="account-wall-image has-text-right">
                                        <img className="img-responsive login-panel-header-img" src={logo} alt="logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}